<header [formData]="formData" *ngIf="!hideHeader"></header>
<page-title text="Management Review" titleType="2"></page-title>

<form>

    <div class="row">
        <div class="col-12 col-sm-6">
            <div class="form-group">
                <label>Reassign Report to another Staff Member:</label>
                <button-toggle [value]="form.reassign.value" (valueChange)="showReassignOptions($event)" [disabled]="readOnly"></button-toggle>
            </div>
        </div>
        <div class="col-12 col-sm-6" *ngIf="form.reassign.value===true">
            <div class="form-group">
                <label>&nbsp;</label>
                <user-select 
                    [field]="form.reassignToUserId" 
                    [disabled]="readOnly"
                ></user-select>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-12 col-lg-8 border-lg-right border-dashed">
            <div class="row">
                <div class="col-12">
                    <span>Review:</span>
                </div>
            </div>
            <div class="row my-3">
                <div class="col-12">
                    <textarea
                        class="form-control {{form.summary.iifIsValid('border-success','border-danger')}}"
                        replaceAmpersand
                        autosize
                        [minRows]="3"
                        [placeholder]="readOnly ? '' : 'Enter your brief summary here...'"
                        [spellcheck]="true"
                        [(ngModel)]="form.summary.value"
                        [ngModelOptions]="{standalone: true}"
                        [disabled]="readOnly"
                    ></textarea>
                    <small class="form-text text-danger" *ngIf="form.summary.showErrorHelp()">Invalid Summary</small>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <span>Internal Notes:</span>
                </div>
            </div>
            <div class="row my-3">
                <div class="col-12">
                    <textarea
                        class="form-control {{form.notes.iifIsValid('border-success','border-danger')}}"
                        replaceAmpersand
                        autosize
                        [minRows]="3"
                        [placeholder]="readOnly ? '' : 'Enter your notes'"
                        [spellcheck]="true"
                        [(ngModel)]="form.notes.value"
                        [ngModelOptions]="{standalone: true}"
                        [disabled]="readOnly"
                    ></textarea>
                </div>
            </div>
            <div class="row my-3">
                <div class="col-12">
                    <follow-up-widget
                        #followUpWidget
                        [model]="form.followUps.value"
                        (modelChange)="form.followUps.value=$event"
                        [readOnly]="readOnly"
                    ></follow-up-widget>
                </div>
            </div>
        </div>
        <div class="col-12 offset-0 col-lg-4 pt-0 pt-lg-4">
            <div class="row">
                <div class="col-12 offset-0 col-xl-10 offset-xl-1">
                    <document-uploads [(documents)]="documents" [readOnly]="readOnly" [disableTicking]="true"></document-uploads>
                </div>
            </div>
        </div>
    </div>

    <footer 
        [formRecord]="formRecord" 
        [readOnly]="readOnly" 
        (onSubmit)="submit($event)">
    </footer>
</form>
