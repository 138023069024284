<header [formData]="formData" *ngIf="!hideHeader"></header>
<page-title text="{{hideHeader?'Risk Assessment Record':'Risk Assessment'}}"></page-title>

<form>
	<div class="row">
		<div class="col-12 col-lg-8 border-lg-right">
			<div class="row my-3" *ngIf="form.existingRisk.value">
				<div class="col-12 col-sm-6">
					<div class="form-group">
						<label>This is a:</label>
						<button-toggle enableText="Existing Risk" disableText="New Risk"
							[value]="form.isExistingRisk.value" [disabled]="true">
						</button-toggle>
					</div>
				</div>
			</div>

			<div class="row my-3" *ngIf="form.isExistingRisk.value">
				<div class="col-12">
					<risk-select [value]="form.existingRisk.value" [field]="form.existingRisk"
						placeholder="Select a risk" [disabled]="readOnly">
					</risk-select>
				</div>
			</div>

			<div class="row my-3">
				<div class="col-12 col-sm-6">
					<div class="form-group">
						<label>Risk Type:</label>
						<select2 class="form-control select2-form-control" [data]="riskTypeOptions"
							[options]="{ placeholder: 'Select an option' }" [value]="form.riskType.value"
							(valueChanged)="this.form.riskType.value = $event.value;" [disabled]="readOnly">
						</select2>
					</div>
				</div>

				<div class="col-12 col-sm-6">
					<div class="form-group">
						<label>Location:</label>
						<location-select [field]="form.location" [disabled]="readOnly" placeholder="Select Location"></location-select>
					</div>
				</div>
			</div>

			<div class="row my-3" *ngIf="isResidentRisk()">
				<div class="col-12 col-sm-6" *ngIf="isResidentRisk()">
					<div class="form-group">
						<label for="resident-name">Client Name:</label>
						<input type="text" id="resident-name"
							class="form-control {{!readOnly && form.clientName.iifIsValid('border-success','border-danger')}}"
							[(ngModel)]="form.clientName.value" [ngModelOptions]="{standalone: true}"
							[disabled]="readOnly">
						<small class="form-text text-danger" *ngIf="form.clientName.showErrorHelp()">Invalid
							Resident</small>
					</div>
				</div>
			</div>

			<div class="row my-3">
				<div class="col-12">
					<span>Please provide a brief summary of risk: </span>
				</div>
			</div>

			<div class="row my-3">
				<div class="col-12">
					<textarea
						class="form-control {{!readOnly && form.summary.iifIsValid('border-success','border-danger')}}"
						placeholder="Summary" autosize replaceAmpersand [minRows]="3" [spellcheck]="true"
						[(ngModel)]="form.summary.value" [ngModelOptions]="{standalone: true}"
						[disabled]="readOnly"></textarea>
					<small class="form-text text-danger" *ngIf="form.summary.showErrorHelp()">Invalid Summary</small>
				</div>
			</div>

			<div class="row">
				<div class="col-12">
					<label>Select Department responsible for initial assessment:</label>
				</div>
				<div class="col-12 col-sm-6">
					<div class="form-group">
						<user-group-select [field]="form.initialAssessorDepartmentId" [disabled]="readOnly"></user-group-select>
					</div>
				</div>
			</div>
		</div>
		<div class="col-12 offset-0 col-lg-4 pt-0 pt-lg-4">
			<div class="row">
				<div class="col-12 offset-0 col-xl-10 offset-xl-1">
					<document-uploads [(documents)]="documents" [readOnly]="readOnly" [disableTicking]="true">
					</document-uploads>
				</div>
			</div>
		</div>
	</div>

	<footer [formRecord]="formRecord" [readOnly]="readOnly" (onSubmit)="beforeSubmit($event)">
	</footer>
</form>