var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from '@angular/core';
import { Location } from "@angular/common";
import * as moment from 'moment';
import "moment-timezone";
import { FormField } from "../../../../../model/Form";
import { FormComponent } from "../../../../../model/FormComponent";
import { Session } from "../../../../../service/util/Session";
import { CurrentUserService } from "../../../../../service/currentUser/CurrentUserService";
import { FormService } from "../../../../../service/FormService";
import { CategoryService } from "../../../../../service/CategoryService";
import { FormRecordService } from "../../../../../service/FormRecordService";
import { GroupsService } from "../../../../../service/admin/GroupsService";
import { ErrorHandlerService } from "../../../../../service/ErrorHandlerService";
import { Router } from "@angular/router";
import { FollowUpWidgetComponent } from "../../../../shared/followUpWidget.component";
import { environment } from '../../../../../environments/environment';
var FollowUpFormStage0Component = /** @class */ (function (_super) {
    __extends(FollowUpFormStage0Component, _super);
    function FollowUpFormStage0Component(session, currentUserService, formService, categoryService, formRecordService, groupsService, errorHandler, router, location) {
        var _this = _super.call(this, location) || this;
        _this.session = session;
        _this.currentUserService = currentUserService;
        _this.formService = formService;
        _this.categoryService = categoryService;
        _this.formRecordService = formRecordService;
        _this.groupsService = groupsService;
        _this.errorHandler = errorHandler;
        _this.router = router;
        // Existing Form Data
        _this.readOnly = false;
        _this.hideHeader = false;
        _this.followUpTypes = [
            {
                text: 'Audit',
                value: 'audit',
            },
            {
                text: 'Report',
                value: 'report',
            },
            {
                text: 'New',
                value: 'new',
            },
            {
                text: 'Other',
                value: 'other',
            },
        ];
        _this.form = {
            //TODO: Rework this into enum
            followUpType: new FormField('new'),
            location: new FormField('', {
                validation: FormField.ValidationMethods.None
            }),
            type: new FormField('', {
                validation: FormField.ValidationMethods.None
            }),
            summary: new FormField('', {
                validation: FormField.ValidationMethods.IsNotBlank
            }),
            dueAt: new FormField('', {
                validation: FormField.ValidationMethods.None
            }),
            department: new FormField('', {
                validation: FormField.ValidationMethods.IsNotBlank
            }),
        };
        _this.documents = [];
        return _this;
    }
    FollowUpFormStage0Component.prototype.registerFormFields = function () {
        var _this = this;
        var _a;
        (_a = this.formFields).push.apply(_a, Object.keys(this.form).map(function (k) { return _this.form[k]; }));
    };
    FollowUpFormStage0Component.prototype.onSubmit = function (isDraft) {
        var _this = this;
        this.session.lockInput(function () {
            return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
                var isNewForm, success, fail, formLocationId, userGroupId, stage, assignedUserId, momentDueAt, dueAt, alertAt, halfTermSeconds, formFunc;
                var _this = this;
                return __generator(this, function (_a) {
                    isNewForm = !(this.formData && this.formData.id);
                    success = function () {
                        resolve();
                        if (isNewForm) {
                            // go to dashboard
                            _this.router.navigate(['/dashboard']);
                        }
                        else {
                            _this.goBack();
                        }
                    };
                    fail = function (msg, err) {
                        console.error(msg, err);
                        _this.errorHandler.handleHttpError(err);
                        reject();
                    };
                    formLocationId = this.form.location.isValid && this.form.location.value !== null ? Number(this.form.location.value) : null;
                    if (formLocationId === 0)
                        formLocationId = null;
                    userGroupId = this.form.department.isValid ? Number(this.form.department.value) : null;
                    stage = isDraft ? 0 : 1;
                    assignedUserId = null;
                    if (this.currentUserService.userData && isDraft)
                        assignedUserId = this.currentUserService.userData.id;
                    momentDueAt = this.form.dueAt.value ? moment(this.form.dueAt.value, "DD/MM/YYYY") : null;
                    dueAt = momentDueAt ? momentDueAt.toISOString() : null;
                    alertAt = null;
                    if (momentDueAt) {
                        halfTermSeconds = momentDueAt.diff(moment()) / 2.0 / 1000.0;
                        alertAt = momentDueAt.subtract(halfTermSeconds, 'second').toISOString();
                    }
                    if (!isNewForm) {
                        formFunc = this.formService.updateForm({
                            id: this.formData.id,
                            formLocationId: formLocationId,
                            userGroupId: userGroupId,
                            stage: stage,
                            dueAt: dueAt,
                            alertAt: alertAt,
                            assignedUserId: assignedUserId
                        });
                    }
                    else {
                        formFunc = this.formService.createForm({
                            categoryId: this.formCategoryId,
                            formLocationId: formLocationId,
                            userGroupId: userGroupId,
                            dueAt: dueAt,
                            alertAt: alertAt,
                            notifyOnComplete: null,
                            stage: stage,
                            parentFormId: null,
                            assignedUserId: assignedUserId
                        });
                    }
                    formFunc.subscribe(function (data) {
                        var properties = [];
                        var formId = (_this.formData && _this.formData.id) ? _this.formData.id : data['id'];
                        var assignedUserGroupId = _this.form.department.isValid ? Number(_this.form.department.value) : null;
                        if (assignedUserGroupId) {
                            properties.push({
                                name: "userGroupId",
                                intData: assignedUserGroupId
                            });
                        }
                        properties.push({
                            name: "summary",
                            stringData: _this.form.summary.value
                        });
                        if (_this.form.type.value && _this.form.type.value.length)
                            properties.push({
                                name: "type",
                                enumId: Number(_this.form.type.value)
                            });
                        properties.push({
                            name: "followUpType",
                            stringData: _this.form.followUpType.value
                        });
                        _this.formRecordService.createRecord({
                            formId: formId,
                            // Intentionally cast the properties object since we know its correct
                            properties: properties,
                            stage: 0,
                            documents: _this.documents.map(function (doc) { return ({ id: doc.id, isTicked: !!doc.isTicked }); }),
                            isComplete: isDraft ? false : true
                        })
                            .subscribe(success, function (err) { return fail('Error while creating a form', err); });
                    }, function (err) { return fail('Error while create/update form', err); });
                    return [2 /*return*/];
                });
            }); });
        });
    };
    FollowUpFormStage0Component.prototype.ngOnInit = function () {
        this.dateString = moment().tz(environment.timeZone).format("DD-MM-YYYY");
        this.registerFormFields();
        this.loadEnums();
        this.repopulateFormFromData();
    };
    FollowUpFormStage0Component.prototype.repopulateFormFromData = function () {
        if (!this.formData || !this.formData.records || !this.formData.records.length)
            return;
        // Find the most recent stage0 record
        var stage0Records = this.formData.records.filter(function (record) { return record.stage === 0; });
        if (stage0Records.length === 0)
            return;
        var mostRecentRecord = stage0Records.sort(function (a, b) { return a.sequence > b.sequence ? 1 : -1; }).pop();
        //Convert the properties into easily accessible IFormRecordPropertyParam
        if (!mostRecentRecord || !mostRecentRecord.properties)
            return;
        /**
         * FIX this is needed for correct functionality in submissionDetail component
         */
        this.formRecord = mostRecentRecord;
        var simpleProperties = {};
        mostRecentRecord.properties.forEach(function (recordProperty) {
            //eject invalid property
            if (!recordProperty.property)
                return;
            var result = {
                name: recordProperty.property.name
            };
            if (recordProperty.stringData)
                result.stringData = recordProperty.stringData;
            if (recordProperty.intData)
                result.intData = recordProperty.intData;
            if (recordProperty.jsonData)
                result.jsonData = recordProperty.jsonData;
            if (recordProperty.enumId)
                result.enumId = recordProperty.enumId;
            simpleProperties[result.name] = result;
        });
        /**
         * get attached documents
         */
        this.documents = this.initTickedDocuments(this.formRecord.documents, this.formRecord.tickedDocuments);
        if (simpleProperties['userGroupId'])
            this.form.department.value = String(simpleProperties['userGroupId'].intData);
        else if (this.formData['userGroupId'])
            this.form.department.value = String(this.formData['userGroupId']);
        if (this.formData['formLocationId'])
            this.form.location.value = String(this.formData['formLocationId']);
        if (simpleProperties['summary'])
            this.form.summary.value = simpleProperties['summary'].stringData;
        if (simpleProperties['followUpType'])
            this.form.followUpType.value = simpleProperties['followUpType'].stringData;
        if (simpleProperties['type'])
            this.form.type.value = simpleProperties['type'].enumId;
        if (this.formData.dueAt) {
            this.form.dueAt.value = this.formData.dueAt ? moment(this.formData.dueAt).format('DD/MM/YYYY') : '';
        }
    };
    FollowUpFormStage0Component.prototype.loadEnums = function () {
        var _this = this;
        this.session.lockInputRx(this.categoryService.getCategories())
            .subscribe(function (data) {
            var reportCategory = data.find(function (data) { return !!data.name.match(/^Follow-Up/); });
            if (reportCategory)
                _this.formCategoryId = reportCategory.id;
            //else
            //Notify the user an error has occurred
        }, function (err) {
            console.error('Error while getting categories', err);
            _this.errorHandler.handleHttpError(err);
        });
    };
    return FollowUpFormStage0Component;
}(FormComponent));
export { FollowUpFormStage0Component };
