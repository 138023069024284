import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { saveAs } from 'file-saver';
import * as moment from 'moment';
import "moment-timezone";
import { FormService } from "../../../../service/FormService";
import { Session } from "../../../../service/util/Session";
import { FormsPaginationResponse, IFormsQueryParams } from "../../../../../common/contracts/form";
import { CurrentUserService } from "../../../../service/currentUser/CurrentUserService";
import { PersistentStorageService } from "../../../../service/PersistentStorageService";
import { TruncatePipe } from "../../../../pipes/truncate.pipe";
import { ErrorHandlerService } from "../../../../service/ErrorHandlerService";
import { stringifyObjectValues } from "../../../../service/util/stringifyObjectValues";
import { UserSelectComponent } from "../../../shared/userSelect.component";
import { ReportService } from "../../../../service/ReportService";
import { CategoryService } from "../../../../service/CategoryService";
import { ICategoryOutputModel } from "../../../../../common/contracts/category";
import { Observable } from "rxjs";
import { logger } from "service/util/Logger";
import { DocumentIntanceService } from "service/admin/DocumentInstanceService";
import { IDocumentInstanceOutputType, IDocumentInstanceParams } from "../../../../../common/contracts/documentInstance"
import { FormField } from "model/Form";
import { environment } from "../../../../environments/environment";

class DashboardRow {
  id: number;

  type: string;
  title: string;
  location: string | null;
  formType: string;
  documentName: string;

  createdDate: Date;
  dueDate: Date | null;

  createdAtDateString: string;
  dueDateString: string;
  archivedAtDateString: string;

  isAlert: boolean;
  isWarning: boolean;
  isFinalized: boolean;

  assignedUser: string | null;
  assignedGroup: string | null;

  stage: number;
}

class AprovedDocumentRow {
  id: number;

  documentName: string;
  documentType: string;
  documentCode: string;
  documentId: number;

  documentCollectionName: string;
  documentCollectionId: number;

  aprovedAt: Date | null;
  path: string;
  version: number;
}

@Component({
  selector: 'app-admin',
  templateUrl: './drm-dashboard.component.html',
  styleUrls: ['./drm-dashboard.component.scss'],
})
export class DRMDashboardComponent implements OnInit {

  @ViewChild('userSelect') userSelectorRef: UserSelectComponent;

  public dashboardRecords: DashboardRow[] = [];
  public aprovedDocuments: AprovedDocumentRow[] = [];

  public alertCount: number = 0;
  public warningCount: number = 0;
  public taskCount: number = 0;
  public notificationCount: number = 0;
  public formsQueryParams: IFormsQueryParams = {
    skip: 0,
    limit: 10,
    sortBy: 'dueAt',
    order: 'asc',
    assignedUserId: undefined,
    locationId: undefined,
    showAll: 'active',
    dueFilter: undefined,
    createdAtStart: undefined,
    createdAtEnd: undefined,
    dueAtStart: undefined,
    dueAtEnd: undefined,
    search: undefined,
    category: undefined,
  };
  public documentInstanceQueryParams: IDocumentInstanceParams = {
    skip: 0,
    limit: 10,
    sortBy: 'dueAt',
    order: 'asc',
    locationId: undefined,
    lastUpdatedAtStart: undefined,
    lastUpdatedAtEnd: undefined,
    search: undefined,
    category: undefined,
    standard: undefined,
    tags: undefined,
    metaTags: '',
    metaTagValue:''
  };
  public documentStandard = new FormField<String>('',{
  });
  public currentPage = 1;
  public isAdmin: boolean;
  public isAdminOrManager: boolean;
  public pageSize = this.pss.pageSize;
  public totalForms: number;
  public totalDocuments: number;
  public currentUserId: number | null;
  public defaultAssignedUserId: string;
  public defaultAssignedGroupId: string;
  public defaultAssignedLocationId: string;
  public showAllOptions = [
    { value: 'all', text: 'All' },
    { value: 'complete', text: 'Complete' },
    { value: 'active', text: 'Active' },
  ];
  public searchFilter: string = '';
  public originatorFilter: string = '';

  public dateFilterMap = {
    none: 'Filter By Date',
    createdAt: 'Created At',
    dueAt: 'Due Date',
  };

  public metaTag:string;
  public metaTagValueModel: any = '';

  public selectedDateFilter: string = 'none';

  public selectedCategoryFilter: ICategoryOutputModel | null = null;

  public filterStartDate: string = '';
  public filterEndDate: string = '';
  public formCategories: Observable<ICategoryOutputModel[]>;
  public currentMetaTag = {id:'',value:'',name:''} ;
  public metaTags: any = [];
  public includeArchived: boolean = false;

  constructor(
    public formService: FormService,
    private categoryService: CategoryService,
    public session: Session,
    public router: Router,
    public currentUserService: CurrentUserService,
    private route: ActivatedRoute,
    private pss: PersistentStorageService,
    private truncatePipe: TruncatePipe,
    private errorHandler: ErrorHandlerService,
    private reportService: ReportService,
    private documentService: DocumentIntanceService,
  ) {
  }

  ngOnInit() {
    logger.silly("Dashboard Component Init");
    this.isAdmin = this.currentUserService.isAdministrator.getValue();
    this.isAdminOrManager = this.isAdmin || this.currentUserService.userData!.role === 'manager';

    if (this.isAdminOrManager) {
      this.currentUserId = this.currentUserService.currentUserId.getValue();
      this.defaultAssignedUserId = this.currentUserId!.toString();
      this.defaultAssignedGroupId =
        (this.currentUserService.currentUserData.value!.groups || [])
          .map(group => group.id.toString())
          .join(",");

      this.defaultAssignedLocationId =
        (this.currentUserService.currentUserData.value!.locations || [])
          .map(location => location.id.toString())
          .join(",");

      logger.silly("defaultAssignedLocationId" + this.defaultAssignedLocationId);
      logger.silly("defaultAssignedGroupId: " + this.defaultAssignedGroupId);
      logger.silly("defaultAssignedUserId: " + this.defaultAssignedUserId);
    }

    if (this.isAdmin) {
      this.showAllOptions.splice(2, 0, { value: 'deleted', text: 'Deleted' });
    }

    const allowedShowAllValues: string[] = this.showAllOptions.map(v => v.value);

    /**
     * all filtering, pagination should go through query params
     */
    this.route.queryParams.subscribe(params => {
      /**
       * if page param is undefined push default query params
       */
      if (!params.page || !params.category) {
        this.pushDefaultQueryParams();
        return;
      }
      if (params.category) {
        this.formsQueryParams.category = params.category;
      }

      this.currentPage = parseInt(params.page, 10);

      if (isNaN(this.currentPage) || this.currentPage <= 0) {
        this.pushDefaultQueryParams();
        return;
      }

      if (this.isAdminOrManager) {
        if (params.assignedUser) {
          this.formsQueryParams.assignedUserId = parseInt(params.assignedUser, 10) || undefined;
          if (!this.userSelectorRef && this.formsQueryParams.assignedUserId) { // fix for recovering user filter after page refresh
            this.defaultAssignedUserId = this.formsQueryParams.assignedUserId.toString();
          } else if (
            this.formsQueryParams.assignedUserId &&
            (this.userSelectorRef.selectedUserId ? this.userSelectorRef.selectedUserId.toString() : '') !== (this.formsQueryParams.assignedUserId ? this.formsQueryParams.assignedUserId.toString() : '')) {
            this.userSelectorRef.changeSelectedUser(this.formsQueryParams.assignedUserId);
          }
        } else {
          if (!this.userSelectorRef) { // fix for recovering user filter after page refresh
            this.defaultAssignedUserId = '';
          }
          delete this.formsQueryParams.assignedUserId;
        }

        logger.silly(`Processing params.location[(${typeof params.location}) ${params.location}] into locationId[${this.formsQueryParams.locationId}]`);

        /**
         * This cannot rely on a truthy assertion as params.group
         * will return false when it is a blank string
         */
        if (typeof params.group === 'string') {
          this.formsQueryParams.groupId = params.group;
        } else {
          logger.silly('Setting queryParams.groupId to defaultAssignedGroupId');
          this.formsQueryParams.groupId = this.defaultAssignedGroupId;
        }
        logger.silly(`Processing params.group[(${typeof params.group}) ${params.group}] into groupId[${this.formsQueryParams.groupId}]`);

        if (params.showAll && allowedShowAllValues.includes(params.showAll)) {
          this.formsQueryParams.showAll = params.showAll;
        } else {
          this.formsQueryParams.showAll = 'active';
        }
      }

      if (params.sortBy) {
        this.formsQueryParams.sortBy = params.sortBy;
      } else {
        this.formsQueryParams.sortBy = 'dueAt';
      }

      if (params.order) {
        this.formsQueryParams.order = params.order;
      } else {
        this.formsQueryParams.order = 'asc';
      }

      let startDateFound = false;
      let endDateFound = false;

      ['createdAtStart', 'createdAtEnd', 'dueAtStart', 'dueAtEnd'].forEach(dateParam => {
        this.formsQueryParams[dateParam] = params[dateParam] || undefined;
        if (params[dateParam]) {

          if (dateParam.startsWith('createdAt')) {
            this.selectedDateFilter = 'createdAt';
          } else if (dateParam.startsWith('dueAt')) {
            this.selectedDateFilter = 'dueAt';
          }

          if (dateParam.endsWith('Start')) {
            startDateFound = true;
            this.filterStartDate = params[dateParam].replace(/-/g, '/');
          } else if (dateParam.endsWith('End')) {
            endDateFound = true;
            this.filterEndDate = params[dateParam].replace(/-/g, '/');
          }
        }
      });

      if (!startDateFound) {
        this.filterStartDate = '';
      }

      if (!endDateFound) {
        this.filterEndDate = '';
      }

      if (!startDateFound && !endDateFound) {
        this.selectedDateFilter = 'none';
      }

      if (params.showOnly && ['overdue', 'alert', 'remaining'].includes(params.showOnly)) {
        this.formsQueryParams.dueFilter = params.showOnly;
      } else {
        delete this.formsQueryParams.dueFilter;
      }

      if (params.search && params.search.trim()) {
        this.formsQueryParams.search = params.search.trim();
        this.searchFilter = params.search.trim();
      }

      if (params.originatorName && params.originatorName.trim()) {
        this.formsQueryParams.originatorName = params.originatorName.trim();
        this.originatorFilter = params.originatorName.trim();
      }

      if (params.reportFormType) {
        this.formsQueryParams.reportFormType = parseInt(params.reportFormType, 10) || undefined;
      } else {
        delete this.formsQueryParams.reportFormType;
      }
      
      if (params.includeArchived) {
        this.documentInstanceQueryParams.includeArchived = params.includeArchived === 'true' ? true : false;
      }

      this.formsQueryParams.skip = (this.currentPage - 1) * this.pageSize;
      this.formsQueryParams.limit = this.pageSize;

      this.documentInstanceQueryParams.skip = (this.currentPage - 1) * this.pageSize;
      this.documentInstanceQueryParams.limit = this.pageSize;
      this.loadForms();
      this.loadDocuments();
    });
  }

  public handlePageChange() {
    this.pushQueryParams();
  }

  public handlePageChangeDocumentSearch() {
    this.pushDocumentInstanceQueryParams();
  }

  public handlePageSizeChange(pageSize: number) {
    this.pageSize = pageSize;
    if (this.currentPage === 1) {
      this.formsQueryParams.skip = 0;
      this.formsQueryParams.limit = this.pageSize;
      this.loadForms();
    } else {
      this.currentPage = 1;
      this.handlePageChange();
    }
  }

  public handlePageSizeChangeDocumentSearch(pageSize: number) {
    this.pageSize = pageSize;
    if (this.currentPage === 1) {
      this.documentInstanceQueryParams.skip = 0;
      this.documentInstanceQueryParams.limit = this.pageSize;
      this.loadDocuments();
    } else {
      this.currentPage = 1;
      this.handlePageChangeDocumentSearch();
    }
  }

  private pushQueryParams() {
    const queryParams = stringifyObjectValues({
      page: this.currentPage,
      assignedUser: this.isAdminOrManager ? this.formsQueryParams.assignedUserId : undefined,
      group: this.isAdminOrManager ? (this.formsQueryParams.groupId || undefined) : undefined,
      showAll: this.isAdminOrManager ? (this.formsQueryParams.showAll || undefined) : undefined,
      showOnly: this.formsQueryParams.dueFilter || undefined,
      sortBy: this.formsQueryParams.sortBy,
      order: this.formsQueryParams.order,
      createdAtStart: this.formsQueryParams.createdAtStart || undefined,
      createdAtEnd: this.formsQueryParams.createdAtEnd || undefined,
      dueAtStart: this.formsQueryParams.dueAtStart || undefined,
      dueAtEnd: this.formsQueryParams.dueAtEnd || undefined,
      search: this.formsQueryParams.search || undefined,
      category: this.formsQueryParams.category || undefined,
    });

    if (this.isAdminOrManager &&
      typeof this.formsQueryParams.groupId === 'string' &&
      this.formsQueryParams.groupId.length === 0
    ) queryParams.group = '';

    logger.silly("Pushing QueryParams", queryParams);
    this.router.navigate(['.'], {
      relativeTo: this.route,
      queryParams,
      queryParamsHandling: '',
    });
  }

  private pushDefaultQueryParams() {
    // Setting DRM-Task as default category
    this.categoryService.getCategories().subscribe(categories => {
      const drmTaskCategory = categories.find(cat => cat.name === 'DRM-Task');
      console.log("Pushing Default QueryParams");
      const queryParams = stringifyObjectValues({
        page: 1,
        assignedUser: this.isAdminOrManager ? this.currentUserId : undefined,
        showAll: this.isAdminOrManager ? 'active' : undefined,
        sortBy: 'dueAt',
        order: 'asc',
        category: drmTaskCategory && drmTaskCategory.id
      });
      this.router.navigate(['.'], {
        relativeTo: this.route,
        replaceUrl: true,
        queryParams,
        queryParamsHandling: '',
      });
    });
  }

  public handleUserFilterChange(selected) {
    if (!selected || selected.length === 0) {
      delete this.formsQueryParams.assignedUserId;
    } else {
      this.formsQueryParams.assignedUserId = selected[0].id;
    }
    this.currentPage = 1;
    this.pushQueryParams();
  }

  public handleShowAllFilterChange(option: 'all' | 'complete' | 'deleted' | 'active') {
    this.formsQueryParams.showAll = option;
    delete this.formsQueryParams.dueFilter;

    /**
     * if sorting by archived date was active and a user switched to active filter, turn sort by dueAt as default
     */
    if (this.formsQueryParams.sortBy === 'archivedAt' && option === 'active') {
      this.formsQueryParams.sortBy = 'dueAt';
      this.formsQueryParams.order = 'asc';
    }

    this.currentPage = 1;
    this.pushQueryParams();
  }

  public handleGroupFilterChange(selected: { id: string }[]) {
    console.log('event: handleGroupFilterChange');
    if (!selected || selected.length === 0) {
      logger.silly("Updated QueryParam: groupId: [blank string]");
      this.formsQueryParams.groupId = '';
    } else {
      const oldValue = this.formsQueryParams.groupId;
      const newValue = selected.map(item => item.id).filter(id => id && id.length).join(",");
      logger.silly(`Updated QueryParam: groupId: Old[${oldValue}] New[${newValue}]`);
      this.formsQueryParams.groupId = newValue;
    }
    this.currentPage = 1;
    this.pushQueryParams();
  }

  public handleCategoryFilterChange(selected) {
    if (!selected || selected.length === 0) {
      this.formsQueryParams.category = undefined;
    } else {
      this.formsQueryParams.category = selected[0].id;
    }
    this.currentPage = 1;
    this.pushQueryParams();
  }

  public handleReportFormTypeChange(selected) {
    if (!selected || selected.length === 0) {
      this.formsQueryParams.reportFormType = undefined;
    } else {
      this.formsQueryParams.reportFormType = selected[0].id;
    }
    this.currentPage = 1;
    this.pushQueryParams();
  }

  public loadForms() {
    this.session.lockInputRx(this.formService.getForms(this.formsQueryParams))
      .subscribe((data: FormsPaginationResponse) => {

        // TODO: Constrain the results to results approprate for the current user

        let dashboardRecords: DashboardRow[] = [];

        this.totalForms = data.totalCount;

        data.items.forEach(formItem => {
          let formType: string = formItem["category"]["name"];
          if (formType === 'Report') formType = 'Feedback';
          const createdDate: Date = new Date(formItem["createdAt"]);
          const dueDate: Date | null = formItem["dueAt"] ? new Date(formItem["dueAt"] as string) : null;
          const title = `${this.truncatePipe.transform(formItem.summary || 'unknown')}`;

          dashboardRecords.push({
            title,
            formType,
            createdDate,
            dueDate,
            type: formType,
            isAlert: !!formItem.isOverdue,
            isWarning: !!formItem.isAlertOverdue,
            isFinalized: !!formItem.archivedAt,
            id: formItem["id"],
            stage: formItem["stage"],
            archivedAtDateString: formItem["archivedAt"] ? moment(formItem["archivedAt"]).tz(environment.timeZone).format("DD-MM-YY") : '',
            createdAtDateString: moment(createdDate).tz(environment.timeZone).format("DD-MM-YY"),
            dueDateString: dueDate ? moment(dueDate).tz(environment.timeZone).format("DD-MM-YY") : '',
            assignedUser: formItem.assignedUser ? `${formItem.assignedUser.firstName} ${formItem.assignedUser.lastName} ` : null,
            assignedGroup: formItem.userGroup ? `${formItem.userGroup.groupName}` : null,
            location: formItem.formLocation ? formItem.formLocation.name : 'All Locations',
            documentName: formItem.document.documentCode
          });
        });

        this.dashboardRecords = dashboardRecords;
        this.taskCount = data.remainingTasks;
        this.warningCount = data.alertTasks;
        this.alertCount = data.overdueTasks;
      },
        err => {
          console.error('Error while getting forms in dashboard', err);
          this.errorHandler.handleHttpError(err);
        });
  }

  public progressForm(row: DashboardRow) {
    if (row.formType === 'Report' || row.formType === 'Feedback') {
      this.router.navigateByUrl(`/report/${row.id}`);
    } else if (row.formType === 'Follow-Up') {
      this.router.navigateByUrl(`/followUp/${row.id}`);
    } else if (row.formType === 'Audit') {
      this.router.navigateByUrl(`/audit/${row.id}`);
    } else if (row.formType === 'DRM-Task') {
      this.router.navigateByUrl(`/document-review-task/${row.id}`);
    } else {
      console.error(`Unknown Form Type ${row.formType}. Unable to navigate`);
    }
  }

  public toggleTopCardFilter(filter: "overdue" | "alert" | "remaining") {
    if (this.formsQueryParams.dueFilter === filter) {
      this.formsQueryParams.dueFilter = undefined;
    } else {
      this.formsQueryParams.dueFilter = filter;
    }
    this.formsQueryParams.showAll = 'active';
    this.currentPage = 1;
    this.pushQueryParams();
  }

  public handleSortChange(sortField: string) {
    if (sortField !== this.formsQueryParams.sortBy) {
      this.formsQueryParams.sortBy = sortField;
      this.formsQueryParams.order = 'asc';
    } else {
      this.formsQueryParams.order = this.formsQueryParams.order === 'asc' ? 'desc' : 'asc';
    }
    this.pushQueryParams();
  }

  public handleDateFilterChange(value: string) {
    this.selectedDateFilter = value;
    this.filterStartDate = '';
    this.filterEndDate = '';
    this.formsQueryParams.createdAtStart = undefined;
    this.formsQueryParams.createdAtEnd = undefined;
    this.formsQueryParams.dueAtStart = undefined;
    this.formsQueryParams.dueAtEnd = undefined;
    if (value === 'none') {
      this.currentPage = 1;
      this.pushQueryParams();
    }
  }

  public handleStartDateChanged() {
    this.formsQueryParams[this.selectedDateFilter + 'Start'] = this.filterStartDate.replace(/\//g, '-');
    this.currentPage = 1;
    this.pushQueryParams();
  }

  public handleEndDateChanged() {
    this.formsQueryParams[this.selectedDateFilter + 'End'] = this.filterEndDate.replace(/\//g, '-');
    this.currentPage = 1;
    this.pushQueryParams();
  }

  public performSearch() {
    if (this.searchFilter && this.searchFilter.trim()) {
      this.formsQueryParams.search = this.searchFilter.trim();
    } else {
      this.formsQueryParams.search = undefined;
    }

    if (this.originatorFilter && this.originatorFilter.trim()) {
      this.formsQueryParams.originatorName = this.originatorFilter.trim();
    } else {
      this.formsQueryParams.originatorName = undefined;
    }

    this.currentPage = 1;
    this.pushQueryParams();
  }

  public clearSearch() {
    this.searchFilter = '';
    this.formsQueryParams.search = undefined;
    this.documentInstanceQueryParams.search = undefined;
    this.currentPage = 1;
    this.pushQueryParams();
  }

  public handlePdfExport() {
    this.reportService.downloadDashboardPdfReport(this.formsQueryParams).subscribe((data) => {
      saveAs(data, `dashboard-report-${moment().format('YYYY-MMM-DD')}.pdf`);
    }, err => this.errorHandler.handleHttpError(err));
  }

  public handleCsvExport() {
    this.reportService.downloadDashboardCsvReport(this.formsQueryParams).subscribe((data) => {
      saveAs(data, `dashboard-report-${moment().format('YYYY-MMM-DD')}.csv`);
    }, err => this.errorHandler.handleHttpError(err));
  }

  public handleCategoryFilter(category: ICategoryOutputModel | null) {

    this.selectedCategoryFilter = category;
    this.formsQueryParams.category = category ? category.id : undefined;
    this.pushQueryParams();

  }

  public mapCategoryName(name: string) {
    if (name.toLowerCase() === 'report') return "Feedback";

    return name;
  }

  public loadDocuments() {
    this.documentService.getAllDocuments(this.documentInstanceQueryParams).subscribe((data: any) => {

      let aprovedDocuments: AprovedDocumentRow[] =[];

      this.totalDocuments = data.totalCount;

      data.rows.forEach((documentInstance: IDocumentInstanceOutputType) => {
            const id = documentInstance.id,
                  documentName = documentInstance.document.name,
                  documentCode = (documentInstance.document.documentCode),
                  documentId = Number(documentInstance.document.id),
                  documentType = documentInstance.document.type,
                  documentCollectionName = documentInstance.document.documentCollection.name,
                  documentCollectionId = Number(documentInstance.document.documentCollectionId),
                  aprovedAt = documentInstance.aprovedAt,
                  path = documentInstance.path,
                  version = documentInstance.version
            aprovedDocuments.push({
              id,
              documentName,
              documentCode,
              documentId,
              documentType,
              documentCollectionName,
              documentCollectionId,
              aprovedAt,
              path,
              version
            });
      });

      this.aprovedDocuments = aprovedDocuments;
      });
  }

  public downloadDocument(id: number, formName: string) {
    this.documentService.downloadDocument(id).subscribe(res =>{
      saveAs(res,formName);
    });
  }

  public onTabChange(event) {
    if(event && event.nextId === 'ngb-tab-1') {
      this.clearSearch();
      this.pushDocumentInstanceQueryParams();
    } else {
      this.clearSearch();
      this.pushQueryParams();
    }
  }

  public performDocumentSearch() {
    if (this.searchFilter && this.searchFilter.trim()) {
      this.documentInstanceQueryParams.search = this.searchFilter.trim();
    } else {
      this.documentInstanceQueryParams.search = undefined;
    }

    this.currentPage = 1;
    this.pushDocumentInstanceQueryParams();
  }
  public pushDocumentInstanceQueryParams() {
    const queryParams = stringifyObjectValues({
      page: this.currentPage,
      sortBy: this.formsQueryParams.sortBy,
      order: this.formsQueryParams.order,
      lastUpdatedAtStart: this.documentInstanceQueryParams.lastUpdatedAtStart || undefined,
      lastUpdatedAtEnd: this.documentInstanceQueryParams.lastUpdatedAtEnd || undefined,
      search: this.documentInstanceQueryParams.search || undefined,
      standard: this.documentInstanceQueryParams.standard || undefined,
      category: this.formsQueryParams.category || undefined,
      tags: this.documentInstanceQueryParams.tags || undefined,
      groupId: this.documentInstanceQueryParams.groupId || undefined,
      metaTags: this.documentInstanceQueryParams.metaTags || undefined,
      metaTagValue: this.documentInstanceQueryParams.metaTagValue || undefined,
      includeArchived: this.documentInstanceQueryParams.includeArchived,
    });
  
    this.router.navigate(['.'], {
      relativeTo: this.route,
      queryParams,
      queryParamsHandling: '',
    });
  }

  public handleStandardFilterChange(event) {
    if (!event || event.length === 0) {
      this.documentInstanceQueryParams.standard = '';
    } else {
      this.documentInstanceQueryParams.standard = event[0].id;
    }
    this.pushDocumentInstanceQueryParams();
  }

  public handleTagFilterChange(selected: { id: string }[]) {
    if (!selected || selected.length === 0) {
      logger.silly("Updated QueryParam: tags: [blank string]");
      this.documentInstanceQueryParams.tags = '';
    } else {
      const oldValue = this.documentInstanceQueryParams.tags;
      const newValue = selected.map(item => item.id).filter(id => id && id.length).join(",");
      logger.silly(`Updated QueryParam: tags: Old[${oldValue}] New[${newValue}]`);
      this.documentInstanceQueryParams.tags = newValue;
    }
    this.currentPage = 1;
    this.pushDocumentInstanceQueryParams();
  }

  public handleDocumentGroupFilterChange(selected: { id: string }[]) {
    if (!selected || selected.length === 0) {
      logger.silly("Updated QueryParam: userGroup: [blank string]");
      this.documentInstanceQueryParams.groupId = '';
    } else {
      const oldValue = this.documentInstanceQueryParams.groupId;
      const newValue = selected.map(item => item.id).filter(id => id && id.length).join(",");
      logger.silly(`Updated QueryParam: userGroup: Old[${oldValue}] New[${newValue}]`);
      this.documentInstanceQueryParams.groupId = newValue;
    }
    this.currentPage = 1;
    this.pushDocumentInstanceQueryParams();
  }

  public lastUpdatedAtFromFilterChange(event) {
    if(event === "") {
      return
    }
    this.documentInstanceQueryParams.lastUpdatedAtStart = event;
    this.currentPage = 1;
    this.pushDocumentInstanceQueryParams();
  }

  public lastUpdatedAtEndFilterChange(event) {
    if(event === "") {
      return
    }
    this.documentInstanceQueryParams.lastUpdatedAtEnd = event;
    this.currentPage = 1;
    this.pushDocumentInstanceQueryParams();
  }

  public handleMetaChange(event) {
    this.currentMetaTag.id = event[0].id;
    this.currentMetaTag.name = event[0].text;
  }
  public addNewMeta() {
    this.currentMetaTag.value = this.metaTagValueModel;
    this.metaTags.push({id:this.currentMetaTag.id, value:this.metaTagValueModel, name: this.currentMetaTag.name});
    this.metaTagValueModel = '';
    this.metaTag = '';

    this.documentInstanceQueryParams.metaTags = !!this.metaTags.length ? this.metaTags.map(tag => tag.id).join() : undefined,
    this.documentInstanceQueryParams.metaTagValue = !!this.metaTags.length ? this.metaTags.map(tag => tag.value).join() : undefined
    this.currentPage = 1;
    this.pushDocumentInstanceQueryParams();
  }
  public removeMeta(index) {
    this.metaTags.splice(index,1);
    this.documentInstanceQueryParams.metaTags = !!this.metaTags.length ? this.metaTags.map(tag => tag.id).join() : undefined,
    this.documentInstanceQueryParams.metaTagValue = !!this.metaTags.length ? this.metaTags.map(tag => tag.value).join() : undefined
    this.currentPage = 1;
    this.pushDocumentInstanceQueryParams();
  }
  public handleDocumentRowClick(document) {
    this.router.navigateByUrl(`/document-collections/${document.documentCollectionId}/documents/${document.documentId}`)
  }
  public handleIncludeArchivedToggle() {
    this.includeArchived = !this.includeArchived
    this.documentInstanceQueryParams.includeArchived = this.includeArchived;
    this.pushDocumentInstanceQueryParams();
  }
} 
