import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Location } from "@angular/common";
import * as moment from 'moment';
import "moment-timezone";
import { FormComponent } from "../../../../../model/FormComponent";
import { FormField } from "../../../../../model/Form";
import { Session } from "../../../../../service/util/Session";
import { CurrentUserService } from "../../../../../service/currentUser/CurrentUserService";
import { IFormRecordOutputModel, IFormRecordPropertyParam } from "../../../../../../common/contracts/form";
import { FormRecordService } from "../../../../../service/FormRecordService";
import { FormService } from "../../../../../service/FormService";
import { IFormOutputModel } from "../../../../../../common/contracts/form";
import { CategoryService } from "../../../../../service/CategoryService";
import { GroupsService } from "../../../../../service/admin/GroupsService";
import { ErrorHandlerService } from "../../../../../service/ErrorHandlerService";
import { IDocumentType } from "../../../../../../common/contracts/document";
import { FollowUpWidgetComponent } from "../../../../shared/followUpWidget.component";
import { FollowUpService } from "../../../../../service/FollowUpService";
import { saveAs } from "file-saver"
import { DocumentIntanceService } from 'service/admin/DocumentInstanceService';
import { DocumentService } from 'service/DocumentService';
import { DocumentsService } from 'service/admin/DocumentsService';
import { environment } from '../../../../../environments/environment';


@Component({
  selector: 'document-review-form-2',
  templateUrl: './documentReviewFormStage2.component.html'
})
export class DocumentReviewFormStage2Component extends FormComponent implements OnInit {
  public followUpFormCategory: number;

  // Existing Form Data
  @Input() public formData: IFormOutputModel;
  @Input() sequence: number;

  public documentId: number
  public formRecord: IFormRecordOutputModel;

  @Input() readOnly: boolean = false;
  @Input() hideHeader: boolean = false;

  @ViewChild('followUpWidget') followUpWidgetRef: FollowUpWidgetComponent;

  /*
    This should have been done properly using something that implements FormControl but its
    too late now
   */
  public form: { [key: string]: FormField<any> } = {
    reassign: new FormField<boolean>(false, {
      validation: FormField.ValidationMethods.None
    }),
    reassignAdmin: new FormField<boolean>(false, {
      validation: FormField.ValidationMethods.None
    }),
    //Since this will be assigned to a select it must be a string data - Conversion where appropriate
    reassignToUserId: new FormField<string>('', {
      validation: FormField.ValidationMethods.None
    }),
    followUps: new FormField<string>('[]', {
      nullEquivilent: "[]",
      validation: (value: string) => {
        return this.followUpWidgetRef ? this.followUpWidgetRef.validate() : true;
      }
    }),
    note: new FormField<String>('', {
      validation: FormField.ValidationMethods.None
    }),
  };

  public dateString: string;

  public documents: Array<IDocumentType> = [];
  latestAprovedVersion: any;
  latestDraftRecord: any;
  uploadedDocument: IDocumentType[] = [];
  hasDraftFromCompletedTasks: boolean = false;

  constructor(
    public session: Session,
    public currentUserService: CurrentUserService,
    public formRecordService: FormRecordService,
    public formService: FormService,
    public categoryService: CategoryService,
    private groupsService: GroupsService,
    public errorHandler: ErrorHandlerService,
    location: Location,
    private followUpService: FollowUpService,
    public documentInstanceService: DocumentIntanceService,
    public documentService: DocumentService,
    public documentDRMService: DocumentsService,
  ) {
    super(
      location
    );
  }

  registerFormFields() {
    this.formFields.push(...Object.keys(this.form).map((k: string) => this.form[k]));
  }

  ngOnInit() {
    this.dateString = moment().tz(environment.timeZone).format(this.dateFormat);

    this.registerFormFields();

    this.repopulateFormFromData();

    // Load the category name
    this.categoryService.getCategoryIdByName('Follow-Up').subscribe(categoryId =>
      this.followUpFormCategory = categoryId
    );

    this.getLatestAprovedDocument();
    this.getLatestDraft();
    this.populateUploadedDocumentsInCurrentStage(this.readOnly);
  }

  populateUploadedDocumentsInCurrentStage(readOnly: boolean) {
    if (!this.formData) {
      return;
    }
    if (!readOnly) {
      const completeRecords = this.formData.records.filter(record => (record.stage === 2 && record.documents.length > 0))
      if (completeRecords.length === 0) {
        return;
      }
      let documents = completeRecords[completeRecords.length - 1].documents;
      let uploadedDocument = documents[documents.length - 1];
      this.uploadedDocument.push(uploadedDocument);
      return;
    }
    const completeRecords = this.formData.records.filter(record => (record.stage === 2 && record.isComplete && record.documents.length > 0))
    if (completeRecords.length === 0) {
      return;
    }
    let documents = completeRecords[0].documents;
    let uploadedDocument = documents[documents.length - 1];
    this.uploadedDocument.push(uploadedDocument);
  }

  onSubmit(isDraft: boolean) {
    this.session.lockInput(() => {
      return new Promise(async (resolve, reject) => {

        let success = () => {
          resolve();

          this.goBack();
        };

        let fail = (msg: string, err: any) => {
          console.error(msg, err);
          this.errorHandler.handleHttpError(err);
          reject();
        };

        let stage: number = isDraft ? 2 : 3;
        let assignedUserId: number | null = null;
        let userGroupId = this.formData.userGroupId;

        if (this.currentUserService.userData)
          assignedUserId = this.currentUserService.userData.id;

        if (
          // If the form is ready to advance
          stage === 3
        ) {

          if (this.form.reassign.value && this.form.reassignToUserId.value) {
            // We actually want to send it back 1 for more detail
            stage = 1;

            assignedUserId = Number(this.form.reassignToUserId.value);
          } else if (this.formData.notifyOnComplete && this.formData.notifyOnComplete.length > 0) {
            // We are done. The server should fire off an email when we mark this complete
            stage = 4;

            assignedUserId = null;
          } else {
            // If this is destined for the next stage and further admin processing
            // It should go to the admin department
            // Updated to move next stage to "Update documents departments"
            let groups = await this.groupsService.getGroups().toPromise();

            let adminGroup = groups.find(group => group.groupName.match(/^Update Documents/gi) !== null);

            if (adminGroup)
              userGroupId = adminGroup.id;

            assignedUserId = null;
          }
        }

        let properties: Partial<IFormRecordPropertyParam>[] = [];

        /*
         * This second round of notifyOnComplete ocurrs after the determination as to wether or not
         * the form is complete because it is possible an additional email has been selected for notification
         * but is not the originator
         */

        this.formService.updateForm({
          id: this.formData.id,
          stage,
          userGroupId,
          assignedUserId,
        })
          .subscribe(() => {
            properties.push({
              name: "reassign",
              intData: this.form.reassign.value ? 1 : 0
            });

            if (this.form.reassign.value) {
              // TODO: This fields validation shoudl change from int > 0 to NONE when reassign is true/false
              properties.push({
                name: "reassignToUserId",
                intData: Number(this.form.reassignToUserId.value)
              });
            }

            properties.push({
              name: "followUps",
              jsonData: this.form.followUps.value
            });

            if (this.form.note.value.length > 0) {
              properties.push({
                name: "note",
                stringData: this.form.note.value
              });
            }

            // Generate the follows
            let followUps: { userGroupId: string, description: string, dueDate: string }[] = [];
            if (this.form.followUps.value.length) {
              followUps = JSON.parse(this.form.followUps.value);
            }

            if (this.uploadedDocument.length === 0) {
              this.uploadedDocument.push(this.documents[this.documents.length - 1]);
            }

            this.formRecordService.createRecord({
              formId: this.formData.id,
              // Intentionally cast the properties object since we know its correct
              properties: properties as any,
              stage: 2,
              documents: this.uploadedDocument.map(doc => ({ id: doc.id, isTicked: !!doc.isTicked })),
              isComplete: !isDraft
            })
              .subscribe((data: any) => {
                //Done creating the form and appending its properties
                if (isDraft || followUps.length === 0) {

                  if (stage === 4) {
                    this.formService.finalizeForm(this.formData.id)
                      .subscribe(() => {
                        if (!isDraft) {
                          let params = {
                            userGroupId: userGroupId,
                            stage: 3,
                            createdUserId: this.formData.createdById,
                            documentId: this.documentId,
                            completedBy: this.currentUserService.userData ? this.currentUserService.userData.id : null
                          }
                          this.formService.sendReviewFormNotifications(params).subscribe();
                        }
                        resolve();

                        this.goBack();
                      }, err => this.errorHandler.handleHttpError(err));
                  } else {
                    success();
                  }
                } else if (this.form.reassign.value && this.form.reassign.value) {
                  success();
                } else {
                  if (stage === 4) {
                    this.formService.finalizeForm(this.formData.id)
                      .subscribe(
                        () => {
                          this.followUpService.generateFollowUps(this.formData.id, this.formData.formLocationId, followUps, this.getFirstSubmissionType(), success);
                        },
                        err => fail('Error while finalizing a form', err)
                      );
                  } else {
                    let params = {
                      userGroupId: userGroupId,
                      stage: 3,
                      createdUserId: this.formData.createdById,
                      documentId: this.documentId,
                      completedBy: this.currentUserService.userData ? this.currentUserService.userData.id : null
                    }
                    this.formService.sendReviewFormNotifications(params).subscribe();
                    success();
                  }
                }
              }, err => fail('Error creating a record', err));
          }, err => fail('Error updating a form', err));
      });
    });
  }

  private getFirstSubmissionType(): number | null {
    let firstSubmission = this.getFirstSubmission();

    if (firstSubmission) {
      let formType = firstSubmission.properties.find(recordProperty => recordProperty.property.name === "documentReviewFormType");
      if (formType) {
        return formType.enumId;
      }
    }

    return null;
  }

  private getFirstSubmission(): IFormRecordOutputModel | null {
    if (!this.formData || !this.formData.records || !this.formData.records.length)
      return null;

    return this.formData.records.find(record => record.stage === 0 && record.isComplete) || null;
  }

  private repopulateFormFromData() {
    if (!this.formData || !this.formData.records || !this.formData.records.length)
      return;

    let stageRecords = this.formData.records.filter(record => record.stage === 2);
    let previousRecord = this.formData.records.slice().sort((a, b) => a.sequence > b.sequence ? 1 : -1).pop();

    /*
      If there are no pre-existing stage records, OR if the previous record is not a stage record,
      AND a sequence has not been specified (IE, not submitted), load followups from that previous record
    */
    if (!this.sequence && (stageRecords.length === 0 || (previousRecord && previousRecord.stage !== 2))) {
      /*
          At this point, we want to acknowledge the possibility that the
          previous stage specified followups that need to be completed
       */
      stageRecords = this.formData.records.filter(record => record.stage === 1);

      let mostRecentRecord = stageRecords.sort((a, b) => a.sequence > b.sequence ? 1 : -1).pop();

      if (!mostRecentRecord)
        return;

      let followUpRecord =
        mostRecentRecord.properties.find(recordProperty => recordProperty.property.name === 'followUps');

      if (followUpRecord) {
        this.form.followUps.value = followUpRecord.jsonData;
      }

      /**
       * aggregate documents and ticked documents from all previous complete records
       */
      const completeRecords = this.formData.records.filter(record => record.isComplete);

      let allDocuments: IDocumentType[] = [];
      let allTickedDocuments: { id: number }[] = [];

      for (const record of completeRecords) {
        if (record.documents && record.documents.length) {
          allDocuments = allDocuments.concat(record.documents.map(doc => ({ ...doc }))); // copy all documents object
          if (record.tickedDocuments && record.tickedDocuments.length) {
            allTickedDocuments = allTickedDocuments.concat(record.tickedDocuments)
          }
        }
      }

      this.documents = this.initTickedDocuments(allDocuments, allTickedDocuments);

      return;
    }

    if (!this.sequence) {
      let mostRecentRecord = stageRecords.sort((a, b) => a.sequence > b.sequence ? 1 : -1).pop();

      if (!mostRecentRecord)
        throw new Error("internal error");

      // If the most recent record was a submission, we are not going to use it
      if (mostRecentRecord.isComplete)
        return;

      this.formRecord = mostRecentRecord;
    } else {
      let targetRecord = stageRecords.find(record => record.sequence === this.sequence);

      if (!targetRecord)
        throw new Error("internal error");

      this.formRecord = targetRecord;
    }

    this.dateString = moment(this.formRecord.createdAt).tz(environment.timeZone).format(this.dateFormat);

    //Convert the properties into easily accessible IFormRecordPropertyParam
    if (!this.formRecord.properties)
      return;

    this.documents = this.initTickedDocuments(this.formRecord.documents, this.formRecord.tickedDocuments);

    let simpleProperties: { [key: string]: IFormRecordPropertyParam } = {};

    this.formRecord.properties.forEach(recordProperty => {
      //eject invalid property
      if (!recordProperty.property)
        return;

      let result: Partial<IFormRecordPropertyParam> = {
        name: recordProperty.property.name
      };

      if (recordProperty.stringData)
        result.stringData = recordProperty.stringData;

      if (recordProperty.intData)
        result.intData = recordProperty.intData;

      if (recordProperty.jsonData)
        result.jsonData = recordProperty.jsonData;

      if (recordProperty.enumId)
        result.enumId = recordProperty.enumId;

      simpleProperties[result.name as string] = result as IFormRecordPropertyParam;
    });

    /*
      If the last record for this stage is not the most recent record for this stage
      then it was passed back before coming forward again, and the reassignment
      should ALWAYS be 0
     */
    let allowReassignPrepopulate: boolean = true;
    /**
     *  FIX: added .slice() that copies an array to prevent mutation of original records
     **/
    let highestOrderRecord = this.formData.records.slice().sort((a, b) => a.sequence > b.sequence ? 1 : -1).pop();
    if (!this.sequence && highestOrderRecord && highestOrderRecord.sequence > this.formRecord.sequence)
      allowReassignPrepopulate = false;

    if (simpleProperties['reassign']
      && simpleProperties['reassign'].intData !== null
      && simpleProperties['reassign'].intData !== undefined
      && allowReassignPrepopulate)
      this.form.reassign.value = (simpleProperties['reassign'].intData > 0);

    if (simpleProperties['reassignToUserId'] && allowReassignPrepopulate)
      this.form.reassignToUserId.value = String(simpleProperties['reassignToUserId'].intData);

    if (simpleProperties['followUps'])
      this.form.followUps.value = simpleProperties['followUps'].jsonData;

    if (simpleProperties['note'])
      this.form.note.value = simpleProperties['note'].stringData;
  }

  public getLatestDraft() {
    this.formService.getFormsFromDocumentId(this.documentId).subscribe(data => {
      if (data.length === 0) {
        this.documentInstanceService.getDraftDocument(this.documentId).subscribe(data => {
          if (data) {
            this.latestDraftRecord = data;
            this.hasDraftFromCompletedTasks = true;
          } else {
            this.latestDraftRecord = null;
          }
        });
      } else {
        const formsCreatedAtArray = data.map(form => new Date(form.createdAt)),
          latestFormCreatedAt = new Date(Math.max.apply(null, formsCreatedAtArray)),
          latestFormRecords = data.find(form => (new Date(form.createdAt).getTime() === latestFormCreatedAt.getTime())).records,
          latestRecord = latestFormRecords.find(record => new Date(record.createdAt).getTime() === new Date(Math.max.apply(null, latestFormRecords.filter(record => !!record.documents.length).map(record => new Date(record.createdAt)))).getTime())

        this.latestDraftRecord = latestRecord;
      }
    });
  }

  public downloadLatestDraft() {
    if (this.latestDraftRecord === null) {
      this.downloadLatestAprovedVersion();
    } else if (this.hasDraftFromCompletedTasks && this.latestDraftRecord) {
      this.documentInstanceService.downloadDraftDocument(this.latestDraftRecord.id).subscribe(data => {
        saveAs(data, this.latestDraftRecord.originalFileName);
      })
    } else {
      this.documentService.downloadDocument(this.latestDraftRecord.documents[0].id).subscribe(file => {
        saveAs(file, this.latestDraftRecord.documents[0].fileName)
      });
    }
  }
  private getLatestAprovedDocument() {
    if (this.formData) {
      const formPropertiesArray = this.formData.records.map(record => record.properties),
        flatenedFormPropertiesArray = [].concat.apply([], formPropertiesArray),
        propertyWithDocumentId = flatenedFormPropertiesArray.find(property => property.property.name === "documentId");
      this.documentId = propertyWithDocumentId.stringData;

    }

    this.documentDRMService.getDocumentInstanceByDocumentId(this.documentId).subscribe(file => {
      this.latestAprovedVersion = file;
    });
  }

  public downloadLatestAprovedVersion() {
    this.documentInstanceService.downloadDocument(this.latestAprovedVersion.id).subscribe(data => {
      saveAs(data, this.latestAprovedVersion.fileName);
    });
  }
}
