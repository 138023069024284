import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import {GenericService} from "./GenericService";
import { IFormsQueryParams } from "../../common/contracts/form";
import { stringifyObjectValues } from "./util/stringifyObjectValues";

@Injectable()
export class ReportService extends GenericService {

  static apiPrefix = '/api/report';

  constructor(private http: HttpClient) {
    super();
  }

  public downloadReadOnlyFormsReport(formId: number) {
    return this.http.get(`${ReportService.apiPrefix}/${formId}` , {
      responseType: 'blob',
    });
  }

  public downloadDashboardPdfReport(params: IFormsQueryParams) {
    const queryParams = new HttpParams({
      fromObject: stringifyObjectValues(params)
    });

    return this.http.get(`${ReportService.apiPrefix}/dashboardPdf` , {
      params: queryParams,
      responseType: 'blob',
    });
  }

  public downloadDashboardCsvReport(params: IFormsQueryParams) {
    const queryParams = new HttpParams({
      fromObject: stringifyObjectValues(params)
    });

    return this.http.get(`${ReportService.apiPrefix}/dashboardCsv` , {
      params: queryParams,
      responseType: 'blob',
    });
  }

}
