import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { forkJoin, of } from "rxjs";
import { UsersService } from "../../../../../service/admin/UsersService";
import { rolesList } from "../../../../../../common/contracts/users";
import { GroupsService } from "../../../../../service/admin/GroupsService";
import { RxFormsService } from "../../../../../service/util/reactiveForms";
import { ModalService } from "../../../../../service/ModalService";
import { ErrorHandlerService } from "../../../../../service/ErrorHandlerService";
import { emailValidator, trimRequiredValidator, validateConfirmPassword } from "../../../../../validators/reactiveFormValidators";
import { LocationService } from "../../../../../service/LocationService";
import { CurrentUserService } from 'service/currentUser/CurrentUserService';
import { Session } from 'service/util/Session';
import { AuthService } from 'service/security/AuthService';
var EditUserComponent = /** @class */ (function () {
    function EditUserComponent(usersService, groupService, locationService, router, route, activatedRoute, modalService, errorHandlerService, currentUserService, session, authService) {
        var _this = this;
        this.usersService = usersService;
        this.groupService = groupService;
        this.locationService = locationService;
        this.router = router;
        this.route = route;
        this.activatedRoute = activatedRoute;
        this.modalService = modalService;
        this.errorHandlerService = errorHandlerService;
        this.currentUserService = currentUserService;
        this.session = session;
        this.authService = authService;
        this.isAdmin = false;
        this.isNew = false;
        this.groupsOptions = [];
        this.locationsOptions = [];
        this.rolesOptions = rolesList.map(function (role) { return ({ id: role, text: role }); });
        this.setPassword = false;
        this.userId = null;
        this.isLoaded = false;
        this.isSelectorSet = {};
        this.userForm = new FormGroup({
            firstName: new FormControl('', trimRequiredValidator),
            lastName: new FormControl('', trimRequiredValidator),
            username: new FormControl('', [trimRequiredValidator, emailValidator]),
            phone: new FormControl('', Validators.pattern(/^\d{10}$/)),
            password: new FormControl(''),
            confirmPassword: new FormControl('', validateConfirmPassword),
            role: new FormControl('', Validators.required),
            selectedGroups: new FormControl([]),
            selectedLocations: new FormControl([]),
        });
        this.userForm.controls.password.valueChanges.subscribe(function (value) {
            _this.userForm.controls.confirmPassword.updateValueAndValidity();
            if (value) {
                _this.userForm.controls.confirmPassword.markAsTouched();
            }
        });
    }
    EditUserComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.activatedRoute.params.subscribe(function (params) {
            if (params.userId) {
                if (/^\d+$/.test(params.userId) !== true) {
                    console.error("Invalid user router param");
                    _this.router.navigate(["/404"], { replaceUrl: true });
                    return;
                }
            }
            _this.userId = parseInt(params.userId, 10);
            forkJoin(_this.groupService.getGroups(), _this.locationService.getLocations(), params.userId ? _this.usersService.getUserById(_this.userId) : of(null)).subscribe({
                next: function (_a) {
                    var groups = _a[0], locations = _a[1], user = _a[2];
                    _this.isAdmin = _this.currentUserService.isAdministrator.getValue();
                    _this.groupsOptions = groups.map(function (group) { return ({
                        id: group.id.toString(),
                        text: group.groupName
                    }); });
                    _this.locationsOptions = locations.map(function (location) { return ({
                        id: location.id.toString(),
                        text: location.name
                    }); });
                    if (user !== null) {
                        _this.user = user;
                        _this.userForm.setValue({
                            firstName: user.firstName,
                            lastName: user.lastName,
                            username: user.username,
                            phone: user.phone,
                            role: user.role,
                            password: "",
                            confirmPassword: "",
                            selectedGroups: user.groups ? user.groups.map(function (group) { return group.id.toString(); }) : [],
                            selectedLocations: user.locations ? user.locations.map(function (location) { return location.id.toString(); }) : []
                        });
                    }
                    else {
                        _this.isNew = true;
                        _this.setPassword = true;
                        _this.userForm.controls.password.setValidators([Validators.required, Validators.minLength(6)]);
                    }
                    _this.userForm.markAsPristine();
                    _this.isLoaded = true;
                },
                error: function (error) { return _this.errorHandlerService.handleHttpError(error); }
            });
        });
    };
    EditUserComponent.prototype.toggleChangePassword = function () {
        this.setPassword = !this.setPassword;
        if (this.setPassword) {
            this.userForm.controls.password.setValidators([Validators.required, Validators.minLength(6)]);
        }
        else {
            this.userForm.controls.password.clearValidators();
            this.userForm.controls.password.setValue('');
            this.userForm.controls.password.markAsUntouched();
            this.userForm.controls.confirmPassword.setValue('');
            this.userForm.controls.confirmPassword.markAsUntouched();
        }
    };
    EditUserComponent.prototype.handleSelectorChange = function (fieldName, event) {
        var control = this.userForm.get(fieldName);
        if (!control) {
            console.error("Cannot find control " + fieldName + " in userForm");
            return;
        }
        control.setValue(event.value);
        if (this.isSelectorSet[fieldName]) {
            control.markAsTouched();
            this.userForm.markAsDirty();
        }
        else {
            this.isSelectorSet[fieldName] = true;
        }
    };
    EditUserComponent.prototype.submit = function () {
        var _this = this;
        if (this.userForm.invalid) {
            RxFormsService.touchAllFormControls(this.userForm);
            return;
        }
        var formData = this.userForm.getRawValue();
        var observer = {
            next: function () { return _this.goBack(); },
            error: function (error) { return _this.errorHandlerService.handleHttpError(error); },
        };
        if (this.userId) {
            this.usersService.updateUser({
                id: this.userId,
                username: formData['username'].trim(),
                firstName: formData['firstName'].trim(),
                lastName: formData['lastName'].trim(),
                role: formData['role'],
                phone: formData['phone'] || null,
                groups: formData['selectedGroups'].map(function (groupId) { return parseInt(groupId, 10); }),
                locations: formData['selectedLocations'].map(function (locationId) { return parseInt(locationId, 10); }),
                password: this.setPassword ? formData['password'] : null,
            }).subscribe(observer);
        }
        else {
            this.usersService.createUser({
                username: formData['username'].trim(),
                firstName: formData['firstName'].trim(),
                lastName: formData['lastName'].trim(),
                role: formData['role'],
                password: formData['password'],
                phone: formData['phone'] || null,
                groups: formData['selectedGroups'].map(function (groupId) { return parseInt(groupId, 10); }),
                locations: formData['selectedLocations'].map(function (locationId) { return parseInt(locationId, 10); }),
            }).subscribe(observer);
        }
    };
    EditUserComponent.prototype.archiveUser = function () {
        var _this = this;
        this.modalService.confirmRx({
            title: 'Warning',
            message: 'Are you sure that you want to delete this user?'
        }).subscribe(function (isConfirmed) {
            if (isConfirmed && _this.userId) {
                _this.usersService.archiveUser(_this.userId).subscribe(function () { return _this.goBack(); }, function (error) { return _this.errorHandlerService.handleHttpError(error); });
            }
        });
    };
    EditUserComponent.prototype.goBack = function () {
        this.router.navigate(['..'], { relativeTo: this.route });
    };
    EditUserComponent.prototype.emulateUser = function () {
        var _this = this;
        console.log(this.user);
        if (!this.user || !this.user.id) {
            alert("This user cannot be emulated.");
            return;
        }
        this.session.lockInputRx(this.authService.switch(this.user.id)).subscribe(function (data) {
            // Grant the credentials
            _this.authService.token = data.accessToken;
            _this.session.credentials = data;
            window.location.href = window.location.origin + _this.router.createUrlTree(['/dashboard']);
        }, function (err) {
            alert(err.message);
        });
    };
    return EditUserComponent;
}());
export { EditUserComponent };
