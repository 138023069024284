
      <div class="row" *ngIf="!readOnly">
          <div class="col-12">
              <button class="btn btn-orange rounded-0 btn-block" (click)="fileInput.click()" [disabled]="isUploading">
                  <i class="fa fa-upload" *ngIf="!isUploading"></i>
                  <i class="fa fa-circle-o-notch fa-spin" *ngIf="isUploading"></i>
                  <strong class="upload-title">{{isUploading ? 'UPLOADING' : 'UPLOAD FILE'}}</strong>
              </button>
              <input class="hidden-input" type="file" #fileInput (change)="handleFileSelected($event)">
          </div>
      </div>
      <div class="row" *ngIf="!disableTicking">
        <div class="col-12 pt-2">
            How to configure attachments<br />
            <i class="fa fa-paperclip attachment-icon text-primary"></i> Send File to Originator<br />
            <i class="fa fa-paperclip attachment-icon light-grey-text"></i> Not For Originator<br />
        </div>
      </div>
      
      <ng-container *ngIf="displayUploads">
        <div class="row mt-2 mt-lg-3">
            <div class="col-12">
                <span>Files Uploaded:</span>
            </div>
        </div>
        <div *ngIf="documents.length === 0" class="mt-1 pt-1 pl-3 border-top">
            <span>No Files Uploaded</span>
        </div>
        
        <div *ngFor="let document of documents; let i = index" class="mt-1 pt-1 border-top">
            <i class="fa fa-paperclip attachment-icon"
               *ngIf="!disableTicking"
               [title]="document.isTicked ? 'Detach document from the final report' : 'Attach document to the final report'"
               [ngClass]="{'text-primary': document.isTicked, 'light-grey-text': !document.isTicked}"
               (click)="handleDocumentTick(document)"></i>&nbsp;
            <span class="download-document-reference" (click)="handleDocumentDownload(document)">{{document.fileName}}</span>
            <span class="remove-document-reference" title="Remove document" (click)="handleRemoveDocument(i)" *ngIf="!readOnly">&times;</span>
        </div>
      </ng-container>
  