<div class="modal-header">
    <h4 class="modal-title">{{options.title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="cancel()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="col-12 offset-0">
        <div class="form-group">
            <label>{{options.label || 'Select date'}}:</label>
            <date-picker #datePicker [(value)]="value" [required]="true"></date-picker>
            <div class="input-group pt-2" *ngIf="options.includeTime">
                <label class="form-control px-2 text-right flex-grow-2">Hours&nbsp;(24h):</label>
                <input class="form-control text-center"
                  [disabled]="disabled"
                  type="number"
                  placeholder="HH"
                  min="0" max="23"
                  [(ngModel)]="hours"
                >
                <label class="form-control px-2 text-right">Mins:</label>
                <input class="form-control text-center"
                  [disabled]="disabled"
                  type="number"
                  placeholder="HH"
                  min="1" max="59"
                  [(ngModel)]="minutes"
                >
                <label class="form-control text-center">(AEST)</label>
            </div>

        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="submit()" ngbAutofocus [disabled]="datePicker.isDateInvalid">
        {{options.submitText || 'Submit'}}
    </button>
    <button type="button" class="btn btn-secondary" (click)="cancel()">{{options.declineText || 'Cancel'}}</button>
</div>
