var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { catchError } from 'rxjs/operators';
import { GenericService } from "../GenericService";
import { HttpClient } from '@angular/common/http';
var AuthService = /** @class */ (function (_super) {
    __extends(AuthService, _super);
    function AuthService(http) {
        var _this = _super.call(this) || this;
        _this.http = http;
        return _this;
    }
    AuthService.prototype.login = function (credentials) {
        return this.http.post('/api/auth', credentials)
            .pipe(catchError(this.handleError));
    };
    AuthService.prototype.forgotPassword = function (params) {
        return this.http.post('/api/forgot', params)
            .pipe(catchError(this.handleError));
    };
    AuthService.prototype.resetPassword = function (params) {
        return this.http.post('/api/reset', params)
            .pipe(catchError(this.handleError));
    };
    AuthService.prototype.switch = function (userId) {
        return this.http.get("/api/switch/" + userId)
            .pipe(catchError(this.handleError));
    };
    AuthService.prototype.eyewatchSSO = function (token, server, logoutUrl) {
        return this.http.post('/api/murriwatchSSO', { token: token, server: server, logoutUrl: logoutUrl })
            .pipe(catchError(this.handleError));
    };
    return AuthService;
}(GenericService));
export { AuthService };
