var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { FormService } from "../../../../service/FormService";
import { ActivatedRoute } from "@angular/router";
import { Session } from "../../../../service/util/Session";
var has = Reflect.has;
import { ErrorHandlerService } from "../../../../service/ErrorHandlerService";
import { ComponentCanDeactivate } from "../../../shared/canDeactivate/ComponentCanDeactivate";
import { AuditFormStage0Component } from "./stage0/auditFormStage0.component";
import { AuditFormStage1Component } from "./stage1/auditFormStage1.component";
import { AuditFormStage2Component } from './stage2/auditFormStage2.component';
import { zip } from 'rxjs';
import { map } from 'rxjs/operators';
// import {AuditFormStage3Component} from './stage3/auditFormStage3.component';
export { AuditFormStage0Component } from "./stage0/auditFormStage0.component";
export { AuditFormStage1Component } from "./stage1/auditFormStage1.component";
export { AuditFormStage2Component } from './stage2/auditFormStage2.component';
export { AuditFormStage3Component } from './stage3/auditFormStage3.component';
var AuditFormComponent = /** @class */ (function (_super) {
    __extends(AuditFormComponent, _super);
    function AuditFormComponent(formService, activatedRoute, session, errorHandler) {
        var _this = _super.call(this) || this;
        _this.formService = formService;
        _this.activatedRoute = activatedRoute;
        _this.session = session;
        _this.errorHandler = errorHandler;
        _this.showForm = false;
        _this.formData = null;
        _this.childForms = null;
        _this.isTemplate = false;
        return _this;
    }
    AuditFormComponent.prototype.canDeactivate = function () {
        if (!this.showForm)
            return true;
        if (this.formData && this.formData.isDeleted) {
            return true;
        }
        if (this.targetStage === 0)
            return this.auditStage0Form.canDeactivate();
        if (this.targetStage === 1)
            return this.auditStage1Form.canDeactivate();
        if (this.targetStage === 2)
            return this.auditStage1Form.canDeactivate();
        return true;
    };
    AuditFormComponent.prototype.ngOnInit = function () {
        var _this = this;
        zip(this.activatedRoute.params, this.activatedRoute.data).pipe(map(function (_a) {
            var params = _a[0], data = _a[1];
            return { params: params, data: data };
        }))
            .subscribe(function (result) {
            var params = result.params, data = result.data;
            if (data.isTemplate)
                _this.isTemplate = data.isTemplate;
            if (!has(params, "reportId")) {
                _this.showForm = true;
                _this.targetStage = 0;
                _this.formData = null;
                _this.childForms = null;
                return;
            }
            _this.session.lockInputRx(_this.formService.getFormById(params["reportId"])).subscribe(function (form) {
                _this.formData = form;
                if (_this.formData) {
                    if (_this.formData.records.length === 0) {
                        _this.errorHandler.raiseError("Record did not contain valid data. Please contact a system administrator for assistance.", "Invalid Form Data");
                        return;
                    }
                    _this.targetStage = _this.formData.stage || 0;
                    _this.showForm = true;
                    if ((_this.formData.archivedAt || _this.isTemplate) && _this.formData.childForms && _this.formData.childForms.length) {
                        _this.childForms = _this.formData.childForms;
                    }
                    else {
                        _this.childForms = null;
                    }
                }
            }, function (err) {
                console.error('Error while getting audit form', err);
                _this.errorHandler.handleHttpError(err);
            });
        });
    };
    return AuditFormComponent;
}(ComponentCanDeactivate));
export { AuditFormComponent };
