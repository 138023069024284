import { Component, Input, OnInit } from '@angular/core';
import { Session } from '../../service/util/Session';
import { CurrentUserService } from "../../service/currentUser/CurrentUserService";
import { Router } from '@angular/router';

@Component({
  selector: 'authentication-icon',
  templateUrl: './authentication-icon.component.html',
  styleUrls: ['./authentication-icon.component.css'],
})
export class AuthenticationIconComponent implements OnInit {
  @Input() small = false;

  constructor(
    public session: Session,
    private currentUserService: CurrentUserService,
    private router: Router,
  ) { }

  ngOnInit() {
  }

  public signOut() {
    this.session.lockInput(async () => {
      const userData = this.currentUserService.currentUserData.getValue();
      let logoutUrl: string | undefined = undefined;

      if (userData) {
        logoutUrl = userData.logoutUrl
      }
      
      this.currentUserService.cleanUserCredentials();
      await this.session.init();

      if (logoutUrl) {
        window.location.href = logoutUrl;
      } else {
        this.router.navigateByUrl('/');
      }
    });
  }
}
