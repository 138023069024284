import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { UsersService } from "../../../../../service/admin/UsersService";
import { IUsersSortedField, IUserOutputModel } from "../../../../../../common/contracts/users";
import { IGroupsSortedField } from "../../../../../../common/contracts/groups";
import { ISortOrderValue } from "../../../../../../common/contracts/common";
import { PersistentStorageService } from "../../../../../service/PersistentStorageService";
import { ErrorHandlerService } from "../../../../../service/ErrorHandlerService";

@Component({
  selector: 'app-groups-component',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit {

  public users: IUserOutputModel[] = [];
  public totalItems = 0;
  public currentPage = 1;
  public pageSize: number = this.pss.pageSize;
  public sortBy: IGroupsSortedField = 'createdAt';
  public sortOrder: ISortOrderValue = 'asc';

  constructor(
    private usersService: UsersService,
    private router: Router,
    private route: ActivatedRoute,
    private pss: PersistentStorageService,
    private errorHandler: ErrorHandlerService,
    ) {
  }

  ngOnInit() {
    this.handlePageChange();
  }

  public handleUserClick(id: number | string) {
    this.router.navigate([`${id}`], {relativeTo: this.route});
  }

  public handlePageChange() {
    this.usersService.getUsers({
      skip: this.pageSize * (this.currentPage - 1),
      limit: this.pageSize,
      sortBy: this.sortBy,
      order: this.sortOrder,
      search: undefined
    }).subscribe((data) => {
      this.users = data.items;
      this.totalItems = data.totalCount;
    }, (err) => this.errorHandler.handleHttpError(err));
  }

  public handleSortChange(sortField: IUsersSortedField) {
    if (sortField === this.sortBy) {
      this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortBy = sortField;
      this.sortOrder = 'asc';
    }
    this.handlePageChange();
  }
}
