import { Component, OnInit } from '@angular/core';
import { DocumentCollectionsService } from "../../../../../service/admin/DocumentCollectionsService";
import { IDocumentCollectionSortedField, IDocumentCollectionOutputModel } from "../../../../../../common/contracts/documentCollection";
import { ActivatedRoute, Router } from "@angular/router";
import { ISortOrderValue } from "../../../../../../common/contracts/common";
import { PersistentStorageService } from "../../../../../service/PersistentStorageService";
import { ErrorHandlerService } from "../../../../../service/ErrorHandlerService";
import { CurrentUserService } from 'service/currentUser/CurrentUserService';

@Component({
  selector: 'app-groups-component',
  templateUrl: './documentCollections.component.html',
  styleUrls: ['./documentCollections.component.scss']
})
export class DocumentCollectionsComponent implements OnInit {

  public documentCollections: IDocumentCollectionOutputModel[] = [];
  public totalItems = 0;
  public currentPage = 1;
  public pageSize = this.pss.pageSize;
  public sortBy: IDocumentCollectionSortedField = 'name';
  public sortOrder: ISortOrderValue = 'asc';
  public isAdmin: boolean = false;

  constructor(private groupsService: DocumentCollectionsService,
    private router: Router,
    private route: ActivatedRoute,
    private pss: PersistentStorageService,
    private errorHandler: ErrorHandlerService,
    private userService: CurrentUserService,) { }

  ngOnInit() {
    this.isAdmin = this.userService.isAdministrator.getValue();
    this.handlePageChange();
  }

  public handleDocumentCollectionClick(groupId: number | string) {
    this.router.navigate([`${groupId}`], { relativeTo: this.route });
  }

  public handlePageChange() {
    this.groupsService.getPagedDocumentCollections(
      this.pageSize * (this.currentPage - 1),
      this.pageSize,
      this.sortBy,
      this.sortOrder
    ).subscribe((data) => {
      this.documentCollections = data.items;
      this.totalItems = data.totalCount;
    }, (err) => this.errorHandler.handleHttpError(err));
  }

  public handleSortChange(sortField: IDocumentCollectionSortedField) {
    if (sortField === this.sortBy) {
      this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortBy = sortField;
      this.sortOrder = 'asc';
    }

    this.handlePageChange();
  }

}
