import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'service/security/AuthService';
import { Session } from 'service/util/Session';
import { JWTPayload } from '../../../model/Auth';

@Component({
  selector: 'app-splash',
  templateUrl: './splash.component.html',
  styleUrls: ['./splash.component.css'],
})
export class SplashComponent {
  constructor(
    private session: Session,
    private authService: AuthService,
    private router: Router,
    public activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(query => {
      if (query.hasOwnProperty('token') && query.hasOwnProperty('logoutUrl') && query.hasOwnProperty('server')) {

        const token = query.token;
        const tokenServer = query.server;
        const logoutUrl = query.logoutUrl;

        if (token.length && tokenServer.length) {
          // Begin Eyewatch SSO
          this.session.lockInputRx(this.authService.eyewatchSSO(token, tokenServer, logoutUrl)).subscribe((data: JWTPayload) => {
            this.authService.token = data.accessToken;
            this.session.credentials = data as JWTPayload;
            this.router.navigateByUrl('/dashboard');
          });
        }
      }
    });
  }
}
