import { OnInit } from "@angular/core";
import * as moment from 'moment';
import "moment-timezone";
import { CurrentUserService } from "../../service/currentUser/CurrentUserService";
import { FormRecordService } from "../../service/FormRecordService";
import { ModalService } from "../../service/ModalService";
import { ErrorHandlerService } from "../../service/ErrorHandlerService";
import { environment } from "../../environments/environment";
var SubmissionDetailComponent = /** @class */ (function () {
    function SubmissionDetailComponent(currentUserService, formRecordService, modalService, errorHandler) {
        this.currentUserService = currentUserService;
        this.formRecordService = formRecordService;
        this.modalService = modalService;
        this.errorHandler = errorHandler;
        //TODO: Move this to a config somewhere
        this.dateFormat = "DD-MM-YYYY, h:mm a";
        this.userString = "";
        this.dateString = "";
        this.isDateEditable = false;
    }
    Object.defineProperty(SubmissionDetailComponent.prototype, "formRecord", {
        get: function () {
            return this._formRecord;
        },
        set: function (val) {
            this._formRecord = val;
            this.setOutputStrings();
        },
        enumerable: true,
        configurable: true
    });
    SubmissionDetailComponent.prototype.ngOnInit = function () {
        this.setOutputStrings();
    };
    SubmissionDetailComponent.prototype.setOutputStrings = function () {
        if (this.formRecord && this.formRecord.isComplete) {
            this.isDateEditable = this.currentUserService.isAdministrator.getValue();
            this.dateString = moment(this.formRecord.createdAt).tz(environment.timeZone).format(this.dateFormat);
            this.userString = this.formRecord.createdByUser.firstName + ' ' + this.formRecord.createdByUser.lastName;
        }
        else {
            this.isDateEditable = false;
            this.dateString = moment().tz(environment.timeZone).format(this.dateFormat);
            this.userString = this.currentUserService.currentUserFullName.getValue();
        }
    };
    SubmissionDetailComponent.prototype.updateRecordDate = function () {
        var _this = this;
        this.modalService.selectDate({
            title: 'Record Submission Date',
            label: 'Submission date',
            submitText: 'Update',
            defaultValue: moment(this.formRecord.createdAt).tz(environment.timeZone).format('DD/MM/YYYY'),
            defaultHours: moment(this.formRecord.createdAt).tz(environment.timeZone).format('HH'),
            defaultMinutes: moment(this.formRecord.createdAt).tz(environment.timeZone).format('mm'),
            includeTime: true
        }).then(function (result) {
            var newCreatedAt = moment(result.date, "DD/MM/YYYY").tz(environment.timeZone).set({
                'hour': result.time && result.time.hours || 12,
                'minute': result.time && result.time.minutes || 0,
                'second': 0
            }).toISOString();
            _this.formRecordService.updateRecordDate({
                recordId: _this._formRecord.id,
                date: newCreatedAt,
            }).subscribe(function () {
                _this.formRecord.createdAt = newCreatedAt;
                _this.setOutputStrings();
            }, function (err) { return _this.errorHandler.handleHttpError(err); });
        }).catch(function () { });
    };
    return SubmissionDetailComponent;
}());
export { SubmissionDetailComponent };
