import { OnInit, EventEmitter } from '@angular/core';
import { Session } from "../../service/util/Session";
import { FormField } from "../../model/Form";
import { Select2Component } from 'ng2-select2';
import { ErrorHandlerService } from "../../service/ErrorHandlerService";
import { CategoryService } from "../../service/CategoryService";
import { map } from 'rxjs/operators';
var FormCategorySelectComponent = /** @class */ (function () {
    function FormCategorySelectComponent(categoryService, session, errorHandler) {
        this.categoryService = categoryService;
        this.session = session;
        this.errorHandler = errorHandler;
        this._value = '';
        // Implies a read only state
        this.disabled = false;
        this.defaultValue = '';
        this.formCategorySelectData = [];
        this.change = new EventEmitter();
    }
    Object.defineProperty(FormCategorySelectComponent.prototype, "value", {
        get: function () {
            return this._value;
        },
        set: function (newValue) {
            this._value = newValue;
            if (!this.field) {
                this.defaultValue = this._value;
            }
        },
        enumerable: true,
        configurable: true
    });
    FormCategorySelectComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.formCategorySelectOptions = { allowClear: true, placeholder: this.placeholder || "Form Type" };
        this.session.lockInputRx(this.categoryService.getCategories().pipe(map(function (categories) { return categories.filter(function (cat) { return cat.name !== 'DRM-Task'; }); })))
            .subscribe(function (items) {
            var newSelectOptions = [{ id: "", text: "" }];
            items.forEach(function (category) { return newSelectOptions.push({ id: category.id.toString(), text: category.name === 'Report' ? 'Feedback' : category.name }); });
            _this.formCategorySelectData = newSelectOptions;
            //Force the change detection to cycle again to prevent race
            if (_this.field) {
                if (String(_this.field.value) !== _this.defaultValue)
                    _this.defaultValue = String(_this.field.value);
            }
            else {
                //If the default value was manually set we need to re-trigger the process
                if (_this._value !== '') {
                    _this.defaultValue = _this.value;
                    var option = _this.formCategorySelectData.find(function (o) { return o.id === _this._value; });
                    if (option) {
                        option.selected = true; // this is only way to select a group after page reload
                    }
                }
            }
        }, function (err) { return _this.errorHandler.handleHttpError(err); });
    };
    FormCategorySelectComponent.prototype.valueChanged = function (selectedOpts) {
        if (selectedOpts.length === 0 || (selectedOpts.length === 1 && selectedOpts[0].id.length === 0 && selectedOpts[0].text.length === 0)) {
            if (this.field)
                this.field.value = null;
        }
        if (selectedOpts.length > 1)
            throw ("Selected options unexpectedly contained multiple results");
        if (selectedOpts.length === 1) {
            if (this.field) {
                this.field.value = selectedOpts[0].id;
            }
            else {
                if (this._value !== selectedOpts[0].id) { // if the value has been changed - emit event
                    this._value = selectedOpts[0].id;
                    this.change.emit(selectedOpts);
                }
            }
        }
    };
    return FormCategorySelectComponent;
}());
export { FormCategorySelectComponent };
