import { OnInit, EventEmitter } from "@angular/core";
import { LocationService } from "../../service/LocationService";
import { Session } from "../../service/util/Session";
import { FormField } from "../../model/Form";
import { Select2Component } from "ng2-select2";
import { ErrorHandlerService } from "../../service/ErrorHandlerService";
import { CurrentUserService } from "service/currentUser/CurrentUserService";
import { logger } from "service/util/Logger";
import { numStrToArr } from "service/util/converter";
var LocationSelectComponent = /** @class */ (function () {
    function LocationSelectComponent(locationService, session, errorHandler, currentUserService) {
        this.locationService = locationService;
        this.session = session;
        this.errorHandler = errorHandler;
        this.currentUserService = currentUserService;
        /**
         * Restrict available location to the user locations attached to the current user
         */
        this.restrictLocations = false;
        this.locationSelectData = [];
        this.defaultValue = "";
        this._value = '';
        this._multiple = false;
        this.disabled = false;
        this.change = new EventEmitter();
    }
    Object.defineProperty(LocationSelectComponent.prototype, "value", {
        get: function () {
            return this._value;
        },
        /**
         * By preventing assigning non-unique values during the set
         * we can prevent a loop of observable subscribers
         */
        set: function (newValue) {
            this._value = newValue;
            if (!this.field && !this.multiple) {
                this.defaultValue = this._value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LocationSelectComponent.prototype, "multiple", {
        get: function () {
            return this._multiple;
        },
        set: function (newValue) {
            this._multiple = newValue;
            if (this.locationSelectOptions)
                this.locationSelectOptions.multiple = newValue;
        },
        enumerable: true,
        configurable: true
    });
    /** My god this is complex. It needs to be rewritten completely */
    LocationSelectComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.locationSelectOptions = { allowClear: !this.multiple, placeholder: this.placeholder || "All Locations", multiple: this.multiple };
        this.session.lockInputRx(this.locationService.getLocations())
            .subscribe(function (items) {
            var newSelectOptions = [{ id: "", text: "" }];
            // remove groups that is not assigned to current user with role manager.
            if (_this.restrictLocations) {
                var locations_1 = _this.currentUserService.currentUserData.value.locations;
                if (locations_1 && locations_1.length > 0) {
                    items = items.filter(function (item) {
                        return !!locations_1.find(function (location) { return location.id === item.id; });
                    });
                }
            }
            var addedOptions = false;
            if (!_this.field || !_this.multiple || (_this.multiple && _this.field && String(_this.field.value).length === 0)) {
                items.forEach(function (location) { return newSelectOptions.push({ id: String(location.id), text: location.name }); });
                _this.locationSelectData = newSelectOptions;
                addedOptions = true;
            }
            //Force the change detection to cycle again to prevent race
            if (_this.field) {
                var strVal = String(_this.field.value);
                if (strVal !== _this.defaultValue) {
                    var locationIds_1 = numStrToArr(strVal);
                    if (!addedOptions) {
                        items.forEach(function (location) { return newSelectOptions.push({
                            id: String(location.id),
                            text: location.name,
                            selected: !!locationIds_1.find(function (id) { return location.id === id; })
                        }); });
                        _this.locationSelectData = newSelectOptions;
                        addedOptions = true;
                    }
                    else {
                        _this.defaultValue = strVal;
                    }
                }
            }
            else {
                //If the default value was manually set we need to re-trigger the process
                if (_this._value !== '') {
                    var valueArr_1 = _this.multiple && _this._value && _this._value.length ? _this._value.split(",") : undefined;
                    _this.defaultValue = _this.value;
                    var options = _this.locationSelectData.filter(function (o) { return (_this.multiple ? (valueArr_1 ? valueArr_1.find(function (selectedValue) { return selectedValue === o.id; }) : false) : o.id === _this._value); });
                    if (options && options.length) {
                        options.map(function (opt) { return opt.selected = true; });
                    }
                }
            }
        }, function (err) { return _this.errorHandler.handleHttpError(err); });
    };
    LocationSelectComponent.prototype.valueChanged = function (selectedOpts) {
        if (selectedOpts.length === 0 || (selectedOpts.length === 1 && selectedOpts[0].id.length === 0 && selectedOpts[0].text.length === 0)) {
            if (this.field)
                this.field.value = null;
        }
        if (selectedOpts.length > 1 && !this.multiple)
            throw ("Selected options unexpectedly contained multiple results");
        if (selectedOpts.length === 1 && !this.multiple) {
            if (this.field) {
                this.field.value = selectedOpts[0].id;
            }
            else {
                if (this._value !== selectedOpts[0].id) { // if the value has been changed - emit event
                    this._value = selectedOpts[0].id;
                    this.change.emit(selectedOpts);
                    return;
                }
            }
        }
        if (this.multiple) {
            var actualSelected = selectedOpts.filter(function (opt) { return opt.id && opt.id.length; });
            var newValue = actualSelected.map(function (opt) { return opt.id; }).join(",");
            if (this.field) {
                console.log("Setting Field Value ", newValue);
                this.field.value = newValue;
            }
            else {
                logger.silly("Checking internalValue change Value[(" + typeof newValue + ") " + newValue + "] !== _value[(" + typeof this._value + ") " + this._value + "]");
                if (this._value !== newValue) { // if the value has been changed - emit event
                    logger.silly("userGroupSelectComponent: Updating Internal Value");
                    this._value = newValue;
                    this.change.emit(actualSelected);
                }
            }
        }
    };
    return LocationSelectComponent;
}());
export { LocationSelectComponent };
