<div *ngIf="!showForm">
    Loading...
</div>
<form-actions [formData]="formData"></form-actions>
<div class="row" *ngIf="showForm && formData && formData.parentFormId">
    <div class="col-12 text-center">
        <a [routerLink]="['/report', formData.ancestorFormId]" target="_blank">Parent Form</a>
    </div>
</div>
<audit-form-0
    [formData]="formData"
    [isTemplate]="isTemplate"
    *ngIf="showForm && targetStage === 0 && (!formData || !formData.isDeleted)"
    #auditStage0>
</audit-form-0>
<audit-form-1
    [formData]="formData"
    *ngIf="showForm && targetStage === 1 && (!formData || !formData.isDeleted)"
    #auditStage1>
</audit-form-1>
<audit-form-2
    [formData]="formData"
    *ngIf="showForm && targetStage === 2 && (!formData || !formData.isDeleted)"
    #auditStage1>
</audit-form-2>
<!---
    The read only view of the form should be always visible throughout the process
--->
<div class="{{ targetStage === 3 ? '' : 'pt-2' }}">&nbsp;</div>
<audit-form-3 [formData]="formData" *ngIf="showForm && !!formData && !isTemplate"></audit-form-3>

<div class="row" *ngIf="childForms">
    <div class="col-12">
        <h1 class="text-uppercase text-white bg-teal p-2 px-3 my-2">Child Forms</h1>
        <div class="pl-2">
            <child-forms-tree [childForms]="childForms"></child-forms-tree>
        </div>
    </div>
</div>

