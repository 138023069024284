import * as t from 'io-ts';
import { DateType } from './date';
import { PaginationResponse } from './pagination';
import { UserRole } from '../commontTypes';
import { IntFromString } from 'io-ts-types/lib/IntFromString';
import { PhoneValidationModel, SortOrderValues } from './common';

export const rolesList: UserRole[] = ['user', 'manager', 'administrator'];

export function constructUserOutputType<T>(dateType: t.Type<T>) {
  return t.type({
    id: t.number,
    username: t.string,
    firstName: t.string,
    lastName: t.string,
    phone: t.union([t.string, t.null]),
    role: t.string,
    createdAt: dateType,
    updatedAt: dateType,
    groups: t.union([t.array(t.type({
      id: t.number,
      groupName: t.string,
    })), t.undefined]),
    locations: t.union([t.array(t.type({
      id: t.number,
      name: t.string,
    })), t.undefined])
  });
}

export const UserWithDateOutputModel = constructUserOutputType<Date>(DateType);

export const UserWithDateOutputArray = t.array(UserWithDateOutputModel);

export interface IUserWithDateOutputModel extends t.TypeOf<typeof UserWithDateOutputModel> {}

export const UserOutputModel = constructUserOutputType<string>(t.string);

export interface IUserOutputModel extends t.TypeOf<typeof UserOutputModel> {}

export type UsersPaginationResponse = PaginationResponse<IUserOutputModel>;

export const UpdateUserInputModel = t.exact(t.type({
  id: t.number,
  username: t.string,
  firstName: t.string,
  lastName: t.string,
  // See comment in /contracts/common.ts for explanation for this ignore
  // @ts-ignore
  role: t.union(rolesList.map(r => t.literal(r))),
  phone: PhoneValidationModel,
  groups: t.array(t.number),
  locations: t.array(t.number),
  password: t.union([t.string, t.null]),
}));

export interface IUpdateUserInputModel extends t.TypeOf<typeof UpdateUserInputModel> {}

export const CreateUserInputModel = t.exact(t.type({
  username: t.string,
  firstName: t.string,
  lastName: t.string,
  role: t.refinement(t.string, r => rolesList.includes(r as UserRole), rolesList.map(role => `"${role}"`).join(', ')),
  phone: PhoneValidationModel,
  groups: t.array(t.number),
  locations: t.array(t.number),
  password: t.string,
}));

export interface ICreateUserInputModel extends t.TypeOf<typeof CreateUserInputModel> {}

// tslint:disable:max-line-length
/*
  This type of definition does not work with io-ts 1.5.3 and instead returns the following compile errors

  TODO: We need to resolve this so that we are not locked into io-ts 1.5.0 - 1.5.2

  src/common/contracts/common.ts:9:40 - error TS2345: Argument of type 'LiteralC<string>[]' is not assignable to parameter of type '[Mixed, Mixed, ...Mixed[]]'.
  Type 'LiteralC<string>[]' is missing the following properties from type '[Mixed, Mixed, ...Mixed[]]': 0, 1

  9 export const SortOrderValues = t.union(['asc', 'desc'].map(f => t.literal(f)));
                                           ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

  src/common/contracts/form.ts:131:41 - error TS2345: Argument of type 'LiteralC<string>[]' is not assignable to parameter of type '[Mixed, Mixed, ...Mixed[]]'.

  131 export const FormsSortedField = t.union(['dueAt', 'createdAt'].map(f => t.literal(f)));
                                              ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

  src/common/contracts/groups.ts:35:42 - error TS2345: Argument of type 'LiteralC<string>[]' is not assignable to parameter of type '[Mixed, Mixed, ...Mixed[]]'.

  35 export const GroupsSortedField = t.union(['createdAt', 'updatedAt', 'groupName'].map(f => t.literal(f)));
                                              ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

  src/common/contracts/users.ts:44:17 - error TS2345: Argument of type 'LiteralC<UserRole>[]' is not assignable to parameter of type '[Mixed, Mixed, ...Mixed[]]'.
    Type 'LiteralC<UserRole>[]' is missing the following properties from type '[Mixed, Mixed, ...Mixed[]]': 0, 1

  44   role: t.union(rolesList.map(r => t.literal(r))),
                     ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

  src/common/contracts/users.ts:64:41 - error TS2345: Argument of type 'LiteralC<string>[]' is not assignable to parameter of type '[Mixed, Mixed, ...Mixed[]]'.

  64 export const UsersSortedField = t.union(['name', 'createdAt', 'username', 'role'].map(f => t.literal(f)));
 */
// tslint:enable:max-line-length


// See comment in /contracts/common.ts for explanation for this ignore
// @ts-ignore
export const UsersSortedField = t.union(['name', 'createdAt', 'username', 'role'].map(f => t.literal(f)));

export type IUsersSortedField = t.TypeOf<typeof UsersSortedField>;

export const UsersQueryParams = t.type({
  skip: t.union([IntFromString, t.number]),
  limit: t.union([IntFromString, t.number]),
  sortBy: UsersSortedField,
  order: SortOrderValues,
  search: t.union([t.string, t.undefined]),
});

export interface IUsersQueryParams extends t.TypeOf<typeof UsersQueryParams> {}
