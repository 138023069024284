import {Component, Input, OnInit, ViewChild} from '@angular/core';
import { Location } from "@angular/common";
import * as moment from 'moment';
import "moment-timezone";
import {FormField, RecordParamType} from "../../../../../model/Form";
import {FormComponent} from "../../../../../model/FormComponent";
import {Session} from "../../../../../service/util/Session";
import {CurrentUserService} from "../../../../../service/currentUser/CurrentUserService";
import {FormService} from "../../../../../service/FormService";
import {FormCategory} from "../../../../../../model/Category";
import {CategoryService} from "../../../../../service/CategoryService";
import {FormRecordService} from "../../../../../service/FormRecordService";
import {
  IFormOutputModel,
  IFormRecordOutputModel
} from "../../../../../../common/contracts/form";
import {Observable} from "rxjs";
import { IButtonsSwitchOption } from "../../../../shared/buttonsSwitch.component";
import {GroupsService} from "../../../../../service/admin/GroupsService";
import { ErrorHandlerService } from "../../../../../service/ErrorHandlerService";
import { Router } from "@angular/router";
import {FollowUpWidgetComponent} from "../../../../shared/followUpWidget.component";
import {IDocumentType} from "../../../../../../common/contracts/document";
import { EnumService } from 'service/EnumService';
import { IEnumsOutputModel } from '../../../../../../common/contracts/enums';
import { IResourcePackOutputModel } from '../../../../../../common/contracts/resource-pack';
import { saveAs } from 'file-saver';
import { DocumentService } from 'service/DocumentService';
import { Document } from 'model/Document';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'audit-form-0',
  templateUrl: './auditFormStage0.component.html',
  styles: [`
      .hidden-input {
          display: none;
      }

      .download-document-reference {
          text-decoration: underline;
          cursor: pointer;
          font-size: 0.8em;
          line-height: 1.2;
          padding-bottom: 4px;
          display: block;
      }

      .remove-document-reference {
          cursor: pointer;
          font-size: 1em;
          margin-left: 8px;
      }

      .upload-title {
          margin-left: 10px;
      }

      button[disabled] {
          background-color: #ff9b3b;
          color: white;
      }
    
    .attachment-icon {
        font-size: 20px;
        cursor: pointer;
    }
    
    .light-grey-text {
        color: #AAAAAA;
    }

  `],
})
export class AuditFormStage0Component extends FormComponent implements OnInit {

  // Existing Form Data
  @Input() readOnly: boolean = false;
  @Input() hideHeader: boolean = false;
  @Input() formData: IFormOutputModel;
  @Input() sequence: number;
  @Input() isTemplate: boolean = false;

  public formRecord: IFormRecordOutputModel;
  
  @ViewChild('followUpWidget') followUpWidgetRef: FollowUpWidgetComponent;

  public documents: Array<IDocumentType> = [];
  public dateString: string;
  public formCategoryId: number;
  public resourcePacks: IResourcePackOutputModel[] = [];

  public auditFormTypes:IEnumsOutputModel[] = [];
  
  public facilityOptions: IButtonsSwitchOption[] = [
    {
      text: 'Facility Wide',
      value: 'facilitywide',
    },
    {
      text: 'Part of Facility',
      value: 'partoffacility'
    }
  ];

  public form:{ [key:string]:FormField<any> } = {
    location: new FormField<String>('', {
      validation: FormField.ValidationMethods.None,
      recordParamType: RecordParamType.Number
    }),
    templateLocation: new FormField<String>('', {
      validation: FormField.ValidationMethods.None,
      recordParamType: RecordParamType.String
    }),
    department: new FormField<String>('', {
      validation: FormField.ValidationMethods.IsNotBlank,
      recordParamType: RecordParamType.Number
    }),
    type: new FormField<String>('', {
      validation: FormField.ValidationMethods.IsNotBlank,
      recordParamType: RecordParamType.Enum
    }),
    periodFrom: new FormField<String>('', {
      validation: FormField.ValidationMethods.None,
      recordParamType: RecordParamType.Date
    }),
    periodTo: new FormField<String>('', {
      validation: FormField.ValidationMethods.None,
      recordParamType: RecordParamType.Date
    }),
    dueAt: new FormField<String>('', {
      validation: FormField.ValidationMethods.None,
      recordParamType: RecordParamType.NULL
    }),

    /** Template Only Properties */
    templateDueAtPeriod: new FormField<String>('', {
      validation: FormField.ValidationMethods.None,
      recordParamType: RecordParamType.String
    }),
    templateDueAtOffset: new FormField<String>('', {
      validation: FormField.ValidationMethods.None,
      recordParamType: RecordParamType.Number
    }),
    templatePeriodFromPeriod: new FormField<String>('', {
      validation: FormField.ValidationMethods.None,
      recordParamType: RecordParamType.String
    }),
    templatePeriodFromOffset: new FormField<String>('', {
      validation: FormField.ValidationMethods.None,
      recordParamType: RecordParamType.Number
    }),
    templatePeriodToPeriod: new FormField<String>('', {
      validation: FormField.ValidationMethods.None,
      recordParamType: RecordParamType.String
    }),
    templatePeriodToOffset: new FormField<String>('', {
      validation: FormField.ValidationMethods.None,
      recordParamType: RecordParamType.Number
    }),
  };

  constructor(
    public session: Session,
    public currentUserService: CurrentUserService,
    public formService: FormService,
    public categoryService: CategoryService,
    public formRecordService: FormRecordService,
    public groupsService: GroupsService,
    private errorHandler: ErrorHandlerService,
    private router: Router,
    location: Location,
    public enumService: EnumService,
    public documentService: DocumentService
  ) {
    super(location);
  }

  registerFormFields() {
    this.formFields.push( 
      ...Object.keys(this.form).map((k:string) => this.form[k])
    );
  }
  
  getFormLocationId():number | null {
    if( !this.isTemplate )
      return this.positiveValidInt(this.form.location);
    return null;
  }

  getUserGroupId():number | null {
    return this.positiveValidInt(this.form.department);
  }

  onSubmit(isDraft: boolean) {
    this.session.lockInput(  () => {
      
      return new Promise<void>( async(resolve, reject) => {

        let success = () => {
          resolve();
    
          this.router.navigate([ this.isTemplate ? '/admin/templates' : '/dashboard' ]);
        };
  
        let fail = (msg:string, err:any) => {
          console.error(msg, err);
          this.errorHandler.handleHttpError(err);
          reject();
        };

        let formLocationId = this.getFormLocationId();
        
        //UserGroupID will always be null because there is no department on the form
        let userGroupId: number | null = this.getUserGroupId();
        
        // This will be equal to the current stage. Drafts and Templates should not increment this value
        let stage:number = 0;

        // Drafts should always be assigned to the current user. 
        // Templates should always be assigned to no user.
        let assignedUserId: number | null = null;

        // If its a draft assign it to the current user
        if( !this.isTemplate ) {
          if( isDraft ) {
            assignedUserId = this.currentUserService.getCurrentUserIdOrFail();
          } else {
            // The audit form is now going to stage 1 for action
            stage = 1;
          }
        }

        const { dueAt, alertAt } = this.parseDueDate(this.form.dueAt.value, undefined, this.isTemplate);

        let formFunc:Observable<any>;

        const isNewForm = this.isNewForm(this.formData);

        let summary:string|undefined;

        if( this.form.type.value ) {
          const auditFormTypeEnum = this.auditFormTypes.find( typeEnum => typeEnum.id === Number(this.form.type.value) );

          if( auditFormTypeEnum )
            summary = auditFormTypeEnum.value;
        }

        if ( !isNewForm ) {
          formFunc = this.formService.updateForm({
            id: this.formData.id,
            formLocationId,
            userGroupId,
            stage,
            dueAt,
            alertAt,
            assignedUserId,
            summary,
            isTemplate: this.isTemplate
          });
        } else {
          formFunc = this.formService.createForm({
            categoryId: this.formCategoryId,
            formLocationId,
            userGroupId,
            dueAt,
            alertAt,
            notifyOnComplete : null,
            stage,
            parentFormId: null,
            assignedUserId,
            summary,
            isTemplate: this.isTemplate
          });
        }

        formFunc.subscribe((data:any) => {
          
          let formId:number = this.formData ? this.formData.id : data['id'];

          let properties = this.toRecordParams(this.form);

          this.formRecordService.createRecord({
            formId: formId,
            // Intentionally cast the properties object since we know its correct
            properties: properties as any,
            stage: 0,
            documents: this.documents.map(doc => ({id: doc.id, isTicked: !!doc.isTicked})),
            isComplete: !isDraft
          })
          .subscribe( 
            success, 
            err => fail('Error while creating a form', err)
          );
        },
        err => fail('Error while create/update form', err)
        );
      })
    });
  }

  ngOnInit() {

    this.dateString = moment().tz(environment.timeZone).format("DD-MM-YYYY");

    this.registerFormFields();

    this.loadEnums();

    this.repopulateFormFromData();
  }

  private repopulateFormFromData() {
    if( !this.formData || !this.formData.records || !this.formData.records.length )
      return;
  
    let stageRecords = this.formData.records.filter( record => record.stage === 0);
    if( stageRecords.length === 0 )
      return;
  
    if( !this.sequence ) {
      let mostRecentRecord = stageRecords.sort( (a, b) => a.sequence > b.sequence ? 1 : -1 ).pop();
    
      if (!mostRecentRecord)
        throw new Error("internal error");
    
      // If the most recent record was a submission, we are not going to use it
      if( mostRecentRecord.isComplete && !this.isTemplate ) {
        // It is likely the client will require that some fields are always loaded
        return;
      }
    
      this.formRecord = mostRecentRecord;
    } else {
      let targetRecord = stageRecords.find( record => record.sequence === this.sequence );
    
      if( !targetRecord )
        throw new Error("internal error");
    
      this.formRecord = targetRecord;
    }
  
    this.dateString = moment( this.formRecord.createdAt ).tz(environment.timeZone).format( this.dateFormat );

    this.updateFromRecordParams(this.form, this.formRecord);

    if( this.formData.dueAt ) {
      this.form.dueAt.value = moment( this.formData.dueAt ).tz(environment.timeZone).format('DD/MM/YYYY');
    }

    // get attached documents
    this.documents = this.initTickedDocuments(this.formRecord.documents, this.formRecord.tickedDocuments);
  }

  // Audit Stage 0 Functions
  private loadEnums() {
    this.session.lockInputRx(this.categoryService.getCategories())
      .subscribe( (data:FormCategory[]) => {

        let reportCategory:FormCategory | undefined = data.find( (data:FormCategory) => !!data.name.match(/^Audit/) );

        if( reportCategory )
          this.formCategoryId = reportCategory.id as number;
        //else
          //Notify the user an error has occurred
      },
      err => {
        console.error('Error while getting categories', err);
        this.errorHandler.handleHttpError(err);
      });

    this.session.lockInputRx(this.enumService.getEnumsByName('auditFormType'))
      .subscribe( (data:IEnumsOutputModel[]) => {
        // console.log("Updating Audit form types");
        // console.log(data);
        
        this.auditFormTypes = data;

        if( this.form.type.value ) {
          this.updateAuditType([{
            id: this.form.type.value,
            text: 'default'
          }]);
        } else {
          this.updateAuditType([]);
        }
      });
  }

  updateAuditType(options:IdTextPair[]) {

    // console.log("Updating Audit Type");
    // console.log(options);

    const validOptions = options.filter( option => !!option && option.id );

    // console.log("ValidOptions");
    // console.log(validOptions);

    // console.log("auditFormTypes");
    // console.log(this.auditFormTypes);

    const validAuditFormTypes:IEnumsOutputModel[] = validOptions
      .map( option => this.auditFormTypes.find( formType => formType.id === Number(option.id) ) )
      .filter( formType => !!formType ) as IEnumsOutputModel[]
    ;

    // console.log("ValidAuditFormTypes");
    // console.log(validAuditFormTypes);

    this.resourcePacks = validAuditFormTypes
      .map( formType => formType.resourcePack )
      .filter( formType => !!formType ) as IResourcePackOutputModel[]
    ;

    // console.log("ResourcePacks");
    // console.log(this.resourcePacks);
  }

  async download(doc: Document) {
    this.documentService.downloadDocument(doc.document.id).subscribe(
      data => {
        saveAs(data, doc.document.fileName);
      },
      error => this.errorHandler.handleHttpError(error)
    );
  }
}
