import { IDocumentOutputModel, IDocumentSortedField } from './../../../../../../common/contracts/documentDRM';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from "@angular/router";
import { FormGroup, FormControl } from "@angular/forms";
import { DocumentCollectionsService } from "../../../../../service/admin/DocumentCollectionsService";
import { DocumentsService } from "../../../../../service/admin/DocumentsService";
import { IDocumentCollectionOutputModel } from "../../../../../../common/contracts/documentCollection";
import { RxFormsService } from "../../../../../service/util/reactiveForms";
import { ModalService } from "../../../../../service/ModalService";
import { ErrorHandlerService } from "../../../../../service/ErrorHandlerService";
import { trimRequiredValidator } from "../../../../../validators/reactiveFormValidators";
import { ISortOrderValue } from '../../../../../../common/contracts/common';
import { PersistentStorageService } from 'service/PersistentStorageService';
import { CurrentUserService } from 'service/currentUser/CurrentUserService';

@Component({
	selector: 'app-documentCollections-component',
	templateUrl: './editDocumentCollection.component.html',
	styles: [`.sortable-col-name {
    cursor: pointer;
    & > i {
      display: none;
    }
    &.active {
      color: #007bff;
      & > i {
        display: inline-block;
        margin-left: 10px;
      }
      &.reverse {
        & > i {
          transform: rotate(180deg);
        }
      }
    }
  }`]
})
export class EditDocumentCollectionComponent implements OnInit {
	public className = "EditDocumentCollectionComponent";

	public documentCollection: IDocumentCollectionOutputModel;
	public isNew: boolean = false;
	private documentCollectionId: number | null = null;

	public isDocAdmin: boolean = this.userService.isAdminOrDocumentManager.getValue();

	public documentCollectionForm = new FormGroup({
		name: new FormControl({ value: '', disabled: !this.isDocAdmin }, [trimRequiredValidator]),
		reviewSchedule: new FormControl({ value: '', disabled: !this.isDocAdmin })
	});

	public documents: IDocumentOutputModel[] = [];
	public totalItems = 0;
	public currentPage = 1;
	public pageSize = this.pss.pageSize;
	public sortBy: IDocumentSortedField = 'name';
	public sortOrder: ISortOrderValue = 'asc';
	includeArchived: boolean = false;

	constructor(
		private documentCollectionService: DocumentCollectionsService,
		private documentsService: DocumentsService,
		private pss: PersistentStorageService,
		private router: Router,
		private route: ActivatedRoute,
		private activatedRoute: ActivatedRoute,
		private modalService: ModalService,
		private errorHandlerService: ErrorHandlerService,
		private userService: CurrentUserService,) {

	}

	ngOnInit() {
		this.activatedRoute.params.subscribe((params: Params) => {
			if (params.documentCollectionId) {
				if (/^\d+$/.test(params.documentCollectionId) !== true) {
					console.error('Invalid documentCollection router param');
					this.router.navigate(['/404'], { replaceUrl: true });
					return;
				}

				this.documentCollectionId = parseInt(params.documentCollectionId, 10);
				this.handlePageChange();
				this.documentCollectionService.getDocumentCollectionById(this.documentCollectionId).subscribe((documentCollection) => {
					this.documentCollection = documentCollection;

					this.documentCollectionForm.setValue({
						name: documentCollection.name,
						reviewSchedule: this.documentCollection.reviewSchedule || null
					});
				}, (err) => this.errorHandlerService.handleHttpError(err));
			} else {
				this.isNew = true;
			}
		});
	}

	public submit() {
		if (this.documentCollectionForm.invalid) {
			RxFormsService.touchAllFormControls(this.documentCollectionForm);
			return;
		}

		const observer = {
			next: () => this.goBack(),
			error: error => this.errorHandlerService.handleHttpError(error),
		};

		if (!this.documentCollectionId) {
			this.documentCollectionService.createDocumentCollection({
				name: this.documentCollectionForm.controls.name.value.trim(),
				reviewSchedule: this.documentCollectionForm.controls.reviewSchedule.value,
			}).subscribe(observer);

		} else {
			this.documentCollectionService.updateDocumentCollection({
				id: this.documentCollectionId,
				name: this.documentCollectionForm.controls.name.value.trim(),
				reviewSchedule: this.documentCollectionForm.controls.reviewSchedule.value,
			}).subscribe(observer);
		}
	}

	public archiveDocumentCollection() {
		this.modalService.confirmRx({
			title: 'Warning',
			message: 'Are you sure that you want to delete this document collection?'
		}).subscribe((isConfirmed) => {
			if (isConfirmed && this.documentCollectionId) {
				this.documentCollectionService.archiveDocumentCollection(this.documentCollectionId).subscribe(
					() => this.goBack(),
					(err) => this.errorHandlerService.handleHttpError(err)
				);
			}
		});
	}

	private goBack() {
		this.router.navigate(['../'], { relativeTo: this.route });
	}
	public handleDocumentCollectionClick(groupId: number | string) {
		this.router.navigate([`${groupId}`], { relativeTo: this.route });
	}

	public handlePageChange() {
		this.documentsService.getPagedDocuments(
			this.documentCollectionId,
			this.includeArchived ? 1 : 0,
			this.pageSize * (this.currentPage - 1),
			this.pageSize,
			this.sortBy,
			this.sortOrder,
		).subscribe((data) => {
			this.documents = data.items;
			this.totalItems = data.totalCount;
		}, (err) => this.errorHandlerService.handleHttpError(err));
	}

	public handleSortChange(sortField: IDocumentSortedField) {
		if (sortField === this.sortBy) {
			this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
		} else {
			this.sortBy = sortField;
			this.sortOrder = 'asc';
		}

		this.handlePageChange();
	}
	public handleDocumentClick(documentId: number | string) {
		this.router.navigate([`documents/${documentId}`], { relativeTo: this.route });
	}
	public handleIncludeArchivedToggle() {
		this.includeArchived = !this.includeArchived
		this.handlePageChange();
	}
}
