import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { logger } from 'service/util/Logger';
import { PrevPageRequest, Session } from 'service/util/Session';

declare const APP_VERSION: string;

@Component({
	selector: 'app-main',
	templateUrl: './main.component.html',
	styleUrls: ['./main.component.css'],
})
export class MainComponent implements OnInit {

	private routeHistory: string[] = [];
	private routeHistoryMaxLength: number = 50;
	private className:string = "MainComponent";
	public appVersion: string = "UNKNOWN";

	constructor(
		private router: Router,
		private session: Session
	) { }

	ngOnInit() {
		this.appVersion = APP_VERSION || "UNKNOWN";
		this.trackPages();
	}

	private trackPages() {
		const signature = this.className + ".trackPages: ";

		this.router.events
			.pipe(
				filter(event => event instanceof NavigationStart),
				map(event => event as NavigationStart)
			).subscribe(event => {
				this.routeHistory.push(event.url);

				if (this.routeHistory.length > this.routeHistoryMaxLength) {
					this.routeHistory.splice(0, 1);
				}
			});

		this.session.requestPrevPage.subscribe((prev: PrevPageRequest | undefined) => {
			logger.debug(signature + `Handling History Request[${JSON.stringify(prev)}]`);
			logger.silly(this.routeHistory);
			return this.seekHistory(
				prev && prev.defaultUrl, 
				prev && prev.filter
			);
		});
	}

	/**
	 * @description Recursively takes 1 step backwards through the route history and navigates to it if the filter matches the item (or no filter exists).
	 * @param {string|undefined} defaultUrl Optional URL to navigate to if no other nav target is found
	 * @param {string|undefined|RegExp} filter Matcher for routes
	 * @param {number} seekIdx Accumulator, starting at 1 as the intent will not be to navigate to self
	 * @returns {Promise<boolean>} The result of the navigation attempt that this method necesesarily results in
	 */
	private seekHistory(defaultUrl: string | undefined, filter: string | undefined | RegExp, seekIdx: number = 1):Promise<boolean> {
		const signature = this.className + ".seekHistory: ";
		const seekOffset = seekIdx + 1;

		if (this.routeHistory.length > seekOffset) {
			const prevUrl = this.routeHistory[this.routeHistory.length - seekOffset] as string;

			if (filter) {
				const filterRegex: RegExp = filter instanceof RegExp ? filter : new RegExp(filter);

				if (!prevUrl.match(filterRegex)) {
					logger.debug(signature + `PrevUrl[${prevUrl}] did not Match[${String(filterRegex)}]`)
					return this.seekHistory(defaultUrl, filterRegex, seekOffset);
				}
			}

			logger.info(signature + `Navigating to Url[${prevUrl}]`);
			return this.router.navigateByUrl(prevUrl);
		}

		if (defaultUrl) {
			logger.info(signature + `Navigating Default Url[${defaultUrl}]`);
			return this.router.navigateByUrl(defaultUrl);
		}

		logger.info(signature + 'Navigating Blank Url[]');
		return this.router.navigateByUrl('');
	}
}
