import {Component, Input, OnInit, ViewChild} from '@angular/core';
import { Location } from "@angular/common";
import * as moment from 'moment';
import "moment-timezone";
import {FormField, RecordParamType} from "../../../../../model/Form";
import {FormComponent} from "../../../../../model/FormComponent";
import {Session} from "../../../../../service/util/Session";
import {CurrentUserService} from "../../../../../service/currentUser/CurrentUserService";
import {FormService} from "../../../../../service/FormService";
import {FormCategory} from "../../../../../../model/Category";
import {CategoryService} from "../../../../../service/CategoryService";
import {FormRecordService} from "../../../../../service/FormRecordService";
import {
  IFormOutputModel,
  IFormRecordOutputModel
} from "../../../../../../common/contracts/form";
import { IButtonsSwitchOption } from "../../../../shared/buttonsSwitch.component";
import {GroupsService} from "../../../../../service/admin/GroupsService";
import { ErrorHandlerService } from "../../../../../service/ErrorHandlerService";
import { Router } from "@angular/router";
import {FollowUpWidgetComponent} from "../../../../shared/followUpWidget.component";
import {IDocumentType} from "../../../../../../common/contracts/document";
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'audit-form-1',
  templateUrl: './auditFormStage1.component.html',
})
export class AuditFormStage1Component extends FormComponent implements OnInit {

  protected thisStage:number = 1;

  // Existing Form Data
  @Input() readOnly: boolean = false;
  @Input() hideHeader: boolean = false;

  @Input() formData: IFormOutputModel;
  @Input() sequence: number;

  public formRecord: IFormRecordOutputModel;
  
  @ViewChild('followUpWidget') followUpWidgetRef: FollowUpWidgetComponent;

  public dateString: string;
  public formCategoryId: number;
  
  public facilityOptions: IButtonsSwitchOption[] = [
    {
      text: 'Facility Wide',
      value: 'facilitywide',
    },
    {
      text: 'Part of Facility',
      value: 'partoffacility'
    }
  ];

  public form:{ [key:string]:FormField<any> } = {
    facilityOption: new FormField<String>(this.facilityOptions[0].value, {
      recordParamType: RecordParamType.String
    }),
    facilityDetail: new FormField<String>('', {
      validation: FormField.ValidationMethods.None,
      recordParamType: RecordParamType.String
    }),
    summary: new FormField<String>('', {
      validation: FormField.ValidationMethods.IsNotBlank,
      recordParamType: RecordParamType.String
    }),
    actionSummary: new FormField<String>('', {
      validation: FormField.ValidationMethods.IsNotBlank,
      recordParamType: RecordParamType.String
    }),
    anyActions: new FormField<boolean>(true, {
      validation: FormField.ValidationMethods.None,
      recordParamType: RecordParamType.Boolean
    }),
    actionsResolved: new FormField<boolean>(true, {
      validation: FormField.ValidationMethods.None,
      recordParamType: RecordParamType.Boolean
    }),
    followUps: new FormField<string>('[]', {
      nullEquivilent : "[]",
      validation: (value:string) => {
        return this.followUpWidgetRef ? this.followUpWidgetRef.validate() : true;
      },
      recordParamType: RecordParamType.JSON
    }),
    reassign: new FormField<boolean>(false, {
      validation: FormField.ValidationMethods.None,
      recordParamType: RecordParamType.Boolean
    }),
    reassignToUserId: new FormField<string>('', {
      validation: FormField.ValidationMethods.None,
      recordParamType: RecordParamType.Number
    })
  };

  public documents: Array<IDocumentType> = [];

  constructor(
    public session: Session,
    public currentUserService: CurrentUserService,
    public formService: FormService,
    public categoryService: CategoryService,
    public formRecordService: FormRecordService,
    public groupsService: GroupsService,
    private errorHandler: ErrorHandlerService,
    private router: Router,
    location: Location,
  ) {
    super(location);
  }

  registerFormFields() {
    this.formFields.push( ...Object.keys(this.form).map((k:string) => this.form[k]) );
  }

  onSubmit(isDraft: boolean) {
    this.session.lockInput(  () => {
      
      return new Promise( async(resolve, reject) => {

        let success = () => {
          resolve();
    
          this.router.navigate(['/dashboard']);
        };
  
        let fail = (msg:string, err:any) => {
          console.error(msg, err);
          this.errorHandler.handleHttpError(err);
          reject();
        };
        
        // This will be equal to the current stage. Drafts should not increment this value
        let stage:number = this.thisStage;

        let assignedUserId: number | null = this.formData.assignedUserId;
        let userGroupId: number | null = this.formData.userGroupId;

        // If its a draft assign it to the current user
        const currentUserId = this.currentUserService.getCurrentUserIdOrFail();
        if( isDraft ) {
          assignedUserId = currentUserId;
        } else {
          // The audit form is now going to stage 1 for action
          stage = this.thisStage+1;

          if( this.form.reassign.value && this.form.reassignToUserId.value) {
            stage = this.thisStage;

            assignedUserId = Number(this.form.reassignToUserId.value);
          } else {
            let lastSubmission = this.getMostRecentSubmission(this.formData);

            /*
              If:
               There is a last submission
               AND that last submission is a stage 1 submission
               AND that last submission was made by another user
               AND that last submission assigned the form to the current user
            */
            let assignBack:boolean = false;

            if( lastSubmission && lastSubmission.stage === this.thisStage
              && lastSubmission.createdById !== assignedUserId
              && lastSubmission.createdById !== currentUserId
            ) {
              let reassignProperty = lastSubmission.properties.find( property => property.property.name === 'reassignToUserId');

              if( reassignProperty && reassignProperty.intData === assignedUserId )
                assignBack = true;
            }

            if( lastSubmission && assignBack ) {
              //Assign the submission back to that user, leaving the form in stage 1
              stage = this.thisStage;

              assignedUserId = lastSubmission.createdById;
            } else {
              stage = this.thisStage+1;

              // Assign it to the admin group
              assignedUserId = null;

              let groups = await this.groupsService.getGroups().toPromise();
              let adminGroup = groups.find(group => group.groupName.toLowerCase() === environment.adminGroupName.toLowerCase());

              if (adminGroup)
                userGroupId = adminGroup.id;
              else
                userGroupId = null;
            }
          }
        }
        
        const formFunc = this.formService.updateForm({
          id: this.formData.id,
          stage,
          userGroupId,
          assignedUserId
        });

        formFunc.subscribe((data:any) => {
          
          let formId:number = this.formData.id || data['id'];

          let properties = this.toRecordParams(this.form);

          this.formRecordService.createRecord({
            formId: formId,
            // Intentionally cast the properties object since we know its correct
            properties: properties as any,
            stage: this.thisStage,
            documents: this.documents.map(doc => ({id: doc.id, isTicked: !!doc.isTicked})),
            isComplete: !isDraft
          })
          .subscribe(
          success,
          err => fail('Error while creating a record', err)
          );
        },
        err => fail('Error while create/update form', err)
        );
      })
    });
  }

  ngOnInit() {

    this.dateString = moment().tz(environment.timeZone).format("DD-MM-YYYY");

    this.registerFormFields();

    this.loadEnums();

    this.repopulateFormFromData();
  }

  private repopulateFormFromData() {
    if( !this.formData || !this.formData.records || !this.formData.records.length )
      return;
  
    let stageRecords = this.formData.records.filter( record => record.stage === this.thisStage);
  
    if( !this.sequence ) {
      /**
       * Get the most recent submission (including drafts)
       * 
       * if the most recent submission is completed
       *  get the follow ups from the competed submission
       *  return
       * 
       * If the most recent submission is a draft
       *  get the most recent completed submission
       *    update the follow ups from the completed submission
       *  if the most recent submission is for a different stage
       *    return
       *  use the most recent submission as the target record
       *  end
       */
      const lastSubmission = this.getMostRecentSubmission(this.formData, true);

      if( !lastSubmission )
        return;

      if( lastSubmission.isComplete ) {
        const followUps = this.getJsonData(lastSubmission, "followUps");

        if( followUps ) {
          this.form.followUps.value = followUps;
          this.form.actionsResolved.value = false;
        }

        return;
      }
      
      const lastCompletedSubmission = this.getMostRecentSubmission(this.formData);

      if( lastCompletedSubmission ) {
        const followUps = this.getJsonData(lastCompletedSubmission, "followUps");

        if( followUps ) {
          this.form.followUps.value = followUps;
          this.form.actionsResolved.value = false;
        }
      }

      if( lastSubmission.stage !== this.thisStage )
        return;
    
      this.formRecord = lastSubmission;
    } else {
      let targetRecord = stageRecords.find( record => record.sequence === this.sequence );
    
      if( !targetRecord )
        throw new Error("Invalid Record Sequence");
    
      this.formRecord = targetRecord;
    }
  
    this.dateString = moment( this.formRecord.createdAt ).tz(environment.timeZone).format( this.dateFormat );

    this.updateFromRecordParams(this.form, this.formRecord);

    // get attached documents
    this.documents = this.initTickedDocuments(this.formRecord.documents, this.formRecord.tickedDocuments);

    this.showReassignOptions();
    this.updateActionOptions();
  }

  // Audit Stage 0 Functions
  private loadEnums() {
    this.session.lockInputRx(this.categoryService.getCategories())
      .subscribe( (data:FormCategory[]) => {

        let reportCategory:FormCategory | undefined = data.find( (data:FormCategory) => !!data.name.match(/^Audit/) );

        if( reportCategory )
          this.formCategoryId = reportCategory.id as number;
        //else
          //Notify the user an error has occurred
      },
      err => {
        console.error('Error while getting categories', err);
        this.errorHandler.handleHttpError(err);
      });
  }

  public updateActionOptions(state?:boolean):void {
    if( state !== undefined && state !== null)
      this.form.anyActions.value = state;
    else
      state = this.form.anyActions.value;

      if (state) {
        this.setFieldValidation(this.form.actionSummary, FormField.ValidationMethods.IsNotBlank);
      } else {
        this.setFieldValidation(this.form.actionSummary, FormField.ValidationMethods.None);
      }
  }
  
  public showReassignOptions(state?: boolean|null): void {
    if( state !== undefined && state !== null)
      this.form.reassign.value = state;
    else
      state = this.form.reassign.value;
    if (state) {
      this.setFieldValidation(this.form.reassignToUserId, FormField.ValidationMethods.IsNotBlank);
      this.setFieldValidation(this.form.summary, FormField.ValidationMethods.None);
      this.setFieldValidation(this.form.actionSummary, FormField.ValidationMethods.None);
    } else {
      this.setFieldValidation(this.form.reassignToUserId, FormField.ValidationMethods.None);
      this.setFieldValidation(this.form.summary, FormField.ValidationMethods.IsNotBlank);
      this.setFieldValidation(this.form.actionSummary, FormField.ValidationMethods.IsNotBlank);
    }
  }
}
