var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from '@angular/core';
import { Location } from "@angular/common";
import * as moment from 'moment';
import "moment-timezone";
import { FormComponent } from "../../../../../model/FormComponent";
import { FormField } from "../../../../../model/Form";
import { Session } from "../../../../../service/util/Session";
import { CurrentUserService } from "../../../../../service/currentUser/CurrentUserService";
import { FormRecordService } from "../../../../../service/FormRecordService";
import { FormService } from "../../../../../service/FormService";
import { CategoryService } from "../../../../../service/CategoryService";
import { GroupsService } from "../../../../../service/admin/GroupsService";
import { ErrorHandlerService } from "../../../../../service/ErrorHandlerService";
import { FollowUpWidgetComponent } from "../../../../shared/followUpWidget.component";
import { DocumentIntanceService } from 'service/admin/DocumentInstanceService';
import { saveAs } from "file-saver";
import { DocumentService } from 'service/DocumentService';
import { DocumentsService } from 'service/admin/DocumentsService';
import { environment } from '../../../../../environments/environment';
var DocumentReviewFormStage3Component = /** @class */ (function (_super) {
    __extends(DocumentReviewFormStage3Component, _super);
    function DocumentReviewFormStage3Component(session, currentUserService, formRecordService, formService, categoryService, groupsService, errorHandler, location, documentInstanceService, documentService, documentDRMService) {
        var _this = _super.call(this, location) || this;
        _this.session = session;
        _this.currentUserService = currentUserService;
        _this.formRecordService = formRecordService;
        _this.formService = formService;
        _this.categoryService = categoryService;
        _this.groupsService = groupsService;
        _this.errorHandler = errorHandler;
        _this.documentInstanceService = documentInstanceService;
        _this.documentService = documentService;
        _this.documentDRMService = documentDRMService;
        _this.readOnly = false;
        _this.hideHeader = false;
        /*
          This should have been done properly using something that implements FormControl but its
          too late now
         */
        _this.form = {
            reassign: new FormField(false, {
                validation: FormField.ValidationMethods.None
            }),
            //Since this will be assigned to a select it must be a string data - Conversion where appropriate
            reassignToUserId: new FormField('', {
                validation: FormField.ValidationMethods.None
            }),
            followUps: new FormField('[]', {
                nullEquivilent: "[]",
                validation: function (value) {
                    return _this.followUpWidgetRef ? _this.followUpWidgetRef.validate() : true;
                }
            }),
            note: new FormField('', {
                validation: FormField.ValidationMethods.None
            }),
            additionalChangesRequired: new FormField(false, {
                validation: FormField.ValidationMethods.None
            }),
            additionalChanges: new FormField('', {
                validation: FormField.ValidationMethods.None
            }),
            keepOriginalDocument: new FormField(true, {
                validation: FormField.ValidationMethods.None
            }),
        };
        _this.documents = [];
        _this.uploadedDocument = [];
        _this.hasDraftFromCompletedTasks = false;
        return _this;
    }
    DocumentReviewFormStage3Component.prototype.registerFormFields = function () {
        var _this = this;
        var _a;
        (_a = this.formFields).push.apply(_a, Object.keys(this.form).map(function (k) { return _this.form[k]; }));
    };
    DocumentReviewFormStage3Component.prototype.ngOnInit = function () {
        var _this = this;
        this.dateString = moment().tz(environment.timeZone).format(this.dateFormat);
        this.registerFormFields();
        this.repopulateFormFromData();
        // Load the category name
        this.categoryService.getCategoryIdByName('Follow-Up').subscribe(function (categoryId) {
            return _this.followUpFormCategory = categoryId;
        });
        this.getLatestAprovedDocument();
        this.getLatestDraft();
        this.populateUploadedDocumentsInCurrentStage(this.readOnly);
    };
    DocumentReviewFormStage3Component.prototype.populateUploadedDocumentsInCurrentStage = function (readOnly) {
        if (!this.formData) {
            return;
        }
        if (!readOnly) {
            var completeRecords_1 = this.formData.records.filter(function (record) { return (record.stage === 3 && record.documents.length > 0); });
            if (completeRecords_1.length === 0) {
                return;
            }
            var documents_1 = completeRecords_1[completeRecords_1.length - 1].documents;
            var uploadedDocument_1 = documents_1[documents_1.length - 1];
            this.uploadedDocument.push(uploadedDocument_1);
            return;
        }
        var completeRecords = this.formData.records.filter(function (record) { return (record.stage === 3 && record.isComplete && record.documents.length > 0); });
        if (completeRecords.length === 0) {
            return;
        }
        var documents = completeRecords[0].documents;
        var uploadedDocument = documents[documents.length - 1];
        this.uploadedDocument.push(uploadedDocument);
    };
    DocumentReviewFormStage3Component.prototype.onSubmit = function (isDraft) {
        var _this = this;
        this.session.lockInput(function () {
            return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
                var success, fail, stage, assignedUserId, userGroupId, groups, adminGroup, properties;
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            success = function () {
                                resolve();
                                _this.goBack();
                            };
                            fail = function (msg, err) {
                                console.error(msg, err);
                                _this.errorHandler.handleHttpError(err);
                                reject();
                            };
                            stage = isDraft ? 3 : 4;
                            assignedUserId = null;
                            userGroupId = this.formData.userGroupId;
                            if (this.currentUserService.userData)
                                assignedUserId = this.currentUserService.userData.id;
                            if (!
                            // If the form is ready to advance
                            (stage === 4)) 
                            // If the form is ready to advance
                            return [3 /*break*/, 4];
                            if (!(this.form.reassign.value && this.form.reassignToUserId.value)) return [3 /*break*/, 1];
                            // We actually want to send it back 1 for more detail
                            stage = 1;
                            assignedUserId = Number(this.form.reassignToUserId.value);
                            return [3 /*break*/, 4];
                        case 1:
                            if (!(this.formData.notifyOnComplete && this.formData.notifyOnComplete.length > 0)) return [3 /*break*/, 2];
                            // We are done. The server should fire off an email when we mark this complete
                            stage = 4;
                            assignedUserId = null;
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, this.groupsService.getGroups().toPromise()];
                        case 3:
                            groups = _a.sent();
                            adminGroup = groups.find(function (group) { return group.groupName.match(/^Update documents/gi) !== null; });
                            if (adminGroup)
                                userGroupId = adminGroup.id;
                            assignedUserId = null;
                            _a.label = 4;
                        case 4:
                            properties = [];
                            this.formService.updateForm({
                                id: this.formData.id,
                                stage: stage,
                                userGroupId: userGroupId,
                                assignedUserId: assignedUserId,
                            })
                                .subscribe(function () {
                                properties.push({
                                    name: "reassign",
                                    intData: _this.form.reassign.value ? 1 : 0
                                });
                                if (_this.form.reassign.value) {
                                    // TODO: This fields validation shoudl change from int > 0 to NONE when reassign is true/false
                                    properties.push({
                                        name: "reassignToUserId",
                                        intData: Number(_this.form.reassignToUserId.value)
                                    });
                                }
                                properties.push({
                                    name: "followUps",
                                    jsonData: _this.form.followUps.value
                                });
                                if (_this.form.note.value.length > 0) {
                                    properties.push({
                                        name: "note",
                                        stringData: _this.form.note.value
                                    });
                                }
                                if (_this.form.additionalChangesRequired.value && _this.form.additionalChanges.value.length > 0) {
                                    properties.push({
                                        name: "additionalChangesRequired",
                                        intData: _this.form.additionalChangesRequired.value ? 1 : 0
                                    });
                                    properties.push({
                                        name: "additionalChanges",
                                        stringData: _this.form.additionalChanges.value
                                    });
                                }
                                properties.push({
                                    name: "keepOriginalDocument",
                                    intData: _this.form.keepOriginalDocument.value ? 0 : 1
                                });
                                // Generate the follows
                                var followUps = [];
                                if (_this.form.followUps.value.length) {
                                    followUps = JSON.parse(_this.form.followUps.value);
                                }
                                if (_this.uploadedDocument.length === 0) {
                                    _this.uploadedDocument.push(_this.documents[_this.documents.length - 1]);
                                }
                                _this.formRecordService.createRecord({
                                    formId: _this.formData.id,
                                    // Intentionally cast the properties object since we know its correct
                                    properties: properties,
                                    stage: 3,
                                    documents: _this.uploadedDocument.map(function (doc) { return ({ id: doc.id, isTicked: !!doc.isTicked }); }),
                                    isComplete: !isDraft
                                })
                                    .subscribe(function (data) {
                                    //Done creating the form and appending its properties
                                    if (isDraft || followUps.length === 0) {
                                        if (stage === 4) {
                                            _this.uploadDocumentInstance(assignedUserId);
                                            _this.sendReviewEmail(isDraft, userGroupId);
                                            resolve();
                                            _this.goBack();
                                        }
                                        else {
                                            success();
                                        }
                                    }
                                    else if (_this.form.reassign.value && _this.form.reassign.value) {
                                        success();
                                    }
                                    else {
                                        if (stage === 4) {
                                            _this.uploadDocumentInstance(assignedUserId);
                                            _this.sendReviewEmail(isDraft, userGroupId);
                                            success();
                                        }
                                        else {
                                            success();
                                        }
                                    }
                                }, function (err) { return fail('Error creating a record', err); });
                            }, function (err) { return fail('Error updating a form', err); });
                            return [2 /*return*/];
                    }
                });
            }); });
        });
    };
    DocumentReviewFormStage3Component.prototype.repopulateFormFromData = function () {
        var _this = this;
        if (!this.formData || !this.formData.records || !this.formData.records.length)
            return;
        var stageRecords = this.formData.records.filter(function (record) { return record.stage === 3; });
        var previousRecord = this.formData.records.slice().sort(function (a, b) { return a.sequence > b.sequence ? 1 : -1; }).pop();
        /*
          If there are no pre-existing stage records, OR if the previous record is not a stage record,
          AND a sequence has not been specified (IE, not submitted), load followups from that previous record
        */
        if (!this.sequence && (stageRecords.length === 0 || (previousRecord && previousRecord.stage !== 3))) {
            /*
                At this point, we want to acknowledge the possibility that the
                previous stage specified followups that need to be completed
             */
            stageRecords = this.formData.records.filter(function (record) { return record.stage === 2; });
            var mostRecentRecord = stageRecords.sort(function (a, b) { return a.sequence > b.sequence ? 1 : -1; }).pop();
            if (!mostRecentRecord)
                return;
            var followUpRecord = mostRecentRecord.properties.find(function (recordProperty) { return recordProperty.property.name === 'followUps'; });
            if (followUpRecord) {
                this.form.followUps.value = followUpRecord.jsonData;
            }
            /**
             * aggregate documents and ticked documents from all previous complete records
             */
            var completeRecords = this.formData.records.filter(function (record) { return record.isComplete; });
            var allDocuments = [];
            var allTickedDocuments = [];
            for (var _i = 0, completeRecords_2 = completeRecords; _i < completeRecords_2.length; _i++) {
                var record = completeRecords_2[_i];
                if (record.documents && record.documents.length) {
                    allDocuments = allDocuments.concat(record.documents.map(function (doc) { return (__assign({}, doc)); })); // copy all documents object
                    if (record.tickedDocuments && record.tickedDocuments.length) {
                        allTickedDocuments = allTickedDocuments.concat(record.tickedDocuments);
                    }
                }
            }
            this.documents = this.initTickedDocuments(allDocuments, allTickedDocuments);
            return;
        }
        if (!this.sequence) {
            var mostRecentRecord = stageRecords.sort(function (a, b) { return a.sequence > b.sequence ? 1 : -1; }).pop();
            if (!mostRecentRecord)
                throw new Error("internal error");
            // If the most recent record was a submission, we are not going to use it
            if (mostRecentRecord.isComplete)
                return;
            this.formRecord = mostRecentRecord;
        }
        else {
            var targetRecord = stageRecords.find(function (record) { return record.sequence === _this.sequence; });
            if (!targetRecord)
                throw new Error("internal error");
            this.formRecord = targetRecord;
        }
        this.dateString = moment(this.formRecord.createdAt).tz(environment.timeZone).format(this.dateFormat);
        //Convert the properties into easily accessible IFormRecordPropertyParam
        if (!this.formRecord.properties)
            return;
        this.documents = this.initTickedDocuments(this.formRecord.documents, this.formRecord.tickedDocuments);
        var simpleProperties = {};
        this.formRecord.properties.forEach(function (recordProperty) {
            //eject invalid property
            if (!recordProperty.property)
                return;
            var result = {
                name: recordProperty.property.name
            };
            if (recordProperty.stringData)
                result.stringData = recordProperty.stringData;
            if (recordProperty.intData)
                result.intData = recordProperty.intData;
            if (recordProperty.jsonData)
                result.jsonData = recordProperty.jsonData;
            if (recordProperty.enumId)
                result.enumId = recordProperty.enumId;
            simpleProperties[result.name] = result;
        });
        /*
          If the last record for this stage is not the most recent record for this stage
          then it was passed back before coming forward again, and the reassignment
          should ALWAYS be 0
         */
        var allowReassignPrepopulate = true;
        /**
         *  FIX: added .slice() that copies an array to prevent mutation of original records
         **/
        var highestOrderRecord = this.formData.records.slice().sort(function (a, b) { return a.sequence > b.sequence ? 1 : -1; }).pop();
        if (!this.sequence && highestOrderRecord && highestOrderRecord.sequence > this.formRecord.sequence)
            allowReassignPrepopulate = false;
        if (simpleProperties['reassign']
            && simpleProperties['reassign'].intData !== null
            && simpleProperties['reassign'].intData !== undefined
            && allowReassignPrepopulate)
            this.form.reassign.value = (simpleProperties['reassign'].intData > 0);
        if (simpleProperties['reassignToUserId'] && allowReassignPrepopulate)
            this.form.reassignToUserId.value = String(simpleProperties['reassignToUserId'].intData);
        if (simpleProperties['followUps'])
            this.form.followUps.value = simpleProperties['followUps'].jsonData;
        if (simpleProperties['note'])
            this.form.note.value = simpleProperties['note'].stringData;
        if (simpleProperties['additionalChangesRequired'] && simpleProperties['additionalChanges']) {
            this.form.additionalChangesRequired.value = String(simpleProperties['additionalChangesRequired'].intData);
            this.form.additionalChanges.value = simpleProperties['additionalChanges'].stringData;
        }
        if (simpleProperties['keepOriginalDocument']) {
            this.form.keepOriginalDocument.value = simpleProperties['keepOriginalDocument'].stringData;
        }
    };
    DocumentReviewFormStage3Component.prototype.getLatestDraft = function () {
        var _this = this;
        this.formService.getFormsFromDocumentId(this.documentId).subscribe(function (data) {
            if (data.length === 0) {
                _this.documentInstanceService.getDraftDocument(_this.documentId).subscribe(function (data) {
                    if (data) {
                        _this.latestDraftRecord = data;
                        _this.hasDraftFromCompletedTasks = true;
                    }
                    else {
                        _this.latestDraftRecord = null;
                    }
                });
            }
            else {
                var formsCreatedAtArray = data.map(function (form) { return new Date(form.createdAt); }), latestFormCreatedAt_1 = new Date(Math.max.apply(null, formsCreatedAtArray)), latestFormRecords_1 = data.find(function (form) { return (new Date(form.createdAt).getTime() === latestFormCreatedAt_1.getTime()); }).records, latestRecord = latestFormRecords_1.find(function (record) { return new Date(record.createdAt).getTime() === new Date(Math.max.apply(null, latestFormRecords_1.filter(function (record) { return !!record.documents.length; }).map(function (record) { return new Date(record.createdAt); }))).getTime(); });
                _this.latestDraftRecord = latestRecord;
            }
        });
    };
    DocumentReviewFormStage3Component.prototype.downloadLatestDraft = function () {
        var _this = this;
        if (this.latestDraftRecord === null) {
            this.downloadLatestAprovedVersion();
        }
        else if (this.hasDraftFromCompletedTasks && this.latestDraftRecord) {
            this.documentInstanceService.downloadDraftDocument(this.latestDraftRecord.id).subscribe(function (data) {
                saveAs(data, _this.latestDraftRecord.originalFileName);
            });
        }
        else {
            this.documentService.downloadDocument(this.latestDraftRecord.documents[0].id).subscribe(function (file) {
                saveAs(file, _this.latestDraftRecord.documents[0].fileName);
            });
        }
    };
    DocumentReviewFormStage3Component.prototype.getLatestAprovedDocument = function () {
        var _this = this;
        if (this.formData) {
            var formPropertiesArray = this.formData.records.map(function (record) { return record.properties; }), flatenedFormPropertiesArray = [].concat.apply([], formPropertiesArray), propertyWithDocumentId = flatenedFormPropertiesArray.find(function (property) { return property.property.name === "documentId"; });
            this.documentId = propertyWithDocumentId.stringData;
        }
        this.documentDRMService.getDocumentInstanceByDocumentId(this.documentId).subscribe(function (file) {
            // saveAs(file,`document-${this.documentId}-latest-aproved`);
            _this.latestAprovedVersion = file;
        });
    };
    DocumentReviewFormStage3Component.prototype.downloadLatestAprovedVersion = function () {
        var _this = this;
        this.documentInstanceService.downloadDocument(this.latestAprovedVersion.id).subscribe(function (data) {
            saveAs(data, _this.latestAprovedVersion.fileName);
        });
    };
    DocumentReviewFormStage3Component.prototype.uploadDocumentInstance = function (assignedUserId) {
        var formPropertyArray = this.formData.records.map(function (x) { return x.properties; }), flattenedFormPropertyArray = [].concat.apply([], formPropertyArray), documentId = Number((flattenedFormPropertyArray.find(function (x) { return x.property.name === "documentId"; })).stringData), documentInstanceParams = {
            // assuming only one file is associated with one form
            documentFileId: this.uploadedDocument.length > 0 ? this.uploadedDocument[0].id : null,
            documentFileName: this.uploadedDocument.length > 0 ? this.uploadedDocument[0].fileName : null,
            documentId: documentId,
            formId: this.formData.id,
            createdAt: this.formData.createdAt,
            aprovedBy: assignedUserId,
            convertToPdf: (this.form.keepOriginalDocument.value)
        };
        this.documentInstanceService.upload(documentInstanceParams).subscribe();
    };
    DocumentReviewFormStage3Component.prototype.sendReviewEmail = function (isDraft, userGroupId) {
        if (!isDraft) {
            var params = {
                userGroupId: userGroupId,
                stage: 4,
                createdUserId: this.formData.createdById,
                documentId: this.documentId,
                completedBy: this.currentUserService.userData ? this.currentUserService.userData.id : null
            };
            this.formService.sendReviewFormNotifications(params).subscribe();
        }
    };
    return DocumentReviewFormStage3Component;
}(FormComponent));
export { DocumentReviewFormStage3Component };
