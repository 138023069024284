import { Component, Input, Output, EventEmitter, OnInit, ViewChild, ElementRef } from "@angular/core";
import { NgbDate, NgbDateAdapter, NgbDateParserFormatter, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { Injectable } from '@angular/core';
import { FormField } from "../../model/Form";

function makeNgbDateStruct(dateParts: string[]): NgbDateStruct {
  return {
    day: (dateParts.length > 0 && parseInt(dateParts[0], 10)) || (null as any as number),
    month: (dateParts.length > 1 && parseInt(dateParts[1], 10)) || (null as any as number),
    year: (dateParts.length > 2 && parseInt(dateParts[2], 10)) || (null as any as number),
  };
}

@Injectable()
export class NgbDateStringFormatAdapter extends NgbDateAdapter<string> {

  fromModel(date: string): NgbDateStruct {

    if (!date) {
      return null as any as NgbDateStruct;
    }
    return makeNgbDateStruct(date.split('/'));
  };

  toModel(date: NgbDateStruct): string {
    return date ? `${date.day}/${date.month}/${date.year}` : '';
  }
}

@Injectable()
export class DateParserFormatter implements NgbDateParserFormatter {

  parse(value: string): NgbDateStruct {
    return makeNgbDateStruct(value.split('/'));
  }

  format(date: NgbDateStruct): string {
    if (!date) return '';
    return `${date.day}/${date.month}/${date.year}`;
  }

}

@Component({
  selector: 'date-picker',
  styles: [`
      input.relative-input {
          position: relative;
      }
      span.clear-icon {
          cursor: pointer;
          width: 20px;
          height: 20px;
          position: absolute;
          top: 8px;
          right: 44px;
          text-align: center;
          font-size: 1.1em;
          font-weight: bolder;
          line-height: 1.2;
      }
  `],
  template: `
  <div class="input-group">
      <input class="form-control relative-input"
             [placeholder]="placeholder || 'DD/MM/YYYY'"
             [disabled]="disabled"
             [(ngModel)]="model"
             (dateSelect)="handleDateSelect()"
             (focus)="d.open()"
             [markDisabled]="markDisabledBound"
             (ngModelChange)="validateModelChange()"
             [ngClass]="validationClasses"
             ngbDatepicker #d="ngbDatepicker" 
             #datePicker>
      <span class="clear-icon" *ngIf="clearable && model" (click)="clearDate($event)" title="Clear">&times;</span>
      <div class="input-group-append">
          <button class="btn btn-outline-secondary" [disabled]="disabled" (click)="d.toggle()" type="button">
              <i class="fa fa-calendar"></i>
          </button>
      </div>
  </div>
  <small class="form-text text-danger" *ngIf="isTouched && isDateInvalid && !disableValidationStyles">Invalid date</small>
  `
})
export class DatePickerComponent implements OnInit {

  @ViewChild('datePicker') datePicker: ElementRef;
  @ViewChild('timePicker') timePicker: ElementRef;

  private _value: string = '';

  @Input()
  disabled: boolean = false;

  @Input('value')
  get value() {
    return this._value;
  }
  set value(newValue: string) {
    this._value = newValue;
    this.model = newValue;
  }

  @Output()
  valueChange = new EventEmitter<string>();

  @Input()
  formField: FormField<string>;

  @Input()
  disablePastDates: boolean = false;

  @Input()
  required: boolean = false;

  @Input()
  placeholder: string;

  @Input()
  disableValidationStyles: boolean = false;

  @Input()
  clearable: boolean = false;

  public markDisabledBound = this.markDisabled.bind(this);

  public model;
  public validationClasses = {};
  public isDateInvalid = false;
  public isDateEmpty = true;
  public isTouched = false;

  constructor() { }

  ngOnInit() {
    this.model = this.formField ? this.formField.value : this.value; // Use FormField first if provided
    this.isDateInvalid = !this.validateStringDate(this.model);
    this.isDateEmpty = !this.model || !this.model.trim();

    if (this.formField) {
      this.formField.valueChanges.subscribe(newValue => {
        this.model = newValue;

        this.isDateInvalid = !this.validateStringDate(this.model);
        this.isDateEmpty = !this.model || !this.model.trim();

        setTimeout(() => {
          if (this.datePicker) {
            /**
             * @author Steele Parker
             * @description
             * This is required because as at 12-12-2019 the application is not
             * updating the model, despite the model being updated multiple times
             * during change detection and value is also updated. Change detection
             * was manually forced and the DOM remained unchanged. It is believed
             * the ngbDatePicker scripts are intercepting and preventing the change 
             * for an unknown reason at this stage. The solution is to push a change
             * to the top of the stack and update the field manually.
             * 
             * This is not the desired solution, and it should be removed should you
             * find a solution for this
             */
            const input = this.datePicker.nativeElement as HTMLFormElement;

            input.value = this.model;
          }
        }, 100);
      });
    }
  }

  public handleDateSelect() {

    this.isTouched = true;

    this.isDateInvalid = !this.validateStringDate(this.model);
    this.isDateEmpty = !this.model || !this.model.trim();

    if (this.formField) {
      this.formField.value = this.model;
      if (this.formField.isDirty) {
        if (this.formField.isValid) {
          this.validationClasses = !this.disableValidationStyles ? { 'border-success': true } : {};
        } else {
          this.validationClasses = !this.disableValidationStyles ? { 'border-danger': true } : {};
        }
      }
    } else {
      this.validationClasses = !this.disableValidationStyles ? { 'border-success': true } : {};

      this.valueChange.emit(this.model);
    }
  }

  public validateModelChange() {

    this.isDateInvalid = !this.validateStringDate(this.model);
    this.isDateEmpty = !this.model || !this.model.trim();
    this.isTouched = true;

    if (this.isDateEmpty) {
      if (this.formField) {
        this.formField.value = '';
        if (this.formField.isValid) {
          this.isDateInvalid = false;
          this.validationClasses = !this.disableValidationStyles ? { 'border-success': true } : {};
        } else {
          this.validationClasses = !this.disableValidationStyles ? { 'border-danger': true } : {};
        }
      } else {
        if (this.required) {
          this.validationClasses = !this.disableValidationStyles ? { 'border-danger': true } : {};
        } else {
          this.validationClasses = !this.disableValidationStyles ? { 'border-success': true } : {};
        }
        this.valueChange.emit('');
      }
      return;
    }

    if (this.isDateInvalid) {
      this.validationClasses = !this.disableValidationStyles ? { 'border-danger': true } : {};
    } else {
      this.validationClasses = !this.disableValidationStyles ? { 'border-success': true } : {};
      if (!this.formField && !this.disableValidationStyles) {
        this.valueChange.emit('');
      }
    }
  }

  private validateStringDate(date: string): boolean {
    return date ? /^\d{1,2}\/\d{1,2}\/\d{4}$/.test(date) : false;
  }

  public markDisabled(date: NgbDate): boolean {
    if (!this.disablePastDates) {
      return false;
    }
    const currentDate = new Date();
    const currentDateStruct: NgbDateStruct = {
      day: currentDate.getDate(),
      month: currentDate.getMonth() + 1,
      year: currentDate.getFullYear(),
    };
    return date.before(currentDateStruct);
  }

  public clearDate(event) {
    event.preventDefault();
    event.stopPropagation();
    this.value = '';
    this.validationClasses = {};
    this.valueChange.emit('');
  }
}
