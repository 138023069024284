import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from "@angular/router";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { forkJoin, of } from "rxjs";
import { UsersService } from "../../../../../service/admin/UsersService";
import { IUserOutputModel, rolesList } from "../../../../../../common/contracts/users";
import { GroupsService } from "../../../../../service/admin/GroupsService";
import { RxFormsService } from "../../../../../service/util/reactiveForms";
import { ModalService } from "../../../../../service/ModalService";
import { ErrorHandlerService } from "../../../../../service/ErrorHandlerService";
import {
  emailValidator,
  trimRequiredValidator,
  validateConfirmPassword
} from "../../../../../validators/reactiveFormValidators";
import { LocationService } from "../../../../../service/LocationService";
import { CurrentUserService } from 'service/currentUser/CurrentUserService';
import { Session } from 'service/util/Session';
import { AuthService } from 'service/security/AuthService';
import { JWTPayload } from '../../../../../../model/Auth';

@Component({
  selector: 'app-groups-component',
  templateUrl: './editUser.component.html',
})
export class EditUserComponent implements OnInit {

  public isAdmin: boolean = false;
  public user: IUserOutputModel;
  public isNew: boolean = false;
  public groupsOptions: Array<{ id: string, text: string }> = [];
  public locationsOptions: Array<{ id: string, text: string }> = [];
  public rolesOptions = rolesList.map(role => ({ id: role, text: role }));
  public setPassword = false;
  private userId: number | null = null;
  public isLoaded: boolean = false;
  private isSelectorSet: { [k: string]: true } = {};

  public userForm = new FormGroup({
    firstName: new FormControl('', trimRequiredValidator),
    lastName: new FormControl('', trimRequiredValidator),
    username: new FormControl('', [trimRequiredValidator, emailValidator]),
    phone: new FormControl('', Validators.pattern(/^\d{10}$/)),
    password: new FormControl(''),
    confirmPassword: new FormControl('', validateConfirmPassword),
    role: new FormControl('', Validators.required),
    selectedGroups: new FormControl([]),
    selectedLocations: new FormControl([]),
  });

  constructor(
    private usersService: UsersService,
    private groupService: GroupsService,
    private locationService: LocationService,
    private router: Router,
    private route: ActivatedRoute,
    private activatedRoute: ActivatedRoute,
    private modalService: ModalService,
    private errorHandlerService: ErrorHandlerService,
    private currentUserService: CurrentUserService,
    public session: Session,
    private authService: AuthService) {

    this.userForm.controls.password.valueChanges.subscribe((value) => {
      this.userForm.controls.confirmPassword.updateValueAndValidity();
      if (value) {
        this.userForm.controls.confirmPassword.markAsTouched();
      }
    });

  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {

      if (params.userId) {
        if (/^\d+$/.test(params.userId) !== true) {
          console.error("Invalid user router param");
          this.router.navigate(["/404"], { replaceUrl: true });
          return;
        }
      }

      this.userId = parseInt(params.userId, 10);

      forkJoin(
        this.groupService.getGroups(),
        this.locationService.getLocations(),
        params.userId ? this.usersService.getUserById(this.userId) : of(null)
      ).subscribe({
        next: ([groups, locations, user]) => {
          this.isAdmin = this.currentUserService.isAdministrator.getValue();

          this.groupsOptions = groups.map(group => ({
            id: group.id.toString(),
            text: group.groupName
          }));

          this.locationsOptions = locations.map(location => ({
            id: location.id.toString(),
            text: location.name
          }));

          if (user !== null) {
            this.user = user;

            this.userForm.setValue({
              firstName: user.firstName,
              lastName: user.lastName,
              username: user.username,
              phone: user.phone,
              role: user.role,
              password: "",
              confirmPassword: "",
              selectedGroups: user.groups ? user.groups.map(group => group.id.toString()) : [],
              selectedLocations: user.locations ? user.locations.map(location => location.id.toString()) : []
            });

          } else {
            this.isNew = true;
            this.setPassword = true;
            this.userForm.controls.password.setValidators([Validators.required, Validators.minLength(6)]);
          }

          this.userForm.markAsPristine();
          this.isLoaded = true;

        },
        error: error => this.errorHandlerService.handleHttpError(error)
      });
    });
  }

  public toggleChangePassword() {
    this.setPassword = !this.setPassword;

    if (this.setPassword) {
      this.userForm.controls.password.setValidators([Validators.required, Validators.minLength(6)]);
    } else {
      this.userForm.controls.password.clearValidators();
      this.userForm.controls.password.setValue('');
      this.userForm.controls.password.markAsUntouched();
      this.userForm.controls.confirmPassword.setValue('');
      this.userForm.controls.confirmPassword.markAsUntouched();
    }
  }

  public handleSelectorChange(fieldName: string, event) {
    const control = this.userForm.get(fieldName);
    if (!control) {
      console.error(`Cannot find control ${fieldName} in userForm`);
      return;
    }
    control.setValue(event.value);
    if (this.isSelectorSet[fieldName]) {
      control.markAsTouched();
      this.userForm.markAsDirty();
    } else {
      this.isSelectorSet[fieldName] = true;
    }
  }

  public submit() {

    if (this.userForm.invalid) {
      RxFormsService.touchAllFormControls(this.userForm);
      return;
    }

    const formData = this.userForm.getRawValue();

    const observer = {
      next: () => this.goBack(),
      error: error => this.errorHandlerService.handleHttpError(error),
    };

    if (this.userId) {
      this.usersService.updateUser({
        id: this.userId,
        username: formData['username'].trim(),
        firstName: formData['firstName'].trim(),
        lastName: formData['lastName'].trim(),
        role: formData['role'],
        phone: formData['phone'] || null,
        groups: formData['selectedGroups'].map(groupId => parseInt(groupId, 10)),
        locations: formData['selectedLocations'].map(locationId => parseInt(locationId, 10)),
        password: this.setPassword ? formData['password'] : null,
      }).subscribe(observer);
    } else {
      this.usersService.createUser({
        username: formData['username'].trim(),
        firstName: formData['firstName'].trim(),
        lastName: formData['lastName'].trim(),
        role: formData['role'],
        password: formData['password'],
        phone: formData['phone'] || null,
        groups: formData['selectedGroups'].map(groupId => parseInt(groupId, 10)),
        locations: formData['selectedLocations'].map(locationId => parseInt(locationId, 10)),
      }).subscribe(observer);
    }

  }

  public archiveUser() {
    this.modalService.confirmRx({
      title: 'Warning',
      message: 'Are you sure that you want to delete this user?'
    }).subscribe(isConfirmed => {
      if (isConfirmed && this.userId) {
        this.usersService.archiveUser(this.userId).subscribe(
          () => this.goBack(),
          error => this.errorHandlerService.handleHttpError(error)
        );
      }
    });
  }

  private goBack() {
    this.router.navigate(['..'], { relativeTo: this.route });
  }

  public emulateUser() {
    console.log(this.user);
    if (!this.user || !this.user.id) {
      alert("This user cannot be emulated.");
      return;
    }

    this.session.lockInputRx(this.authService.switch(this.user.id)).subscribe((data: JWTPayload) => {
      // Grant the credentials
      this.authService.token = data.accessToken;
      this.session.credentials = data as JWTPayload;

      window.location.href = window.location.origin + this.router.createUrlTree(['/dashboard']);
    }, err => {
      alert(err.message);
    });
  }
}
