import { isNil } from 'lodash';
import { isArray } from "rxjs/internal-compatibility";
import { EventEmitter } from "@angular/core";
import * as moment from 'moment';
import "moment-timezone";
import { environment } from '../environments/environment';
var FormFieldConfig = /** @class */ (function () {
    function FormFieldConfig() {
    }
    return FormFieldConfig;
}());
export { FormFieldConfig };
var FormField = /** @class */ (function () {
    function FormField(initalValue, config) {
        this.originalValidation = null;
        this.disabled = false;
        this.placeholder = '';
        // Is the Field currently Valid against the Verification Method
        this.isValid = true;
        // Enables showing the error
        this.showError = false;
        this.valueChanges = new EventEmitter();
        this._value = initalValue;
        this.placeholder = config ? config.placeholder || '' : '';
        this.nullEquivilent = config ? config.nullEquivilent : null;
        this.recordParamType = config ? config.recordParamType || RecordParamType.String : RecordParamType.String;
        Object.assign(this, config);
        this.validate();
        // Cannot be dirty on construct, even if a value is set
        if (this.isDirty)
            this.isDirty = false;
    }
    FormField.prototype.showErrorHelp = function () {
        if (!this.showError)
            return false;
        return !this.isValid;
    };
    FormField.prototype.iifIsValid = function (validString, invalidString) {
        if (!this.isDirty && !this.showError) {
            return '';
        }
        if (this.isValid) {
            return validString;
        }
        return invalidString;
    };
    FormField.prototype.validate = function () {
        var _this = this;
        if (this.validation) {
            if (isArray(this.validation)) {
                // If any validation method calls the field valid, then so it shall be
                this.isValid = this.validation.find(function (v) { return v(_this.value); }) || false;
            }
            else {
                this.isValid = this.validation(this.value);
            }
        }
        else {
            this.isValid = true;
        }
    };
    Object.defineProperty(FormField.prototype, "value", {
        get: function () {
            return !isNil(this._value) ? this._value : null;
        },
        set: function (val) {
            var valIsNull = this.isNull(val);
            // Do not set the value as dirty if the change is null to an empty string
            if (this.value !== val && !(this.valueIsNull && valIsNull)) {
                this.isDirty = true;
            }
            this._value = val;
            this.validate();
            if (this.isDirty && this.onChange) {
                this.onChange(val);
            }
            this.valueChanges.emit(this._value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormField.prototype, "valueIsNull", {
        /**
         * @description Determines if the current value is null/blank
         */
        get: function () {
            return this.isNull(this.value);
        },
        enumerable: true,
        configurable: true
    });
    /**
     * @description Checks if the supplied value is considered 'nullly' with respect to the nullEquivilent
     * @param {T|null} val
     * @returns
     */
    FormField.prototype.isNull = function (val) {
        return val === null
            || val === undefined
            || (this.nullEquivilent && val === this.nullEquivilent)
            || ((typeof val === 'string' || val instanceof String) && val.length === 0);
    };
    FormField.prototype.toRecordParam = function (name) {
        switch (this.recordParamType) {
            case RecordParamType.String:
                var strVal = this.value;
                if (strVal && strVal !== null && strVal.length > 0) {
                    return { name: name, stringData: strVal };
                }
                break;
            case RecordParamType.Number:
                var numVal = this.value;
                if (numVal && numVal !== null &&
                    ((typeof numVal === 'string' && numVal.length > 0) ||
                        (typeof numVal === 'number'))) {
                    return { name: name, intData: Number(numVal) };
                }
                break;
            case RecordParamType.Enum:
                var enumVal = this.value;
                if (enumVal && enumVal !== null && enumVal.length > 0) {
                    return { name: name, enumId: Number(enumVal) };
                }
                break;
            case RecordParamType.Boolean:
                var boolVal = this.value;
                if (boolVal !== null) {
                    return { name: name, intData: boolVal ? 1 : 0 };
                }
                break;
            case RecordParamType.JSON:
                var jsonVal = this.value;
                if (jsonVal && jsonVal !== null && jsonVal.length > 0) {
                    return { name: name, jsonData: jsonVal };
                }
                break;
            case RecordParamType.Date:
                var dateStr = this.value;
                if (dateStr && dateStr !== null && dateStr.length > 0) {
                    var dateData = moment(dateStr, 'DD-MM-YYYY').tz(environment.timeZone);
                    return { name: name, dateData: dateData.toISOString() };
                }
                break;
        }
        return undefined;
    };
    FormField.prototype.fromRecordParam = function (name, recordProperty) {
        switch (this.recordParamType) {
            case RecordParamType.String:
                this.value = recordProperty.stringData;
                break;
            case RecordParamType.Number:
                this.value = recordProperty.intData;
                break;
            case RecordParamType.Enum:
                this.value = recordProperty.enumId;
                break;
            case RecordParamType.Boolean:
                this.value = ((recordProperty.intData || 0) > 0);
                break;
            case RecordParamType.JSON:
                this.value = recordProperty.jsonData;
                break;
            case RecordParamType.Date:
                if (recordProperty.dateData)
                    this.value = moment(recordProperty.dateData).tz(environment.timeZone).format('DD/MM/YYYY');
                break;
        }
        return undefined;
    };
    // tslint:disable-next-line:variable-name
    FormField.ValidationMethods = {
        IsEmail: function (value) {
            if (!value)
                return false;
            // tslint:disable-next-line:max-line-length
            var regExp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
            return (value.match(regExp) !== null);
        },
        IsIPv4: function (value) {
            if (!value)
                return false;
            // tslint:disable-next-line:max-line-length
            var regExp = new RegExp(/^([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5])\.([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5])\.([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5])\.([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5])$/);
            return (value.match(regExp) !== null);
        },
        Match: function (pattern) {
            return (function (value) {
                var evaluatedValue = value;
                if (value === null)
                    evaluatedValue = '';
                var regExp = new RegExp(pattern);
                return (evaluatedValue.match(regExp) !== null);
            });
        },
        IsEqual: function (otherField) {
            return (function (value) {
                return otherField.value === value;
            });
        },
        IsNotBlank: function (value) {
            if (!value)
                return false;
            if (typeof value === 'string')
                return !!(value && value.length > 0);
            return typeof value === 'number';
        },
        IsBlank: function (value) {
            return !FormField.ValidationMethods.IsNotBlank(value);
        },
        None: function (value) { return true; },
        IsNotNull: function (value) {
            return value !== null;
        }
    };
    return FormField;
}());
export { FormField };
export var RecordParamType;
(function (RecordParamType) {
    RecordParamType["String"] = "string";
    RecordParamType["Number"] = "number";
    RecordParamType["Enum"] = "enum";
    RecordParamType["Boolean"] = "boolean";
    RecordParamType["JSON"] = "json";
    RecordParamType["NULL"] = "null";
    RecordParamType["Date"] = "date";
})(RecordParamType || (RecordParamType = {}));
