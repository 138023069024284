<div *ngIf="!showForm">
    Loading...
</div>
<form-actions [formData]="formData"></form-actions>
<report-form-0
    #reportStage0
    [formData]="formData"
    *ngIf="showForm && targetStage === 0 && (!formData || !formData.isDeleted)">
</report-form-0>
<report-form-1
    #reportStage1
    [formData]="formData"
    *ngIf="showForm && targetStage === 1 && (!formData || !formData.isDeleted)">
</report-form-1>
<report-form-2
    #reportStage2
    [formData]="formData"
    *ngIf="showForm && targetStage === 2 && (!formData || !formData.isDeleted)">
</report-form-2>
<!---
    The read only view of the form should be always visible throughout the process
--->
<div class="{{ targetStage === 4 ? '' : 'pt-2' }}">&nbsp;</div>
<report-form-4 [formData]="formData" *ngIf="showForm && targetStage > 0 && !!formData"></report-form-4>

<div class="row" *ngIf="childForms">
    <div class="col-12">
        <h1 class="text-uppercase text-white bg-teal p-2 px-3 my-2">Child Forms</h1>
        <div class="pl-2">
            <child-forms-tree [childForms]="childForms"></child-forms-tree>
        </div>
    </div>
</div>

