/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./splash.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../authentication-icon/authentication-icon.component.ngfactory";
import * as i3 from "../authentication-icon/authentication-icon.component";
import * as i4 from "../../service/util/Session";
import * as i5 from "../../service/currentUser/CurrentUserService";
import * as i6 from "@angular/router";
import * as i7 from "@angular/common";
import * as i8 from "./splash.component";
import * as i9 from "../../service/security/AuthService";
var styles_SplashComponent = [i0.styles];
var RenderType_SplashComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SplashComponent, data: {} });
export { RenderType_SplashComponent as RenderType_SplashComponent };
function View_SplashComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "authentication-icon", [], null, null, null, i2.View_AuthenticationIconComponent_0, i2.RenderType_AuthenticationIconComponent)), i1.ɵdid(1, 114688, null, 0, i3.AuthenticationIconComponent, [i4.Session, i5.CurrentUserService, i6.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_SplashComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "container d-flex h-100"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "row w-100"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "col-12 align-self-center text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "col-12 col-lg-6 offset-lg-3 col-md-8 offset-md-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "img", [["src", "assets/images/logo.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "profileIcon d-none d-md-block"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SplashComponent_1)), i1.ɵdid(8, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = false; _ck(_v, 8, 0, currVal_0); }, null); }
export function View_SplashComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-splash", [], null, null, null, View_SplashComponent_0, RenderType_SplashComponent)), i1.ɵdid(1, 114688, null, 0, i8.SplashComponent, [i4.Session, i9.AuthService, i6.Router, i6.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SplashComponentNgFactory = i1.ɵccf("app-splash", i8.SplashComponent, View_SplashComponent_Host_0, {}, {}, []);
export { SplashComponentNgFactory as SplashComponentNgFactory };
