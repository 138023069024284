var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { GenericService } from "../GenericService";
import { HttpClient, HttpParams } from "@angular/common/http";
import { of } from "rxjs";
import { share, map, tap } from "rxjs/operators";
var DocumentsService = /** @class */ (function (_super) {
    __extends(DocumentsService, _super);
    function DocumentsService(http) {
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.cachedDocuments = null;
        var queryParams = new HttpParams({
            fromObject: {
                skip: '0',
                limit: '50',
                sortBy: 'name',
                order: 'asc',
            }
        });
        _this.getDocumentsRequest = _this.http.get(DocumentsService.apiPrefix, { params: queryParams }).pipe(map(function (data) {
            _this.cachedDocuments = data.items;
            setTimeout(function () {
                _this.cachedDocuments = null; // invalidate documentCollections cache in 20 minutes
            }, 20 * 60 * 1000);
            return data.items;
        }), share() //share results between multiple subscriptions
        );
        return _this;
    }
    DocumentsService.prototype.getDocuments = function () {
        return this.cachedDocuments ? of(this.cachedDocuments) : this.getDocumentsRequest;
    };
    DocumentsService.prototype.getPagedDocuments = function (documentCollectionId, includeArchived, offset, pageSize, sortBy, order, counts) {
        if (offset === void 0) { offset = 0; }
        if (pageSize === void 0) { pageSize = 20; }
        if (sortBy === void 0) { sortBy = 'name'; }
        if (order === void 0) { order = 'asc'; }
        if (counts === void 0) { counts = true; }
        var filter = {};
        if (documentCollectionId) {
            filter['documentCollectionId'] = documentCollectionId.toString();
        }
        if (includeArchived) {
            filter['includeArchived'] = includeArchived.toString();
        }
        var queryParams = new HttpParams({
            fromObject: __assign({ skip: offset.toString(), limit: pageSize.toString(), sortBy: sortBy,
                order: order }, filter
            // counts: counts ? '1' : '0',
            )
        });
        return this.http.get(DocumentsService.apiPrefix, { params: queryParams });
    };
    DocumentsService.prototype.getDocumentById = function (id) {
        return this.http.get(DocumentsService.apiPrefix + "/" + id);
    };
    DocumentsService.prototype.createDocument = function (documentCollectionData) {
        var _this = this;
        return this.http.post(DocumentsService.apiPrefix, documentCollectionData).pipe(tap(function () {
            _this.cachedDocuments = null;
        }));
    };
    DocumentsService.prototype.updateDocument = function (documentCollectionChanges) {
        var _this = this;
        return this.http.put(DocumentsService.apiPrefix, documentCollectionChanges).pipe(tap(function () {
            _this.cachedDocuments = null;
        }));
    };
    DocumentsService.prototype.archiveDocument = function (id) {
        var _this = this;
        return this.http.delete(DocumentsService.apiPrefix + "/" + id).pipe(tap(function () {
            _this.cachedDocuments = null;
        }));
    };
    DocumentsService.prototype.getDocumentInstanceByDocumentId = function (id) {
        return this.http.get(DocumentsService.apiPrefix + "/" + id + "/documentInstance");
    };
    DocumentsService.apiPrefix = '/api/documentDRM';
    return DocumentsService;
}(GenericService));
export { DocumentsService };
