import { Injectable } from '@angular/core';
import {GenericService} from "../GenericService";
import { HttpClient, HttpParams } from "@angular/common/http";
import {
  DocumentMetaPaginationResponse,
  ICreateDocumentMetaInputModel,
  IDocumentMetaSortedField,
  IUpdateDocumentMetaInputModel,
  IDocumentMetaOutputModel
} from "../../../common/contracts/documentMeta";
import { ISortOrderValue } from "../../../common/contracts/common";
import { Observable, of } from "rxjs";
import { share, map, tap } from "rxjs/operators";

@Injectable()
export class DocumentMetaService extends GenericService {

  static readonly apiPrefix = '/api/document-meta';

  private cachedDocumentMeta: IDocumentMetaOutputModel[] | null = null;
  private readonly getDocumentMetaRequest: Observable<IDocumentMetaOutputModel[]>;

  constructor(private http: HttpClient) {
    super();

    const queryParams = new HttpParams({
      fromObject: {
        skip: '0',
        limit: '50',
        sortBy: 'name',
        order: 'asc',
      }
    });

    this.getDocumentMetaRequest = this.http.get<DocumentMetaPaginationResponse>(DocumentMetaService.apiPrefix, { params: queryParams}).pipe(
      map(data => {
        this.cachedDocumentMeta = data.items;
        setTimeout(() => {
          this.cachedDocumentMeta = null; // invalidate tags cache in 20 minutes
        }, 20 * 60 * 1000);
        return data.items;
      }),
      share() //share results between multiple subscriptions
    );
  }

  public getMeta(): Observable<IDocumentMetaOutputModel[]> {
    return this.cachedDocumentMeta ? of(this.cachedDocumentMeta) : this.getDocumentMetaRequest;
  }

  public getPagedMeta(offset: number = 0, pageSize: number = 20, sortBy: IDocumentMetaSortedField = 'name', order: ISortOrderValue = 'asc', counts: boolean = true) {
    const queryParams = new HttpParams({
      fromObject: {
        skip: offset.toString(),
        limit: pageSize.toString(),
        sortBy,
        order,
        // counts: counts ? '1' : '0',
      }
    });
    return this.http.get<DocumentMetaPaginationResponse>(DocumentMetaService.apiPrefix, { params: queryParams});
  }

  public getMetaByDocumentId(id: number) {
    return this.http.get(`${DocumentMetaService.apiPrefix}/document/${id}`);
  }

  public createMeta(tagData: ICreateDocumentMetaInputModel) {
    return this.http.post(DocumentMetaService.apiPrefix, tagData).pipe(tap(() => {
      this.cachedDocumentMeta = null;
    }));
  }

  public updateMeta(tagChanges: IUpdateDocumentMetaInputModel) {
    return this.http.put(DocumentMetaService.apiPrefix, tagChanges).pipe(tap(() => {
      this.cachedDocumentMeta = null;
    }));
  }

  public archiveMeta(id: number) {
    return this.http.delete(`${DocumentMetaService.apiPrefix}/${id}`).pipe(tap(() => {
      this.cachedDocumentMeta = null;
    }));
  }

}
