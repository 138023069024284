import {IFormParams, IFormRecordPropertyParam} from "../../common/contracts/form";
import {Injectable} from "@angular/core";
import * as moment from 'moment';
import has = Reflect.has;
import {FormService} from "./FormService";
import {ErrorHandlerService} from "./ErrorHandlerService";
import {FormRecordService} from "./FormRecordService";
import { environment } from "../environments/environment";

export class FollowUp {
  userGroupId:string;
  
  description:string;
  
  dueDate:string;
}

@Injectable()
export class FollowUpService {
  
  constructor(
    private formService: FormService,
    public formRecordService: FormRecordService,
    private errorHandler: ErrorHandlerService,
  ) {
  
  }
  
  protected getFollowUpIsoDates( localDueDate: string ): { dueAt: string, alertAt: string } {
    
    let now = moment().tz(environment.timeZone).set({
      'hour': 0,
      'minute': 0,
      'second': 0,
      'millisecond': 0
    });
    
    let dueAt = moment(localDueDate, 'DD-MM-YYYY').tz(environment.timeZone);
    
    let dueAtOffset:number = ( dueAt.diff(now, 'days') / 2 );
    
    let alertAt = now.add(dueAtOffset, 'days');
    
    return {
      dueAt: dueAt.toISOString(false),
      alertAt: alertAt.toISOString(false)
    }
  }
  
  fail(msg:string, err:any) {
    console.error(msg, err);
    this.errorHandler.handleHttpError(err);
  };
  
  /**
   * @description Generates "FollowUp" type forms based on data entered into the followUp widget
   * @param {number} parentForm The ID of the form which generated the followUp
   * @param {number} formLocationId The location from the followUp, which should always come from the last stage1 submission on the parent form
   * @param {FollowUp[]} followUps FollowUp Widget Data
   * @param formType The value for the "This Fur Follows up A... Report, Audit, New, Other..."
   * @param {() => void} complete Callback after generating followups
   */
  generateFollowUps(parentForm:number, formLocationId:number | null, followUps: FollowUp[], formType: number | null, complete: () => void ):void | undefined {
    let followUpsProcessing:number = 0;
    
    followUps.forEach( followUp => {
      
      if( followUp.userGroupId.length === 0
        && followUp.dueDate.length === 0
        && followUp.description.length === 0
      ) {
        if( complete )
          complete();
        
        return;
      }
      
      followUpsProcessing++;
      
      let { dueAt, alertAt } = this.getFollowUpIsoDates(followUp.dueDate);
      
      let userGroupId : number | null = followUp.userGroupId ? Number(followUp.userGroupId) : null;
      
      let createFormProps:IFormParams = {
        categoryId: 3,
        formLocationId,
        userGroupId,
        dueAt,
        alertAt,
        notifyOnComplete: null,
        stage: 1,
        parentFormId: parentForm
      };
      
      if( has(followUp, 'userGroupId') ) {
        createFormProps.userGroupId = parseInt(followUp.userGroupId);
      }
      
      this.formService.createForm(createFormProps)
      .subscribe((data:any) => {
        let properties: Partial<IFormRecordPropertyParam>[] = [{
          name: "summary",
          stringData: followUp.description
        }];
        
        if( userGroupId )
          properties.push({
            name: "userGroupId",
            intData: userGroupId
          });
        
        if( formType )
          properties.push({
            name: 'type',
            enumId: formType
          });
        
        this.formRecordService.createRecord({
          formId: data['id'],
          // Intentionally cast the properties object since we know its correct
          properties: properties as any,
          stage: 0,
          documents: [],
          isComplete: true
        })
        .subscribe( () => {
          followUpsProcessing--;
          
          if( followUpsProcessing === 0) {
            complete();
          }
        }, err => this.fail("Error creating new record", err))
      }, err => this.fail('Error while creating a form', err));
    });
    
    if( followUpsProcessing === 0) {
      complete();
    }
  }
}