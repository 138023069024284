import { EventEmitter, Injectable } from "@angular/core";
import { GenericService } from "../GenericService";
import { HttpClient } from "@angular/common/http";
import lscache from 'lscache';
import { catchError, tap } from "rxjs/operators";
import { Session } from "../util/Session";
import { IUserOutputModel } from "../../../common/contracts/users";
import { IUpdateUserProfileInputModel } from "../../../common/contracts/currentUser";
import { BehaviorSubject, Observable } from "rxjs";
import * as Sentry from "@sentry/browser";

@Injectable()
export class CurrentUserService extends GenericService {

	static apiPrefix = '/api/current_user';

	public userData: IUserOutputModel | null = null;

	public currentUserId: BehaviorSubject<null | number>;
	public currentUserFullName: BehaviorSubject<string>;
	public isAdministrator: BehaviorSubject<boolean>;
	public isAdministratorOrManager: BehaviorSubject<boolean>;
	public isAdminOrDocumentManager: BehaviorSubject<boolean>;
	public currentUserData: BehaviorSubject<null | ( IUserOutputModel & { logoutUrl?:string }) >;
	public logoutEvents = new EventEmitter();

	constructor(private http: HttpClient, private session: Session) {
		super();

		this.currentUserId = new BehaviorSubject(null);
		this.currentUserFullName = new BehaviorSubject('');
		this.isAdministrator = new BehaviorSubject(false);
		this.isAdministratorOrManager = new BehaviorSubject(false);
		this.isAdminOrDocumentManager = new BehaviorSubject(false);
		this.currentUserData = new BehaviorSubject(null);
	}

	public getCurrentUser(): Observable<IUserOutputModel> {
		return this.http.get<IUserOutputModel & { logoutUrl?: string }>(CurrentUserService.apiPrefix).pipe(tap(user => {
			this.userData = user;

			Sentry.configureScope(scope => {
				scope.setUser({
					id: user.id.toString(),
					email: user.username
				})
			});

			this.currentUserId.next(this.userData.id);
			this.currentUserFullName.next(`${this.userData.firstName} ${this.userData.lastName}`);
			this.isAdministrator.next(this.userData.role === 'administrator');
			this.isAdministratorOrManager.next(this.userData.role === 'administrator' || this.userData.role === 'manager');
			this.isAdminOrDocumentManager.next(this.userData.role === 'administrator' || (
				this.userData.role === 'manager' &&
				!!this.userData.groups &&
				this.userData.groups.length > 0 &&
				!!this.userData.groups.find(group => group.groupName === 'Update Documents')
			));
			this.currentUserData.next(user);
		}), catchError((err) => {
			this.cleanUserCredentials();
			throw err; // TODO check if it's needed to throw here
		}));
	}

	public updateUserProfile(profile: IUpdateUserProfileInputModel) {
		return this.http.put(CurrentUserService.apiPrefix, profile);
	}

	public getCurrentUserIdOrFail(): number {
		let result;
		if (this.userData)
			result = this.userData.id;

		if (!result)
			throw new Error("CurrentUserService did not have appropriate data");

		return result;
	}

	/**
	 * it's better to avoid using direct calls to service methods from angular templates because angular doesn't know
	 * when a result of a method changes and it begins to run constantly this method expecting for changes.
	 * It's better to use reactive approach with observables, please use "currentUserFullName | async" instead.
	 *
	 * Retained for Posterity
	 */
	/*public getCurrentUserFullName() : string {
  
	  if( this.userData ) {
		return `${this.userData.firstName } ${this.userData.lastName}`;
	  }
  
	  return '';
	}*/

	public recoverTokenFromLocalStorage(): boolean {
		const token = lscache.get('token');

		if (token) {
			this.token = token;
			this.session.credentials = {
				accessToken: token,
				tokenType: "Bearer",
			};
			return true;
		} else {
			return false;
		}
	}

	public cleanUserCredentials() {
		lscache.remove('token');

		Sentry.configureScope(scope => {
			scope.clear();
		});

		this.userData = null;
		this.session.credentials = null;
		GenericService._token = null;
		this.currentUserFullName.next('');
		this.isAdministrator.next(false);
		this.currentUserData.next(null);
		this.logoutEvents.emit();
	}
}
