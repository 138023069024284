var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { EventEmitter, OnInit, ElementRef } from "@angular/core";
import { NgbDateAdapter } from "@ng-bootstrap/ng-bootstrap";
import { FormField } from "../../model/Form";
function makeNgbDateStruct(dateParts) {
    return {
        day: (dateParts.length > 0 && parseInt(dateParts[0], 10)) || null,
        month: (dateParts.length > 1 && parseInt(dateParts[1], 10)) || null,
        year: (dateParts.length > 2 && parseInt(dateParts[2], 10)) || null,
    };
}
var NgbDateStringFormatAdapter = /** @class */ (function (_super) {
    __extends(NgbDateStringFormatAdapter, _super);
    function NgbDateStringFormatAdapter() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    NgbDateStringFormatAdapter.prototype.fromModel = function (date) {
        if (!date) {
            return null;
        }
        return makeNgbDateStruct(date.split('/'));
    };
    ;
    NgbDateStringFormatAdapter.prototype.toModel = function (date) {
        return date ? date.day + "/" + date.month + "/" + date.year : '';
    };
    return NgbDateStringFormatAdapter;
}(NgbDateAdapter));
export { NgbDateStringFormatAdapter };
var DateParserFormatter = /** @class */ (function () {
    function DateParserFormatter() {
    }
    DateParserFormatter.prototype.parse = function (value) {
        return makeNgbDateStruct(value.split('/'));
    };
    DateParserFormatter.prototype.format = function (date) {
        if (!date)
            return '';
        return date.day + "/" + date.month + "/" + date.year;
    };
    return DateParserFormatter;
}());
export { DateParserFormatter };
var DatePickerComponent = /** @class */ (function () {
    function DatePickerComponent() {
        this._value = '';
        this.disabled = false;
        this.valueChange = new EventEmitter();
        this.disablePastDates = false;
        this.required = false;
        this.disableValidationStyles = false;
        this.clearable = false;
        this.markDisabledBound = this.markDisabled.bind(this);
        this.validationClasses = {};
        this.isDateInvalid = false;
        this.isDateEmpty = true;
        this.isTouched = false;
    }
    Object.defineProperty(DatePickerComponent.prototype, "value", {
        get: function () {
            return this._value;
        },
        set: function (newValue) {
            this._value = newValue;
            this.model = newValue;
        },
        enumerable: true,
        configurable: true
    });
    DatePickerComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.model = this.formField ? this.formField.value : this.value; // Use FormField first if provided
        this.isDateInvalid = !this.validateStringDate(this.model);
        this.isDateEmpty = !this.model || !this.model.trim();
        if (this.formField) {
            this.formField.valueChanges.subscribe(function (newValue) {
                _this.model = newValue;
                _this.isDateInvalid = !_this.validateStringDate(_this.model);
                _this.isDateEmpty = !_this.model || !_this.model.trim();
                setTimeout(function () {
                    if (_this.datePicker) {
                        /**
                         * @author Steele Parker
                         * @description
                         * This is required because as at 12-12-2019 the application is not
                         * updating the model, despite the model being updated multiple times
                         * during change detection and value is also updated. Change detection
                         * was manually forced and the DOM remained unchanged. It is believed
                         * the ngbDatePicker scripts are intercepting and preventing the change
                         * for an unknown reason at this stage. The solution is to push a change
                         * to the top of the stack and update the field manually.
                         *
                         * This is not the desired solution, and it should be removed should you
                         * find a solution for this
                         */
                        var input = _this.datePicker.nativeElement;
                        input.value = _this.model;
                    }
                }, 100);
            });
        }
    };
    DatePickerComponent.prototype.handleDateSelect = function () {
        this.isTouched = true;
        this.isDateInvalid = !this.validateStringDate(this.model);
        this.isDateEmpty = !this.model || !this.model.trim();
        if (this.formField) {
            this.formField.value = this.model;
            if (this.formField.isDirty) {
                if (this.formField.isValid) {
                    this.validationClasses = !this.disableValidationStyles ? { 'border-success': true } : {};
                }
                else {
                    this.validationClasses = !this.disableValidationStyles ? { 'border-danger': true } : {};
                }
            }
        }
        else {
            this.validationClasses = !this.disableValidationStyles ? { 'border-success': true } : {};
            this.valueChange.emit(this.model);
        }
    };
    DatePickerComponent.prototype.validateModelChange = function () {
        this.isDateInvalid = !this.validateStringDate(this.model);
        this.isDateEmpty = !this.model || !this.model.trim();
        this.isTouched = true;
        if (this.isDateEmpty) {
            if (this.formField) {
                this.formField.value = '';
                if (this.formField.isValid) {
                    this.isDateInvalid = false;
                    this.validationClasses = !this.disableValidationStyles ? { 'border-success': true } : {};
                }
                else {
                    this.validationClasses = !this.disableValidationStyles ? { 'border-danger': true } : {};
                }
            }
            else {
                if (this.required) {
                    this.validationClasses = !this.disableValidationStyles ? { 'border-danger': true } : {};
                }
                else {
                    this.validationClasses = !this.disableValidationStyles ? { 'border-success': true } : {};
                }
                this.valueChange.emit('');
            }
            return;
        }
        if (this.isDateInvalid) {
            this.validationClasses = !this.disableValidationStyles ? { 'border-danger': true } : {};
        }
        else {
            this.validationClasses = !this.disableValidationStyles ? { 'border-success': true } : {};
            if (!this.formField && !this.disableValidationStyles) {
                this.valueChange.emit('');
            }
        }
    };
    DatePickerComponent.prototype.validateStringDate = function (date) {
        return date ? /^\d{1,2}\/\d{1,2}\/\d{4}$/.test(date) : false;
    };
    DatePickerComponent.prototype.markDisabled = function (date) {
        if (!this.disablePastDates) {
            return false;
        }
        var currentDate = new Date();
        var currentDateStruct = {
            day: currentDate.getDate(),
            month: currentDate.getMonth() + 1,
            year: currentDate.getFullYear(),
        };
        return date.before(currentDateStruct);
    };
    DatePickerComponent.prototype.clearDate = function (event) {
        event.preventDefault();
        event.stopPropagation();
        this.value = '';
        this.validationClasses = {};
        this.valueChange.emit('');
    };
    return DatePickerComponent;
}());
export { DatePickerComponent };
