/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../shared/buttonToggle.component.ngfactory";
import * as i2 from "../../../shared/buttonToggle.component";
import * as i3 from "../../../shared/preloaderWrapper.component.ngfactory";
import * as i4 from "../../../shared/preloaderWrapper.component";
import * as i5 from "@angular/forms";
import * as i6 from "@angular/common";
import * as i7 from "./editOptions.component";
import * as i8 from "../../../../service/admin/SettingsService";
import * as i9 from "../../../../service/ErrorHandlerService";
var styles_EditOptionsComponent = [];
var RenderType_EditOptionsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_EditOptionsComponent, data: {} });
export { RenderType_EditOptionsComponent as RenderType_EditOptionsComponent };
function View_EditOptionsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "row mt-5 mb-5"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [["class", "col-6 pl-4 pt-1"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Process Audit Templates On Submit: "])), (_l()(), i0.ɵeld(3, 0, null, null, 2, "div", [["class", "col-6"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "button-toggle", [], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        _co.settingForm.controls.processTemplates.setValue($event);
        var pd_0 = (_co.settingForm.markAsDirty() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_ButtonToggleComponent_0, i1.RenderType_ButtonToggleComponent)), i0.ɵdid(5, 114688, null, 0, i2.ButtonToggleComponent, [], { value: [0, "value"] }, { valueChange: "valueChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.settingForm.controls.processTemplates.value; _ck(_v, 5, 0, currVal_0); }, null); }
export function View_EditOptionsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 28, "preloader-wrapper", [], null, null, null, i3.View_PreloaderWrapperComponent_0, i3.RenderType_PreloaderWrapperComponent)), i0.ɵdid(1, 49152, null, 0, i4.PreloaderWrapperComponent, [], { isLoaded: [0, "isLoaded"] }, null), (_l()(), i0.ɵeld(2, 0, null, 0, 3, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "div", [["class", "col-12 text-lg-left"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "h1", [["class", "text-uppercase text-orange"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["System Options"])), (_l()(), i0.ɵeld(6, 0, null, 0, 22, "form", [["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i0.ɵnov(_v, 8).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i0.ɵnov(_v, 8).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(7, 16384, null, 0, i5.ɵangular_packages_forms_forms_bh, [], null, null), i0.ɵdid(8, 540672, null, 0, i5.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, null), i0.ɵprd(2048, null, i5.ControlContainer, null, [i5.FormGroupDirective]), i0.ɵdid(10, 16384, null, 0, i5.NgControlStatusGroup, [[4, i5.ControlContainer]], null, null), (_l()(), i0.ɵeld(11, 0, null, null, 5, "div", [["class", "row mt-5 mb-5"]], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 1, "div", [["class", "col-6 pl-4 pt-1"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Emails service enabled: "])), (_l()(), i0.ɵeld(14, 0, null, null, 2, "div", [["class", "col-6"]], null, null, null, null, null)), (_l()(), i0.ɵeld(15, 0, null, null, 1, "button-toggle", [], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        _co.settingForm.controls.isEmailsEnabled.setValue($event);
        var pd_0 = (_co.settingForm.markAsDirty() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_ButtonToggleComponent_0, i1.RenderType_ButtonToggleComponent)), i0.ɵdid(16, 114688, null, 0, i2.ButtonToggleComponent, [], { value: [0, "value"] }, { valueChange: "valueChange" }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EditOptionsComponent_1)), i0.ɵdid(18, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(19, 0, null, null, 0, "div", [["class", "row bg-default mx-1 my-3 py-4"]], null, null, null, null, null)), (_l()(), i0.ɵeld(20, 0, null, null, 8, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(21, 0, null, null, 3, "div", [["class", "col-12 col-md-6 col-lg-4 col-xl-2 col-xxl-1"]], null, null, null, null, null)), (_l()(), i0.ɵeld(22, 0, null, null, 2, "button", [["class", "btn btn-block btn-lg btn-success rounded-0"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.submit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(23, 0, null, null, 1, "span", [["class", "capitalize"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["save"])), (_l()(), i0.ɵeld(25, 0, null, null, 3, "div", [["class", "col-12 pt-2 col-md-6 pt-md-0 col-lg-4 col-xl-2 col-xxl-1"]], null, null, null, null, null)), (_l()(), i0.ɵeld(26, 0, null, null, 2, "a", [["class", "btn btn-block btn-lg btn-secondary rounded-0 text-center text-white"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goBack() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(27, 0, null, null, 1, "span", [["class", "capitalize"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["cancel"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoaded; _ck(_v, 1, 0, currVal_0); var currVal_8 = _co.settingForm; _ck(_v, 8, 0, currVal_8); var currVal_9 = _co.settingForm.controls.isEmailsEnabled.value; _ck(_v, 16, 0, currVal_9); var currVal_10 = false; _ck(_v, 18, 0, currVal_10); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i0.ɵnov(_v, 10).ngClassUntouched; var currVal_2 = i0.ɵnov(_v, 10).ngClassTouched; var currVal_3 = i0.ɵnov(_v, 10).ngClassPristine; var currVal_4 = i0.ɵnov(_v, 10).ngClassDirty; var currVal_5 = i0.ɵnov(_v, 10).ngClassValid; var currVal_6 = i0.ɵnov(_v, 10).ngClassInvalid; var currVal_7 = i0.ɵnov(_v, 10).ngClassPending; _ck(_v, 6, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_11 = _co.settingForm.pristine; _ck(_v, 22, 0, currVal_11); }); }
export function View_EditOptionsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_EditOptionsComponent_0, RenderType_EditOptionsComponent)), i0.ɵdid(1, 114688, null, 0, i7.EditOptionsComponent, [i8.SettingsService, i9.ErrorHandlerService, i6.Location], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EditOptionsComponentNgFactory = i0.ɵccf("ng-component", i7.EditOptionsComponent, View_EditOptionsComponent_Host_0, {}, {}, []);
export { EditOptionsComponentNgFactory as EditOptionsComponentNgFactory };
