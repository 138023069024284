import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Location } from "@angular/common";
import * as moment from 'moment';
import "moment-timezone";
import { FormField } from "../../../../../model/Form";
import { FormComponent } from "../../../../../model/FormComponent";
import { Session } from "../../../../../service/util/Session";
import { CurrentUserService } from "../../../../../service/currentUser/CurrentUserService";
import { FormService } from "../../../../../service/FormService";
import { FormCategory } from "../../../../../../model/Category";
import { CategoryService } from "../../../../../service/CategoryService";
import { FormRecordService } from "../../../../../service/FormRecordService";
import {
	IFormOutputModel,
	IFormRecordOutputModel,
	IFormRecordPropertyParam
} from "../../../../../../common/contracts/form";
import { Observable } from "rxjs";
import { IButtonsSwitchOption } from "../../../../shared/buttonsSwitch.component";
import { GroupsService } from "../../../../../service/admin/GroupsService";
import { ErrorHandlerService } from "../../../../../service/ErrorHandlerService";
import { Router } from "@angular/router";
import { FollowUpWidgetComponent } from "../../../../shared/followUpWidget.component";
import { IDocumentType } from "../../../../../../common/contracts/document";
import { environment } from '../../../../../environments/environment';

@Component({
	selector: 'follow-up-form-0',
	templateUrl: './followUpFormStage0.component.html',
})
export class FollowUpFormStage0Component extends FormComponent implements OnInit {

	// Existing Form Data
	@Input() readOnly: boolean = false;
	@Input() hideHeader: boolean = false;

	@Input() formData: IFormOutputModel;
	@Input() sequence: number;

	public formRecord: IFormRecordOutputModel;

	@ViewChild('followUpWidget') followUpWidgetRef: FollowUpWidgetComponent;

	public dateString: string;
	public formCategoryId: number;
	public followUpTypes: IButtonsSwitchOption[] = [
		{
			text: 'Audit',
			value: 'audit',
		},
		{
			text: 'Report',
			value: 'report',
		},
		{
			text: 'New',
			value: 'new',
		},
		{
			text: 'Other',
			value: 'other',
		},
	];

	public form: { [key: string]: FormField<any> } = {
		//TODO: Rework this into enum
		followUpType: new FormField<String>('new'),
		location: new FormField<String>('', {
			validation: FormField.ValidationMethods.None
		}),
		type: new FormField<String>('', {
			validation: FormField.ValidationMethods.None
		}),
		summary: new FormField<String>('', {
			validation: FormField.ValidationMethods.IsNotBlank
		}),
		dueAt: new FormField<String>('', {
			validation: FormField.ValidationMethods.None
		}),
		department: new FormField<String>('', {
			validation: FormField.ValidationMethods.IsNotBlank
		}),
	};

	public documents: Array<IDocumentType> = [];

	constructor(
		public session: Session,
		public currentUserService: CurrentUserService,
		public formService: FormService,
		public categoryService: CategoryService,
		public formRecordService: FormRecordService,
		public groupsService: GroupsService,
		private errorHandler: ErrorHandlerService,
		private router: Router,
		location: Location,
	) {
		super(location);
	}

	registerFormFields() {
		this.formFields.push(...Object.keys(this.form).map((k: string) => this.form[k]));
	}

	onSubmit(isDraft: boolean) {
		this.session.lockInput(() => {
			return new Promise(async (resolve, reject) => {

				const isNewForm: boolean = !(this.formData && this.formData.id);

				let success = () => {
					resolve();

					if (isNewForm) {
						// go to dashboard
						this.router.navigate(['/dashboard']);
					} else {
						this.goBack();
					}
				};

				let fail = (msg: string, err: any) => {
					console.error(msg, err);
					this.errorHandler.handleHttpError(err);
					reject();
				};

				let formLocationId: number | null = this.form.location.isValid && this.form.location.value !== null ? Number(this.form.location.value) : null;
				if (formLocationId === 0) formLocationId = null;

				let userGroupId: number | null = this.form.department.isValid ? Number(this.form.department.value) : null;
				let stage: number = isDraft ? 0 : 1;

				// The assigned user should always be reset to null on submission of stage 0
				let assignedUserId: number | null = null;

				if (this.currentUserService.userData && isDraft)
					assignedUserId = this.currentUserService.userData.id;

				const momentDueAt = this.form.dueAt.value ? moment(this.form.dueAt.value, "DD/MM/YYYY") : null;
				const dueAt = momentDueAt ? momentDueAt.toISOString() : null;

				let alertAt: string | null = null;

				if (momentDueAt) {
					const halfTermSeconds = momentDueAt.diff(moment()) / 2.0 / 1000.0;
					alertAt = momentDueAt.subtract(halfTermSeconds, 'second').toISOString();
				}

				let formFunc: Observable<any>;

				if (!isNewForm) {
					formFunc = this.formService.updateForm({
						id: this.formData.id,
						formLocationId,
						userGroupId,
						stage,
						dueAt,
						alertAt,
						assignedUserId
					});
				} else {
					formFunc = this.formService.createForm({
						categoryId: this.formCategoryId,
						formLocationId,
						userGroupId,
						dueAt,
						alertAt,
						notifyOnComplete: null,
						stage,
						parentFormId: null,
						assignedUserId
					});
				}

				formFunc.subscribe((data: any) => {

					let properties: Partial<IFormRecordPropertyParam>[] = [];
					let formId: number = (this.formData && this.formData.id) ? this.formData.id : data['id'];

					let assignedUserGroupId: number | null = this.form.department.isValid ? Number(this.form.department.value) : null;
					if (assignedUserGroupId) {
						properties.push({
							name: "userGroupId",
							intData: assignedUserGroupId
						});
					}

					properties.push({
						name: "summary",
						stringData: this.form.summary.value
					});

					if (this.form.type.value && this.form.type.value.length)
						properties.push({
							name: "type",
							enumId: Number(this.form.type.value)
						});

					properties.push({
						name: "followUpType",
						stringData: this.form.followUpType.value
					});

					this.formRecordService.createRecord({
						formId: formId,
						// Intentionally cast the properties object since we know its correct
						properties: properties as any,
						stage: 0,
						documents: this.documents.map(doc => ({ id: doc.id, isTicked: !!doc.isTicked })),
						isComplete: isDraft ? false : true
					})
						.subscribe(success, err => fail('Error while creating a form', err));
				},
					err => fail('Error while create/update form', err));
			})
		});
	}

	ngOnInit() {

		this.dateString = moment().tz(environment.timeZone).format("DD-MM-YYYY");

		this.registerFormFields();

		this.loadEnums();

		this.repopulateFormFromData();
	}

	private repopulateFormFromData() {
		if (!this.formData || !this.formData.records || !this.formData.records.length)
			return;

		// Find the most recent stage0 record
		let stage0Records = this.formData.records.filter(record => record.stage === 0);

		if (stage0Records.length === 0)
			return;

		let mostRecentRecord = stage0Records.sort((a, b) => a.sequence > b.sequence ? 1 : -1).pop();

		//Convert the properties into easily accessible IFormRecordPropertyParam
		if (!mostRecentRecord || !mostRecentRecord.properties)
			return;

		/**
		 * FIX this is needed for correct functionality in submissionDetail component
		 */
		this.formRecord = mostRecentRecord;

		let simpleProperties: { [key: string]: IFormRecordPropertyParam } = {};

		mostRecentRecord.properties.forEach(recordProperty => {
			//eject invalid property
			if (!recordProperty.property)
				return;

			let result: Partial<IFormRecordPropertyParam> = {
				name: recordProperty.property.name
			};

			if (recordProperty.stringData)
				result.stringData = recordProperty.stringData;

			if (recordProperty.intData)
				result.intData = recordProperty.intData;

			if (recordProperty.jsonData)
				result.jsonData = recordProperty.jsonData;

			if (recordProperty.enumId)
				result.enumId = recordProperty.enumId;

			simpleProperties[result.name as string] = result as IFormRecordPropertyParam;
		});

		/**
		 * get attached documents
		 */
		this.documents = this.initTickedDocuments(this.formRecord.documents, this.formRecord.tickedDocuments);

		if (simpleProperties['userGroupId'])
			this.form.department.value = String(simpleProperties['userGroupId'].intData);
		else if (this.formData['userGroupId'])
			this.form.department.value = String(this.formData['userGroupId']);

		if (this.formData['formLocationId'])
			this.form.location.value = String(this.formData['formLocationId']);

		if (simpleProperties['summary'])
			this.form.summary.value = simpleProperties['summary'].stringData;

		if (simpleProperties['followUpType'])
			this.form.followUpType.value = simpleProperties['followUpType'].stringData;

		if (simpleProperties['type'])
			this.form.type.value = simpleProperties['type'].enumId;

		if (this.formData.dueAt) {
			this.form.dueAt.value = this.formData.dueAt ? moment(this.formData.dueAt).format('DD/MM/YYYY') : '';
		}
	}

	private loadEnums() {
		this.session.lockInputRx(this.categoryService.getCategories())
			.subscribe((data: FormCategory[]) => {

				let reportCategory: FormCategory | undefined = data.find((data: FormCategory) => !!data.name.match(/^Follow-Up/));

				if (reportCategory)
					this.formCategoryId = reportCategory.id as number;
				//else
				//Notify the user an error has occurred
			},
				err => {
					console.error('Error while getting categories', err);
					this.errorHandler.handleHttpError(err);
				});
	}
}
