<div class="row" *ngIf="!hideHeader">
    <div class="col-12 col-lg-6">
        <img src="assets/images/logo.svg" class="logoImg">
    </div>
    <div class="col-12 pt-2 pt-lg-0 col-lg-6 text-center">
        <div class="border border-dark rounded border-dashed d-inline-block float-lg-right py-3 px-5">
            <div class="row">
                <div class="col-12">
                    <strong class="font-weight-heavy">Issue Number:</strong>
                    {{ formData ? formData.issueNumber || "TBA" : "TBA" }}
                </div>
                <div class="col-12">
                    <strong class="font-weight-heavy">Document ID:</strong>
                    {{ documentId ? documentId || "TBA" : "TBA" }}
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-12">
                    <strong class="font-weight-heavy">Date Created:</strong> {{dateString}}
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-12">
        <h1 class="text-uppercase text-white bg-teal p-2 px-3 my-2">Management Review</h1>
    </div>
</div>
<form>
    <div class="row">
        <div class="col-12 col-sm-6">
            <div class="form-group">
                <label>Assign for further review:</label>
                <button-toggle [value]="form.reassign.value"
                    (valueChange)="form.reassign.value = $event; $event && form.reassignAdmin.value=false"
                    [disabled]="readOnly"></button-toggle>
            </div>
        </div>
        <div class="col-12 col-sm-6" *ngIf="form.reassign.value===true">
            <div class="form-group">
                <label>&nbsp;</label>
                <user-select [field]="form.reassignToUserId" [disabled]="readOnly"></user-select>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-lg-8 border-lg-right border-dashed">
            <div class="row">
                <div class="col-12">
                    <span>Management Comments:</span>
                </div>
            </div>
            <div class="row my-3">
                <div class="col-12">
                    <textarea class="form-control {{form.note.iifIsValid('border-success','border-danger')}}" autosize
                        replaceAmpersand [minRows]="3" [placeholder]="readOnly ? '' : 'Enter your brief note here...'"
                        [disabled]="readOnly" [spellcheck]="true" [(ngModel)]="form.note.value"
                        [ngModelOptions]="{standalone: true}"></textarea>
                    <small class="form-text text-danger" *ngIf="form.note.showErrorHelp()">Invalid note</small>
                </div>
            </div>

            <div class="row my-3">
                <div class="col-12">
                    <follow-up-widget #followUpWidget [model]="form.followUps.value"
                        (modelChange)="form.followUps.value=$event" [readOnly]="readOnly"></follow-up-widget>
                </div>
            </div>
        </div>
        <div class="col-12 offset-0 col-lg-4 pt-0 pt-lg-4">
            <div class="row">
                <div class="col-12 offset-0 col-xl-10 offset-xl-1">
                    <button class="btn btn-orange rounded-0 btn-block my-2" (click)="downloadLatestAprovedVersion()"
                        [disabled]="readOnly" *ngIf="!!latestAprovedVersion && !readOnly">
                        <i class="fa fa-download mx-1"></i>
                        <strong>Latest Approved Version</strong>
                    </button>
                    <button class="btn btn-orange rounded-0 btn-block my-2" (click)="downloadLatestDraft()"
                        *ngIf="(!!latestAprovedVersion || !!latestDraftRecord) && !readOnly">
                        <i class="fa fa-download mx-1"></i>
                        <strong>Latest Draft Version</strong>
                    </button>
                    <drm-document-uploads [(documents)]="uploadedDocument" [readOnly]="readOnly"></drm-document-uploads>
                </div>
            </div>
        </div>
    </div>
    <submission-detail [formRecord]="formRecord"></submission-detail>
    <div class="row" *ngIf="!readOnly">
        <div class="col-12 col-md-6 col-lg-4 col-xl-2 col-xxl-2">
            <!-- <button class="btn btn-block btn-lg btn-success rounded-0" [routerLink]="form.reassign.value && '/document-review-task/1/' || form.reassignAdmin.value && '/document-review-task/3/' || '/document-review-task/4/'"><span class="capitalize">Submit</span></button> -->
            <button class="btn btn-block btn-lg btn-success rounded-0" (click)="submit(false)"><span
                    class="capitalize">Submit</span></button>
        </div>
        <div class="col-12 pt-2 col-md-6 pt-md-0 col-lg-5 col-xl-3 col-xxl-2">
            <a class="btn btn-block btn-lg btn-primary rounded-0 text-center text-white" (click)="submit(true)"><span
                    class="capitalize">Save Draft</span></a>
        </div>
    </div>
</form>