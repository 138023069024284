<div class="container-fluid main-container">
  <div class="row header bg-white border-light border-bottom position-relative">
    <div class="col-4 text-center d-flex">
      <a [routerLink]="['/']" class="d-none d-md-block align-self-center logoLink"></a>

      <a [routerLink]="['/']" class="d-md-none align-self-center logoLink"></a>
    </div>
    
    <div class="col-8 d-flex profileIcon flex-column justify-content-around align-items-end">
      <authentication-icon [small]="true"></authentication-icon>
    </div>
  </div>

  <router-outlet></router-outlet>

  <div class="versionIdentifier">V#{{appVersion}}</div>
</div>
