import { isNil } from 'lodash';
import { isArray } from "rxjs/internal-compatibility";
import { EventEmitter } from "@angular/core";
import { IFormRecordPropertyParam } from '../../common/contracts/form';
import * as moment from 'moment';
import "moment-timezone";
import { IRecordPropertyType } from '../../common/contracts/recordProperty';
import { environment } from '../environments/environment';

export class FormFieldConfig<T = any> {
	validation: any;
	value: T;
	onChange?: (val: T | null) => void;
	placeholder?: string;
	nullEquivilent?: any;
	recordParamType?: RecordParamType;
}

export class FormField<T> {
	name?: string;
	isDirty: boolean;
	recordParamType: RecordParamType;
	validation: (value: T | T[] | null) => boolean;
	originalValidation: ( (value: T | T[] | null) => boolean ) | null = null;
	_value: T | null;
	disabled: boolean = false;

	onChange: (val: T | null) => void;

	placeholder: string = '';

	// Is the Field currently Valid against the Verification Method
	isValid: boolean = true;

	// Enables showing the error
	showError: boolean = false;

	// Allows an equivilence check against null
	nullEquivilent: T;

	valueChanges = new EventEmitter<T>();

	constructor(initalValue: T | null, config?: Partial<FormFieldConfig<T>>) {
		this._value = initalValue;
		this.placeholder = config ? config.placeholder || '' : '';
		this.nullEquivilent = config ? config.nullEquivilent : null;
		this.recordParamType = config ? config.recordParamType || RecordParamType.String : RecordParamType.String;

		Object.assign(this, config);

		this.validate();

		// Cannot be dirty on construct, even if a value is set
		if (this.isDirty) this.isDirty = false;
	}

	showErrorHelp(): boolean {
		if (!this.showError) return false;

		return !this.isValid;
	}

	iifIsValid(validString: String, invalidString: String): String {
		if (!this.isDirty && !this.showError) {
			return '';
		}

		if (this.isValid) {
			return validString;
		}

		return invalidString;
	}

	public validate() {
		if (this.validation) {
			if (isArray(this.validation)) {
				// If any validation method calls the field valid, then so it shall be
				this.isValid = this.validation.find(v => v(this.value)) || false;
			} else {
				this.isValid = this.validation(this.value);
			}
		} else {
			this.isValid = true;
		}
	}

	set value(val: T | null) {

		let valIsNull = this.isNull(val);
		
		// Do not set the value as dirty if the change is null to an empty string
		if (this.value !== val && !(this.valueIsNull && valIsNull)) {
			this.isDirty = true;
		}

		this._value = val;

		this.validate();

		if (this.isDirty && this.onChange) {
			this.onChange(val);
		}

		this.valueChanges.emit(this._value as T);
	}

	get value(): T | null {
		return !isNil(this._value) ? this._value as T : null;
	}

	/**
	 * @description Determines if the current value is null/blank
	 */
	get valueIsNull():boolean {
		return this.isNull(this.value);
	}

	/**
	 * @description Checks if the supplied value is considered 'nullly' with respect to the nullEquivilent
	 * @param {T|null} val 
	 * @returns 
	 */
	public isNull(val:T|null):boolean {
		return val === null
			|| val === undefined
			|| (this.nullEquivilent && val === this.nullEquivilent)
			|| ((typeof val === 'string' || val instanceof String) && val.length === 0);
	}

	// tslint:disable-next-line:variable-name
	public static ValidationMethods = {
		IsEmail: (value: String | null): boolean => {
			if (!value) return false;

			// tslint:disable-next-line:max-line-length
			const regExp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

			return (value.match(regExp) !== null);
		},
		IsIPv4: (value: String | null): boolean => {
			if (!value) return false;

			// tslint:disable-next-line:max-line-length
			const regExp = new RegExp(/^([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5])\.([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5])\.([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5])\.([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5])$/);

			return (value.match(regExp) !== null);
		},
		Match: (pattern: RegExp | string) => {
			return ((value: any | null) => {
				let evaluatedValue: any = value;

				if (value === null) evaluatedValue = '';

				const regExp = new RegExp(pattern);

				return (evaluatedValue.match(regExp) !== null);
			});
		},
		IsEqual: (otherField: FormField<any>) => {
			return ((value: any) => {
				return otherField.value === value;
			});
		},
		IsNotBlank: (value: String | number | null): boolean => {
			if (!value) return false;

			if (typeof value === 'string')
				return !!(value && value.length > 0);

			return typeof value === 'number';
		},
		IsBlank: (value: String | null): boolean => {
			return !FormField.ValidationMethods.IsNotBlank(value)
		},
		None: (value: String | null): boolean => true,

		IsNotNull: (value: boolean | String | number | null): boolean => {
			return value !== null
		}

	};

	public toRecordParam(name: string): Partial<IFormRecordPropertyParam | undefined> {
		switch (this.recordParamType) {
			case RecordParamType.String:
				const strVal = this.value as string | null;
				if (strVal && strVal !== null && strVal.length > 0) {
					return { name: name, stringData: strVal };
				}
				break;
			case RecordParamType.Number:
				const numVal = this.value as string | number | null;
				if (
					numVal && numVal !== null &&
					(
						(typeof numVal === 'string' && numVal.length > 0) ||
						(typeof numVal === 'number')
					)
				) {
					return { name: name, intData: Number(numVal) };
				}
				break;
			case RecordParamType.Enum:
				const enumVal = this.value as string | null;
				if (enumVal && enumVal !== null && enumVal.length > 0) {
					return { name: name, enumId: Number(enumVal) };
				}
				break;
			case RecordParamType.Boolean:
				const boolVal = this.value as Boolean | null;
				if (boolVal !== null) {
					return { name: name, intData: boolVal ? 1 : 0 };
				}
				break;
			case RecordParamType.JSON:
				const jsonVal = this.value as string | null;
				if (jsonVal && jsonVal !== null && jsonVal.length > 0) {
					return { name: name, jsonData: jsonVal };
				}
				break;
			case RecordParamType.Date:
				const dateStr = this.value as string | null;
				if (dateStr && dateStr !== null && dateStr.length > 0) {
					const dateData = moment(dateStr, 'DD-MM-YYYY').tz(environment.timeZone);
					return { name: name, dateData: dateData.toISOString() };
				}
				break;
		}

		return undefined;
	}

	public fromRecordParam(name: string, recordProperty: IRecordPropertyType): void {

		switch (this.recordParamType) {
			case RecordParamType.String:
				this.value = recordProperty.stringData as T | null;
				break;
			case RecordParamType.Number:
				this.value = recordProperty.intData as T | null;
				break;
			case RecordParamType.Enum:
				this.value = recordProperty.enumId as T | null;
				break;
			case RecordParamType.Boolean:
				this.value = ((recordProperty.intData || 0) > 0) as unknown as T | null;
				break;
			case RecordParamType.JSON:
				this.value = recordProperty.jsonData as T | null;
				break;
			case RecordParamType.Date:
				if (recordProperty.dateData)
					this.value = moment(recordProperty.dateData).tz(environment.timeZone).format('DD/MM/YYYY') as unknown as T | null;
				break;
		}

		return undefined;
	}
}

export enum RecordParamType {
	String = "string",
	Number = "number",
	Enum = "enum",
	Boolean = "boolean",
	JSON = "json",
	NULL = 'null',
	Date = 'date',
}
