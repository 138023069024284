import { Component, OnInit, Input, ViewEncapsulation } from "@angular/core";
import { Location } from "@angular/common";
import * as moment from 'moment';
import 'moment-timezone';
import { FormField, RecordParamType } from "../../../../../model/Form";
import { FormComponent } from "../../../../../model/FormComponent";
import { Session } from "../../../../../service/util/Session";
import { CurrentUserService } from "../../../../../service/currentUser/CurrentUserService";
import { FormService } from "../../../../../service/FormService";
import { FormCategory } from "../../../../../../model/Category";
import { CategoryService } from "../../../../../service/CategoryService";
import { FormRecordService } from "../../../../../service/FormRecordService";
import { IFormOutputModel, IFormRecordOutputModel } from "../../../../../../common/contracts/form";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { ICategoryOutputModel } from "../../../../../../common/contracts/category"
import { ErrorHandlerService } from "../../../../../service/ErrorHandlerService";
import { IDocumentType } from "../../../../../../common/contracts/document";
import { GroupsService } from "service/admin/GroupsService";
import { RiskService } from "service/RiskService";
import { environment } from "../../../../../environments/environment";
import { riskTypeMap, riskTypeOptions } from "../../../../../model/RiskType";

@Component({
	selector: 'risk-assessment-form-0',
	templateUrl: './riskAssessmentFormStage0.component.html',
	encapsulation: ViewEncapsulation.None,
})
export class RiskAssessmentFormStage0Component extends FormComponent implements OnInit {

	public currentFormStage = 0;

	// Existing Form Data
	@Input() readOnly: boolean = false;
	@Input() hideHeader: boolean = false;

	@Input() formData: IFormOutputModel;
	@Input() sequence: number | undefined;

	public formRecord: IFormRecordOutputModel | null;

	public formCategoryId: number;

	public documents: Array<IDocumentType> = [];

	public riskTypeOptions = riskTypeOptions;

	public form = {
		/** Required after Refactor */
		clientName: new FormField<String>('', {
			validation: FormField.ValidationMethods.None
		}),
		location: new FormField<String>('', {
			validation: FormField.ValidationMethods.IsNotBlank,
			recordParamType: RecordParamType.Number
		}),
		summary: new FormField<string>('', {
			validation: FormField.ValidationMethods.IsNotBlank
		}),
		riskType: new FormField<String>('1', {
			validation: FormField.ValidationMethods.IsNotBlank,
			onChange: (val => { this.configureValidation(); }).bind(this),
			recordParamType: RecordParamType.Number
		}),
		isExistingRisk: new FormField<boolean>(false, {
			validation: FormField.ValidationMethods.IsNotNull,
			onChange: (val => { this.configureValidation(); }).bind(this),
			recordParamType: RecordParamType.Boolean
		}),
		existingRisk: new FormField<number>(null, {
			validation: FormField.ValidationMethods.None,
			recordParamType: RecordParamType.Number
		}),
		initialAssessorDepartmentId: new FormField<number>(null, {
			validation: FormField.ValidationMethods.IsNotBlank,
			recordParamType: RecordParamType.Number
		}),
	};

	configureValidation(isDraft: boolean = false) {
		if (isDraft) {
			this.setFieldValidation(this.form.location, FormField.ValidationMethods.None);
			this.setFieldValidation(this.form.riskType, FormField.ValidationMethods.None);
			this.setFieldValidation(this.form.clientName, FormField.ValidationMethods.None);
			this.setFieldValidation(this.form.existingRisk, FormField.ValidationMethods.None);
			return;
		}

		if ((this.form.isExistingRisk as FormField<boolean>).value) {
			this.setFieldValidation(this.form.location, FormField.ValidationMethods.None);
			this.setFieldValidation(this.form.riskType, FormField.ValidationMethods.None);
			this.setFieldValidation(this.form.clientName, FormField.ValidationMethods.None);
			this.setFieldValidation(this.form.existingRisk, FormField.ValidationMethods.IsNotBlank);
		} else {
			if (this.isResidentRisk()) {
				this.setFieldValidation(this.form.clientName, FormField.ValidationMethods.IsNotBlank);
			} else {
				this.setFieldValidation(this.form.clientName, FormField.ValidationMethods.None);
			}

			this.setFieldValidation(this.form.location, FormField.ValidationMethods.IsNotBlank);
			this.setFieldValidation(this.form.riskType, FormField.ValidationMethods.IsNotBlank);
			this.setFieldValidation(this.form.existingRisk, FormField.ValidationMethods.IsBlank);
		}
	}

	constructor(
		public session: Session,
		public currentUserService: CurrentUserService,
		public formService: FormService,
		public categoryService: CategoryService,
		public formRecordService: FormRecordService,
		public router: Router,
		private errorHandler: ErrorHandlerService,
		location: Location,
		public groupsService: GroupsService,
		public riskService: RiskService
	) {
		super(location);
	}

	registerFormFields() {
		this.formFields.push(...Object.keys(this.form).map((k: string) => this.form[k]));
	}

	beforeSubmit(draft: boolean = false) {
		this.configureValidation(draft);
		if (this.form.isExistingRisk.value && this.form.existingRisk.value) {
			this.riskService.getRiskFromId(this.form.existingRisk.value).subscribe(risk => {
				// TODO: Set the risk type prop to the value from the risk as well
				this.form.summary.value = risk.summary;
				this.submit(draft);
			});
		} else {
			this.form.isExistingRisk.value = false;
			this.form.existingRisk.value = null;

			this.submit(draft);
		}
	}

	onSubmit(isDraft: boolean = false) {
		this.session.lockInput(() => {
			return new Promise<void>(async (resolve, reject) => {
				const formLocationId = this.positiveValidInt(this.form.location);
				let assignedUserId: number | null = this.currentUserService.userData && isDraft ? this.currentUserService.userData.id : null;

				/**
				 * Destination Stages
				 * Stage 0: Draft
				 * Stage 1: Assessment
				 */

				let stage: number = 0;
				let userGroupId: number | null = null;

				/**
				 * Append a userGroupId property to indicate where this form should go after it is complete, or if it is an existing risk
				 */
				const finalUserGroupId: number | null = await this.groupsService.getGroupIdByName(environment.defaultRiskGroupName);

				if (!isDraft) {
					stage = 1;

					if (this.form.isExistingRisk.value && this.form.existingRisk.value) {
						userGroupId = finalUserGroupId;
					} else {
						const initialDeptId = this.positiveValidInt(this.form.initialAssessorDepartmentId);

						if (initialDeptId) {
							userGroupId = initialDeptId;
						}
					}
				}

				let notifyOnComplete: string | null = null;
				let formFunc: Observable<any>;

				if (!this.isNewForm(this.formData)) {
					//FormFunc = update
					formFunc = this.formService.updateForm({
						id: this.formData.id,
						formLocationId,
						userGroupId,
						notifyOnComplete,
						stage,
						assignedUserId
					});
				} else {
					// The actual due date is 21 days however we want this to show up red on the due date
					let dueAt = moment().tz(environment.timeZone).add(14, 'days').set({
						'hour': 0,
						'minute': 0,
						'second': 0
					}).toISOString(false);

					// This should be back to the client within 14 days
					let alertAt = moment().tz(environment.timeZone).add(7, 'days').set({
						'hour': 0,
						'minute': 0,
						'second': 0
					}).toISOString(false);

					formFunc = this.formService.createForm({
						categoryId: this.formCategoryId,
						formLocationId,
						userGroupId,
						dueAt,
						alertAt,
						notifyOnComplete,
						stage,
						parentFormId: null,
						assignedUserId,
						summary: this.form.summary.value,
					})
				}

				formFunc.subscribe(async (data: any) => {
					const formId: number = (this.formData && this.formData.id) ? this.formData.id : data['id'];
					const properties = this.toRecordParams(this.form);

					if (finalUserGroupId) {
						properties.push({
							name: 'userGroupId',
							intData: finalUserGroupId
						});
					}

					this.formRecordService.createRecord({
						formId: formId,
						properties: properties as any,
						stage: this.currentFormStage,
						documents: this.documents.map(doc => ({ id: doc.id, isTicked: !!doc.isTicked })), // send list of attached documents
						isComplete: !isDraft
					})
						.subscribe((data: any) => {
							//Done creating the form and appending its properties
							resolve();

							this.session.requestPrevPage.next({ defaultUrl: '/ram-dashboard', filter: 'dashboard' });
						}, err => {
							this.errorHandler.handleHttpError(err);
							reject("Error creating new record");
						});
				}, (err) => {
					this.errorHandler.handleHttpError(err);
					reject("Error Creating or updating form");
				});
			});
		});
	}

	ngOnInit() {
		this.registerFormFields();

		this.loadEnums();

		this.repopulateFormFromData();
	}

	private repopulateFormFromData() {
		const lastSubmission = this.getMostRecentSubmission(this.formData, true, this.currentFormStage, this.sequence);

		// Do not recall the last submission if it is complete but not specifically requested
		if (!lastSubmission || (!this.sequence && lastSubmission.isComplete)) {
			return;
		}

		this.formRecord = lastSubmission;

		this.updateFromRecordParams(this.form, this.formRecord);

		// get attached documents
		this.documents = this.initTickedDocuments(this.formRecord.documents, this.formRecord.tickedDocuments);
	}

	private loadEnums() {
		this.session.lockInputRx(this.categoryService.getCategories())
			.subscribe((data: ICategoryOutputModel[]) => {

				let reportCategory: ICategoryOutputModel | undefined = data.find((data: FormCategory) => !!data.name.match(/^RAM-Task/));

				if (reportCategory)
					this.formCategoryId = reportCategory.id as number;
				//else
				//Notify the user an error has occurred, as we can't find the right form category

			}, (err) => {
				console.error('Error getting enums', err);
				this.errorHandler.handleHttpError(err);
			});
	}

	/**
	 * @description Checks if the current risk is a resident type risk. May be one or more.
	 * @returns {boolean}
	 */
	isResidentRisk(): boolean {
		if (!this.form.riskType.value) return false;

		if (riskTypeMap[this.form.riskType.value.toString()].match(/^Resident/)) {
			return true;
		}

		return false;
	}

	/**
	 * @description Checks if the current risk is a clinical type risk. May be one or more, and account for "non-clicnical"
	 * @returns {boolean}
	 */
	isClinicalRisk(): boolean {
		if (!this.form.riskType.value) return false;

		if (riskTypeMap[this.form.riskType.value.toString()].match(/\sClinical Risk$/)) {
			return true;
		}

		return false;
	}
}
