<div class="row mb-4">
    <div class="col-12 col-md-4 col-lg-3 col-xl-2 p-0">
        <nav class="nav flex-column bg-white border border-light rounded h-100">
            <a class="nav-link" [class.active]="isActive('/dashboard')" [routerLink]="['/dashboard']" [queryParamsHandling]=""><i class="fa fa-home"></i> Dashboard</a>
			<a class="nav-link" [class.active]="isActive('/drm-dashboard')" [routerLink]="['/drm-dashboard']" [queryParamsHandling]="" *ngIf="false">DRM Dashboard</a>

            <a class="nav-link" [class.active]="isActive('/report')" [routerLink]="['/report']"><i class="fa fa-file"></i> New Feedback</a>
            <a class="nav-link" [class.active]="isActive('/followUp')" [routerLink]="['/followUp']"><i class="fa fa-clipboard"></i> New Follow-Up</a>

            <a class="nav-link" [class.active]="isActive('/audit')" [routerLink]="['/audit']" *ngIf="false">New Audit</a>
			<a class="nav-link" [class.active]="isActive('/document-collections')" [routerLink]="['/document-collections']" *ngIf="false">Document Collections</a>

            <a class="nav-link hover-cursor" (click)="riskSectionActive=!riskSectionActive"><i class="fa fa-file-text"></i> Risk Assessment</a>
			<a class="nav-link indent" [class.active]="isActive('/ram-dashboard')" [routerLink]="['/ram-dashboard']" [queryParamsHandling]="" *ngIf="riskSectionActive"><i class="fa fa-file"></i> RAM Dashboard</a>
			<a class="nav-link indent" [class.active]="isActive('/risks')" [routerLink]="['/risks']" [queryParamsHandling]="" *ngIf="riskSectionActive"><i class="fa fa-list-ul"></i> Risks</a>
			<a class="nav-link indent" [class.active]="isActive('/risk-assessment-task')" [routerLink]="['/risk-assessment-task']" *ngIf="navOptions.newRiskAssessment && riskSectionActive"><i class="fa fa-thumb-tack"></i> New Risk Assessment</a>
            
            <ng-container *ngIf="currentUserService.isAdministrator | async">
                <a class="nav-link hover-cursor" (click)="adminSectionActive=!adminSectionActive"><i class="fa fa-user-circle"></i> Admin</a>
                <a class="nav-link indent" [class.active]="isActive('/admin/users')" [routerLink]="['/admin/users']" *ngIf="adminSectionActive"><i class="fa fa-user"></i> Users</a>
                <a class="nav-link indent" [class.active]="isActive('/admin/groups')" [routerLink]="['/admin/groups']" *ngIf="adminSectionActive"><i class="fa fa-users"></i> Groups</a>
                <a class="nav-link indent" [class.active]="isActive('/admin/locations')" [routerLink]="['/admin/locations']" *ngIf="adminSectionActive"><i class="fa fa-map-marker"></i> Locations</a>
                <a class="nav-link indent" [class.active]="isActive('/admin/enums')" [routerLink]="['/admin/enums']" *ngIf="adminSectionActive"><i class="fa fa-list"></i> Enums</a>
                <a class="nav-link indent" [class.active]="isActive('/admin/options')" [routerLink]="['/admin/options']" *ngIf="adminSectionActive"><i class="fa fa-cogs"></i> Options</a>
                <a class="nav-link indent" [class.active]="isActive('/admin/templates')" [routerLink]="['/admin/templates']" *ngIf="false">Templates</a>
                <a class="nav-link indent" [class.active]="isActive('/admin/resource-pack')" [routerLink]="['/admin/resource-pack']" *ngIf="adminSectionActive"><i class="fa fa-folder"></i> Resource Pack</a>
                <a class="nav-link indent" [class.active]="isActive('/admin/tags')" [routerLink]="['/admin/tags']" *ngIf="adminSectionActive"><i class="fa fa-tags"></i> Tags &amp; Meta Data</a>
            </ng-container>

        </nav>
    </div>
    <div class="col-12 col-md-8 col-lg-9 col-xl-10 p-2">
        <div class="bg-white p-2 border border-light rounded">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
