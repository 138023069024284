import { Component, OnInit, Input, ViewEncapsulation } from "@angular/core";
import { Location } from "@angular/common";
import * as moment from 'moment';
import 'moment-timezone';
import { FormField } from "../../../../../model/Form";
import { FormComponent } from "../../../../../model/FormComponent";
import { Session } from "../../../../../service/util/Session";
import { CurrentUserService } from "../../../../../service/currentUser/CurrentUserService";
import { FormService } from "../../../../../service/FormService";
import { FormCategory } from "../../../../../../model/Category";
import { CategoryService } from "../../../../../service/CategoryService";
import { FormRecordService } from "../../../../../service/FormRecordService";
import {
	IFormOutputModel,
	IFormRecordOutputModel,
	IFormRecordPropertyParam,
} from "../../../../../../common/contracts/form";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { ICategoryOutputModel } from "../../../../../../common/contracts/category"
import { ErrorHandlerService } from "../../../../../service/ErrorHandlerService";
import { IDocumentType } from "../../../../../../common/contracts/document";
import { DocumentIntanceService } from "service/admin/DocumentInstanceService";
import { saveAs } from 'file-saver'
import { DocumentService } from "service/DocumentService";
import { ModalService } from "service/ModalService";
import { DocumentsService } from "service/admin/DocumentsService";
import { environment } from "../../../../../environments/environment";

@Component({
	selector: 'document-review-form-0',
	templateUrl: './documentReviewFormStage0.component.html',
	encapsulation: ViewEncapsulation.None,
})
export class DocumentReviewFormStage0Component extends FormComponent implements OnInit {

	// Existing Form Data
	@Input() readOnly: boolean = false;
	@Input() hideHeader: boolean = false;

	@Input() formData: IFormOutputModel;
	@Input() sequence: number;
	@Input() departmentId: number;
	@Input() documentId: number;

	public formRecord: IFormRecordOutputModel;

	public dateString: string;
	public formCategoryId: number;

	public documents: Array<IDocumentType> = [];

	/*
	  This should have been done properly using something that implements FormControl but its
	  too late now
	 */
	public form: { [key: string]: FormField<any> } = {
		department: new FormField<String>('', {
			validation: FormField.ValidationMethods.IsNotBlank
		}),
		summary: new FormField<String>('', {
			validation: FormField.ValidationMethods.IsNotBlank
		}),
		document: new FormField<String>('', {
			validation: FormField.ValidationMethods.IsNotBlank
		}),
	};
	latestAprovedVersion: any;
	latestDraftRecord: any;
	uploadedDocument: Array<IDocumentType> = [];
	hasDraftFromCompletedTasks: boolean = false;
	isAdminOrManager: boolean = this.currentUserService.isAdministrator.getValue() || this.currentUserService.userData!.role === 'manager';

	constructor(
		public session: Session,
		public currentUserService: CurrentUserService,
		public formService: FormService,
		public categoryService: CategoryService,
		public formRecordService: FormRecordService,
		public router: Router,
		private errorHandler: ErrorHandlerService,
		public documentInstanceService: DocumentIntanceService,
		public documentService: DocumentService,
		public documentDRMService: DocumentsService,
		location: Location,
		public modalService: ModalService,
	) {
		super(location);
	}

	registerFormFields() {
		this.formFields.push(...Object.keys(this.form).map((k: string) => this.form[k]));
	}

	onSubmit(isDraft: boolean = false) {
		const isLatestAprovedVersionEmpty = !this.latestAprovedVersion;

		if (isLatestAprovedVersionEmpty && this.uploadedDocument.length === 0) {
			this.modalService.alert({
				title: 'Alert',
				message: 'Document has no aproved versions, please upload a draft to continue.'
			});
			return;
		}
		if (this.uploadedDocument.length === 0) {
			this.modalService.confirmRx({
				title: 'Warning',
				message: 'No files uploaded. Latest draft will be used. Continue?'
			}).subscribe(isConfirmed => {
				if (isConfirmed) {
					/**
					 * Ideally this should be done by copying the latest version and 
					 * creating the new draft and form record. In order to make the 
					 * code flow simple and clear we are downloading the latest version
					 * and uploading it again as a draft. 
					 */

					this.documentInstanceService.downloadDraftDocument(this.latestAprovedVersion.id).subscribe(blob => {
						const fileObject = new File([blob], this.latestAprovedVersion.originalFileName);

						this.documentService.uploadDocument(fileObject).subscribe((newDocument: IDocumentType) => {

							this.documents = [];
							this.documents.push(newDocument);
							this.submitForm(isDraft);
						});
					})
				}
			});
		} else {
			this.submitForm(isDraft);
		}
	}
	submitForm(isDraft) {
		return new Promise((resolve, reject) => {

			let userGroupId: number | null = this.form.department.isValid ? Number(this.form.department.value) : null;
			let stage: number = isDraft ? 0 : 1;

			let formFunc: Observable<any>;

			// The assigned user should always be reset to null on submission of stage 0
			let assignedUserId: number | null = null;

			if (this.currentUserService.userData && isDraft)
				assignedUserId = this.currentUserService.userData.id;

			const isNewForm: boolean = !(this.formData && this.formData.id);

			/* NOTE : The assigned userID should be set to null if this is not a draft */

			if (!isNewForm) {
				//FormFunc = update
				formFunc = this.formService.updateForm({
					id: this.formData.id,
					userGroupId,
					stage,
					assignedUserId
				});
			} else {
				// The actual due date is 21 days however we want this to show up red on the due date
				let dueAt = moment().tz(environment.timeZone).add(5, 'weeks').set({
					'hour': 0,
					'minute': 0,
					'second': 0
				}).toISOString(false);

				// This should be back to the client within 14 days
				let alertAt = moment().tz(environment.timeZone).add(4, 'weeks').set({
					'hour': 0,
					'minute': 0,
					'second': 0
				}).toISOString(false);

				formFunc = this.formService.createForm({
					categoryId: this.formCategoryId,
					formLocationId: null,
					userGroupId,
					dueAt,
					alertAt,
					stage,
					parentFormId: null,
					assignedUserId,
					notifyOnComplete: null
				});
			}

			formFunc.subscribe((data: any) => {

				let properties: Partial<IFormRecordPropertyParam>[] = [];
				let formId: number = (this.formData && this.formData.id) ? this.formData.id : data['id'];
				let formCreatedBy: number = (this.formData && this.formData.createdById) ? this.formData.createdById : data['createdById'];

				if (userGroupId)
					properties.push({
						name: "userGroupId",
						intData: userGroupId
					});

				// This desperately needs to be refactored but in the interest of brevity...

				properties.push({
					name: "summary",
					stringData: this.form.summary.value
				});

				properties.push({
					name: "documentId",
					stringData: this.form.document.value
				});

				// TODO: Add issue number

				if (this.uploadedDocument.length === 0) {
					this.uploadedDocument.push(this.documents[this.documents.length - 1]);
				}

				this.formRecordService.createRecord({
					formId: formId,
					// Intentionally cast the properties object since we know its correct
					properties: properties as any,
					stage: 0,
					documents: this.uploadedDocument.map(doc => ({ id: doc.id, isTicked: !!doc.isTicked })), // send list of attached documents
					isComplete: !isDraft
				})
					.subscribe((data: any) => {
						//Done creating the form and appending its properties
						if (!isDraft) {
							let params = {
								userGroupId: userGroupId,
								stage: 1,
								createdUserId: formCreatedBy,
								documentId: this.documentId,
								completedBy: this.currentUserService.userData ? this.currentUserService.userData.id : null
							}
							this.formService.sendReviewFormNotifications(params).subscribe();
						}
						resolve();

						if (isNewForm) {
							// go to dashboard
							this.router.navigate(['/drm-dashboard']);
						} else {
							this.goBack();
						}

					}, err => {
						this.errorHandler.handleHttpError(err);
						reject("Error creating new record");
					});
			}, (err) => {
				this.errorHandler.handleHttpError(err);
				reject("Error Creating or updating form");
			});
		})
	}
	ngOnInit() {
		this.dateString = moment().tz(environment.timeZone).format(this.dateFormat);

		this.form.department.value = this.departmentId ? this.departmentId.toString() : "";

		this.registerFormFields();

		this.loadEnums();

		this.repopulateFormFromData();

		this.getLatestAprovedDocument();
		this.getLatestDraft();

		this.populateUploadedDocumentsInCurrentStage(this.readOnly);

		this.form.document.value = this.documentId ? this.documentId.toString() : "";
	}

	populateUploadedDocumentsInCurrentStage(readOnly: boolean) {
		if (!this.formData) {
			return;
		}
		if (!readOnly && this.formData) {
			const completeRecords = this.formData.records.filter(record => (record.stage === 0 && record.documents.length > 0))
			if (completeRecords.length === 0) {
				return;
			}
			let documents = completeRecords[completeRecords.length - 1].documents;
			let uploadedDocument = documents[documents.length - 1];
			this.uploadedDocument.push(uploadedDocument);
			return;
		}
		const completeRecords = this.formData.records.filter(record => (record.stage === 0 && record.isComplete && record.documents.length > 0))
		if (completeRecords.length === 0) {
			return;
		}
		let documents = completeRecords[0].documents;
		let uploadedDocument = documents[documents.length - 1];
		this.uploadedDocument.push(uploadedDocument);
	}

	private repopulateFormFromData() {
		if (!this.formData || !this.formData.records || !this.formData.records.length)
			return;

		let stageRecords = this.formData.records.filter(record => record.stage === 0);
		if (stageRecords.length === 0)
			return;

		if (!this.sequence) {
			let mostRecentRecord = stageRecords.sort((a, b) => a.sequence > b.sequence ? 1 : -1).pop();

			if (!mostRecentRecord)
				throw new Error("internal error");

			// If the most recent record was a submission, we are not going to use it
			if (mostRecentRecord.isComplete)
				return;

			this.formRecord = mostRecentRecord;
		} else {
			let targetRecord = stageRecords.find(record => record.sequence === this.sequence);

			if (!targetRecord)
				throw new Error("internal error");

			this.formRecord = targetRecord;
		}

		/**
		 * get attached documents
		 */
		this.documents = this.initTickedDocuments(this.formRecord.documents, this.formRecord.tickedDocuments);

		/**
		 * show update date from record's createdAt
		 */
		this.dateString = moment(this.formRecord.createdAt).tz(environment.timeZone).format(this.dateFormat);


		//Convert the properties into easily accessible IFormRecordPropertyParam
		if (!this.formRecord.properties) {
			return;
		}

		let simpleProperties: { [key: string]: IFormRecordPropertyParam } = {};

		this.formRecord.properties.forEach(recordProperty => {
			//eject invalid property
			if (!recordProperty.property)
				return;

			let result: Partial<IFormRecordPropertyParam> = {
				name: recordProperty.property.name
			};

			if (recordProperty.stringData)
				result.stringData = recordProperty.stringData;

			if (recordProperty.intData)
				result.intData = recordProperty.intData;

			if (recordProperty.jsonData)
				result.jsonData = recordProperty.jsonData;

			if (recordProperty.enumId)
				result.enumId = recordProperty.enumId;

			simpleProperties[result.name as string] = result as IFormRecordPropertyParam;
		});

		if (simpleProperties['userGroupId'])
			this.form.department.value = String(simpleProperties['userGroupId'].intData);
		else if (this.formData['userGroupId'])
			this.form.department.value = String(this.formData['userGroupId']);

		if (this.formData['formLocationId'])
			this.form.location.value = String(this.formData['formLocationId']);

		//Repopulate from the simpleProperties

		if (simpleProperties['summary'])
			this.form.summary.value = simpleProperties['summary'].stringData;

	}

	private loadEnums() {
		this.session.lockInputRx(this.categoryService.getCategories())
			.subscribe((data: ICategoryOutputModel[]) => {

				let reportCategory: ICategoryOutputModel | undefined = data.find((data: FormCategory) => !!data.name.match(/^DRM-Task/));

				if (reportCategory)
					this.formCategoryId = reportCategory.id as number;
				//else
				//Notify the user an error has occurred, as we can't find the right form category

			}, (err) => {
				console.error('Error getting enums', err);
				this.errorHandler.handleHttpError(err);
			});
	}
	public getLatestDraft() {
		this.formService.getFormsFromDocumentId(this.documentId).subscribe(data => {
			if (data.length === 0) {
				this.documentInstanceService.getDraftDocument(this.documentId).subscribe(data => {
					if (data) {
						this.latestDraftRecord = data;
						this.hasDraftFromCompletedTasks = true;
					} else {
						this.latestDraftRecord = null;
					}
				});
			} else {
				const formsCreatedAtArray = data.map(form => new Date(form.createdAt)),
					latestFormCreatedAt = new Date(Math.max.apply(null, formsCreatedAtArray)),
					latestFormRecords = data.find(form => (new Date(form.createdAt).getTime() === latestFormCreatedAt.getTime())).records,
					latestRecord = latestFormRecords.find(record => new Date(record.createdAt).getTime() === new Date(Math.max.apply(null, latestFormRecords.filter(record => !!record.documents.length).map(record => new Date(record.createdAt)))).getTime())

				this.latestDraftRecord = latestRecord;
			}
		});
	}

	public downloadLatestDraft() {
		if (this.latestDraftRecord === null) {
			this.downloadLatestAprovedVersion();
		} else if (this.hasDraftFromCompletedTasks && this.latestDraftRecord) {
			this.documentInstanceService.downloadDraftDocument(this.latestDraftRecord.id).subscribe(data => {
				saveAs(data, this.latestDraftRecord.originalFileName);
			})
		} else {
			this.documentService.downloadDocument(this.latestDraftRecord.documents[0].id).subscribe(file => {
				saveAs(file, this.latestDraftRecord.documents[0].fileName)
			});
		}
	}

	private getLatestAprovedDocument() {
		if (this.formData) {
			const formPropertiesArray = this.formData.records.map(record => record.properties),
				flatenedFormPropertiesArray = [].concat.apply([], formPropertiesArray),
				documentIdProperty = flatenedFormPropertiesArray.find(property => property.property.name === "documentId");
			this.documentId = documentIdProperty.stringData;

		}

		this.documentDRMService.getDocumentInstanceByDocumentId(this.documentId).subscribe(documentInstance => {
			this.latestAprovedVersion = documentInstance;
		});
	}

	public downloadLatestAprovedVersion() {
		this.documentInstanceService.downloadDocument(this.latestAprovedVersion.id).subscribe(data => {
			saveAs(data, this.latestAprovedVersion.fileName);
		});
	}
}
