import { Injectable, Directive, TemplateRef } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Observable, from, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { CronOptions as CronEditorOptions } from '../../../vendor/cron-editor/src/lib/CronOptions';
import { FormField } from 'model/Form';
import { IScheduleParams } from '../../common/contracts/form';

const defaultCronEditorOptions: CronEditorOptions = {
	formInputClass: 'form-control cron-editor-input',
	formSelectClass: 'form-control cron-editor-select',
	formRadioClass: 'cron-editor-radio',
	formCheckboxClass: 'cron-editor-checkbox',

	defaultTime: '10:00:00',
	use24HourTime: true,

	hideMinutesTab: true,
	hideHourlyTab: true,
	hideDailyTab: false,
	hideWeeklyTab: false,
	hideMonthlyTab: false,
	hideYearlyTab: false,
	hideAdvancedTab: true,

	hideSeconds: true,
	removeSeconds: true,
	removeYears: true
}

/**
 * Options passed when opening a confirmation modal
 */
export interface ConfirmOptions {
	/**
	 * The title of the confirmation modal
	 */
	title: string,
	/**
	 * The message in the confirmation modal
	 */
	message: string,

	confirmText?: string,

	declineText?: string,
}
/**
 * 
 * Options passed when opening a cron modal
 */
export interface CronOptions {
	/** The title of the confirmation modal */
	title: string;

	/** Value */
	expression?: string;

	/** Editor Config */
	editor?: CronEditorOptions;

	/** The date field which the start date should be inserted */
	startDateField: FormField<string>;
}

/**
 * Options passed when opening a alert modal
 */
export interface AlertOptions {
	/**
	 * The title of the alert modal
	 */
	title: string,
	/**
	 * The message in the alert modal
	 */
	message: string
}

export interface DateSelectModalOptions {
	title: string,
	label: string,
	defaultValue: string,
	submitText?: string,
	declineText?: string,
	includeTime?: boolean,
	defaultHours?: string,
	defaultMinutes?: string
}

/**
 * An internal service allowing to access, from the confirm modal component, the options and the modal reference.
 * It also allows registering the TemplateRef containing the confirm modal component.
 *
 * It must be declared in the providers of the NgModule, but is not supposed to be used in application code
 */
@Injectable()
export class ConfirmModalState {
	/**
	 * The last options passed ConfirmService.confirm()
	 */
	options: ConfirmOptions;
	/**
	 * The last opened confirmation modal
	 */
	modal: NgbModalRef;
	/**
	 * The template containing the confirmation modal component
	 */
	template: TemplateRef<any>;
}

@Injectable()
export class CronModalState {
	/**
	 * The last options passed ConfirmService.confirm()
	 */
	options: CronOptions;
	/**
	 * The last opened confirmation modal
	 */
	modal: NgbModalRef;
	/**
	 * The template containing the confirmation modal component
	 */
	template: TemplateRef<any>;
}

@Injectable()
export class AlertModalState {

	options: AlertOptions;

	modal: NgbModalRef;

	template: TemplateRef<any>;
}

@Injectable()
export class DateSelectModalState {
	options: DateSelectModalOptions;
	modal: NgbModalRef;
	template: TemplateRef<any>;
}

@Injectable()
export class RiskModalState {
	modal: NgbModalRef;
	template: TemplateRef<any>;
}

/**
 * A modal service, allowing to open a confirmation or alert modal from anywhere and get back a promise.
 */
@Injectable()
export class ModalService {
	constructor(
		private modalService: NgbModal,
		private confirmModalState: ConfirmModalState,
		private dateSelectModalState: DateSelectModalState,
		private alertModalState: AlertModalState,
		private riskModalState: RiskModalState,
		private cronModalState: CronModalState) { }
	/**
	 * Opens a confirmation modal
	 * @param options the options for the modal (title and message)
	 * @returns {Promise<any>} a promise that is fulfilled when the user chooses to confirm, and rejected when
	 * the user chooses not to confirm, or closes the modal
	 */
	cronRx(options: CronOptions): Observable<IScheduleParams | null> {

		// Editor Options as a New Object
		options.editor = Object.assign(options.editor || {}, defaultCronEditorOptions);
		// Default Expression
		if (!options.expression)
			options.expression = "0 0 ? 1/3 MON#1";

		this.cronModalState.options = options;
		this.cronModalState.modal = this.modalService.open(this.cronModalState.template, {
			size: 'lg'
		});

		return from(this.cronModalState.modal.result).pipe(
			catchError(() => of(null))
		);
	}

	confirm(options: ConfirmOptions): Promise<any> {
		this.confirmModalState.options = options;
		this.confirmModalState.modal = this.modalService.open(this.confirmModalState.template);
		return this.confirmModalState.modal.result;
	}

	confirmRx(options: ConfirmOptions): Observable<boolean> {
		this.confirmModalState.options = options;
		this.confirmModalState.modal = this.modalService.open(this.confirmModalState.template);
		return from(this.confirmModalState.modal.result).pipe(
			map(res => res === 'confirmed'),
			catchError(() => of(false))
		);
	}

	alert(options: AlertOptions): Promise<any> {
		this.alertModalState.options = options;
		this.alertModalState.modal = this.modalService.open(this.alertModalState.template);
		return this.alertModalState.modal.result.catch(() => { });
	}

	selectDate(options: DateSelectModalOptions): Promise<{ date: string, time?: { hours: number, minutes: number } }> {
		this.dateSelectModalState.options = options;
		this.dateSelectModalState.modal = this.modalService.open(this.dateSelectModalState.template);
		return this.dateSelectModalState.modal.result;
	}

	risk(): Promise<void> {
		this.riskModalState.modal = this.modalService.open(this.riskModalState.template, {
			size: 'lg'
		});
		return this.riskModalState.modal.result;
	}

}

/**
 * Directive allowing to get a reference to the template containing the confirmation modal component,
 * and to store it into the internal confirm state service. Somewhere in the view, there must be
 *
 * ```
 * <ng-template confirmModal>
 *   <confirm-modal-component></confirm-modal-component>
 * </template>
 * ```
 */
@Directive({
	selector: "ng-template[cronModal]",
})
export class CronTemplateDirective {
	constructor(modalTemplate: TemplateRef<any>, state: CronModalState) {
		state.template = modalTemplate;
	}
}

@Directive({
	selector: "ng-template[confirmModal]"
})
export class ConfirmTemplateDirective {
	constructor(modalTemplate: TemplateRef<any>, state: ConfirmModalState) {
		state.template = modalTemplate;
	}
}

@Directive({
	selector: "ng-template[alertModal]"
})
export class AlertTemplateDirective {
	constructor(modalTemplate: TemplateRef<any>, state: AlertModalState) {
		state.template = modalTemplate;
	}
}

@Directive({
	selector: "ng-template[dateSelectModal]"
})
export class DateSelectModalTemplateDirective {
	constructor(modalTemplate: TemplateRef<any>, state: DateSelectModalState) {
		state.template = modalTemplate;
	}
}

@Directive({
	selector: "ng-template[riskModal]"
})
export class RiskModalTemplateDirective {
	constructor(modalTemplate: TemplateRef<any>, state: RiskModalState) {
		state.template = modalTemplate;
	}
}

