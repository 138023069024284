<div class="row" *ngIf="!hideHeader">
    <div class="col-12 col-lg-6">
        <img src="assets/images/logo.svg" class="logoImg">
    </div>
    <div class="col-12 pt-2 pt-lg-0 col-lg-6 text-center">
        <div class="w-100 border border-dark rounded border-dashed d-inline-block float-lg-right py-3 px-5">
            <div class="row">
                <div class="col-12">
                    <strong class="font-weight-heavy">Issue Number:</strong>
                    {{ formData ? formData.issueNumber || "TBA" : "TBA" }}
                </div>
                <div class="col-12">
                    <strong class="font-weight-heavy">Document ID:</strong>
                    {{ documentId ? documentId || "TBA" : "TBA" }}
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-12">
                    <strong class="font-weight-heavy">Date Created:</strong> {{dateString}}
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-12 text-center col-lg-7 col-xl-6 col-xxl-5 text-lg-left">
        <h1 class="text-uppercase text-orange" *ngIf="!hideHeader">Review Task</h1>
        <h1 class="text-uppercase text-orange" *ngIf="hideHeader">Review Task Record</h1>
    </div>
    <div class="col-12 col-lg-5 col-xl-6 col-xxl-7">
        <hr class="border border-grey border-5 line bw-4 my-4" />
    </div>
</div>
<form>
    <div class="row">
        <div class="col-12 col-lg-4">
            <div class="form-group">
                <label>Department:</label>
                <user-group-select [field]="form.department" [disabled]="readOnly"></user-group-select>
                <small class="form-text text-danger" *ngIf="form.department.showErrorHelp()">Invalid Department</small>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-lg-8 border-lg-right border-dashed">
            <div class="row my-3">
                <div class="col-12">
                    <span>Notes:</span>
                </div>
            </div>
            <div class="row my-3">
                <div class="col-12">
                    <textarea
                        class="form-control {{!readOnly && form.summary.iifIsValid('border-success','border-danger')}}"
                        placeholder="Summary" autosize replaceAmpersand [minRows]="3" [spellcheck]="true"
                        [value]="'Document is ready for review.'" [(ngModel)]="form.summary.value"
                        [ngModelOptions]="{standalone: true}" [disabled]="readOnly"></textarea>
                    <small class="form-text text-danger" *ngIf="form.summary.showErrorHelp()">Invalid Summary</small>
                </div>
            </div>
        </div>
        <div class="col-12 offset-0 col-lg-4 pt-0 pt-lg-4">
            <div class="row">
                <div class="col-12 offset-0 col-xl-10 offset-xl-1">
                    <button class="btn btn-orange rounded-0 btn-block my-2" (click)="downloadLatestAprovedVersion()"
                        *ngIf="!!latestAprovedVersion && !readOnly">
                        <i class="fa fa-download mx-1"></i>
                        <strong>Latest Approved Version</strong>
                    </button>
                    <button class="btn btn-orange rounded-0 btn-block my-2" (click)="downloadLatestDraft()"
                        *ngIf="(!!latestAprovedVersion || !!latestDraftRecord) && !readOnly">
                        <i class="fa fa-download mx-1"></i>
                        <strong>Latest Draft Version</strong>
                    </button>
                    <drm-document-uploads [(documents)]="uploadedDocument" [readOnly]="readOnly"
                        [defaultTickedState]="true"></drm-document-uploads>
                </div>
            </div>
        </div>
    </div>
    <submission-detail [formRecord]="formRecord"></submission-detail>
    <small class="form-text text-danger" *ngIf="!readOnly && !isAdminOrManager">You need admin/manager access to create
        a new review task.</small>
    <div class="row" *ngIf="!readOnly && isAdminOrManager">
        <div class="col-12 col-md-6 col-lg-4 col-xl-2 col-xxl-2">
            <button class="btn btn-block btn-lg btn-success rounded-0" (click)="submit(false)"><span
                    class="capitalize">Submit</span></button>
            <!-- <button class="btn btn-block btn-lg btn-success rounded-0" [routerLink]="'/document-review-task/1/'"><span class="capitalize">Submit</span></button> -->
        </div>
        <div class="col-12 pt-2 col-md-6 pt-md-0 col-lg-5 col-xl-3 col-xxl-2">
            <a class="btn btn-block btn-lg btn-primary rounded-0 text-center text-white" (click)="submit(true)"><span
                    class="capitalize">Save Draft</span></a>
        </div>
    </div>
</form>