import { Component, Input, ComponentFactoryResolver, ViewContainerRef, ViewChild, OnInit } from '@angular/core';
import { IFormOutputModel, IFormRecordOutputModel } from "../../../../../../common/contracts/form";
import { ReportFormStage0Component } from "../stage0/reportFormStage0.component";
import { ReportFormStage1Component } from "../stage1/reportFormStage1.component";
import { ReportFormStage2Component } from "../stage2/reportFormStage2.component";

const stageFormMap = {
	0: ReportFormStage0Component,
	1: ReportFormStage1Component,
	2: ReportFormStage2Component,
};

/*
  This is the final, read only state for all the stages
*/

@Component({
	selector: 'report-form-4',
	templateUrl: './reportFormStage4.component.html'
})
export class ReportFormStage4Component implements OnInit {
	// Existing Form Data
	@Input() public formData: IFormOutputModel;

	@ViewChild('vc', { read: ViewContainerRef }) vc: ViewContainerRef;

	public isReportEnabled = false;

	constructor(
		private factoryResolver: ComponentFactoryResolver,
	) { }

	ngOnInit() {
		this.addReadOnlyForms();
	}

	public addReadOnlyForms() {
		if (!this.formData || !this.formData.records)
			return;

		//Get completed Records
		this.formData.records
			.filter(record => record.isComplete)
			.forEach(record => this.addReadOnlyForm(record))
	}

	public addReadOnlyForm(record: IFormRecordOutputModel) {
		const factory = this.factoryResolver.resolveComponentFactory(stageFormMap[record.stage]);
		const component = this.vc.createComponent(factory);

		// @ts-ignore
		component.instance.readOnly = true;
		// @ts-ignore
		component.instance.formData = this.formData;
		// @ts-ignore
		component.instance.hideHeader = !(record.stage === 0 && this.formData.archivedAt);
		// @ts-ignore
		component.instance.sequence = record.sequence;

		this.vc.insert(component.hostView);

		this.isReportEnabled = true;
	}
}
