import { Component, OnInit, ViewChild } from '@angular/core';
import { FormService } from "../../../../service/FormService";
import { ActivatedRoute, Params, Data } from "@angular/router";
import { Session } from "../../../../service/util/Session";
import has = Reflect.has;
import { ErrorHandlerService } from "../../../../service/ErrorHandlerService";
import { ChildFormTreeNode, IFormOutputModel } from "../../../../../common/contracts/form";
import { ComponentCanDeactivate } from "../../../shared/canDeactivate/ComponentCanDeactivate";
import { AuditFormStage0Component } from "./stage0/auditFormStage0.component";
import { AuditFormStage1Component } from "./stage1/auditFormStage1.component";
import { AuditFormStage2Component } from './stage2/auditFormStage2.component';
import { zip } from 'rxjs';
import { map } from 'rxjs/operators';
// import {AuditFormStage3Component} from './stage3/auditFormStage3.component';

export { AuditFormStage0Component } from "./stage0/auditFormStage0.component";
export { AuditFormStage1Component } from "./stage1/auditFormStage1.component";
export { AuditFormStage2Component } from './stage2/auditFormStage2.component';
export { AuditFormStage3Component } from './stage3/auditFormStage3.component';

@Component({
  selector: 'audit-form',
  templateUrl: './auditForm.component.html',
  styleUrls: ['./auditForm.component.css'],
})
export class AuditFormComponent extends ComponentCanDeactivate implements OnInit {

  public targetStage: number;
  public showForm: boolean = false;
  public formData: IFormOutputModel | null = null;
  public childForms: ChildFormTreeNode[] | null = null;

  public isTemplate: boolean = false;

  @ViewChild('auditStage0') auditStage0Form: AuditFormStage0Component;
  @ViewChild('auditStage1') auditStage1Form: AuditFormStage1Component;
  @ViewChild('auditStage2') auditStage2Form: AuditFormStage2Component;

  constructor(
    public formService: FormService,
    public activatedRoute: ActivatedRoute,
    public session: Session,
    private errorHandler: ErrorHandlerService,
  ) {
    super();
  }

  canDeactivate(): boolean {
    if (!this.showForm) return true;

    if (this.formData && this.formData.isDeleted) {
      return true;
    }

    if (this.targetStage === 0)
      return this.auditStage0Form.canDeactivate();

    if (this.targetStage === 1)
      return this.auditStage1Form.canDeactivate();

    if (this.targetStage === 2)
      return this.auditStage1Form.canDeactivate();

    return true;
  }

  ngOnInit() {
    zip(
      this.activatedRoute.params,
      this.activatedRoute.data
    ).pipe(
      map(([params, data]) => {
        return { params, data };
      })
    )
      .subscribe((result: { params: Params, data: Data }) => {
        const { params, data } = result;

        if (data.isTemplate)
          this.isTemplate = data.isTemplate;

        if (!has(params, "reportId")) {
          this.showForm = true;
          this.targetStage = 0;
          this.formData = null;
          this.childForms = null;
          return;
        }

        this.session.lockInputRx(this.formService.getFormById(params["reportId"])).subscribe((form) => {
          this.formData = form;

          if (this.formData) {
            if (this.formData.records.length === 0) {
              this.errorHandler.raiseError("Record did not contain valid data. Please contact a system administrator for assistance.", "Invalid Form Data");
              return;
            }

            this.targetStage = this.formData!.stage || 0;
            this.showForm = true;

            if ((this.formData.archivedAt || this.isTemplate) && this.formData.childForms && this.formData.childForms.length) {
              this.childForms = this.formData.childForms;
            } else {
              this.childForms = null;
            }
          }

        }, err => {
          console.error('Error while getting audit form', err);
          this.errorHandler.handleHttpError(err);
        });
      });
  }

}
