var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from '@angular/core';
import { Location } from "@angular/common";
import * as moment from 'moment';
import "moment-timezone";
import { FormComponent } from "../../../../../model/FormComponent";
import { FormField } from "../../../../../model/Form";
import { Session } from "../../../../../service/util/Session";
import { CurrentUserService } from "../../../../../service/currentUser/CurrentUserService";
import { FormRecordService } from "../../../../../service/FormRecordService";
import { FormService } from "../../../../../service/FormService";
import { CategoryService } from "../../../../../service/CategoryService";
import { ErrorHandlerService } from "../../../../../service/ErrorHandlerService";
import { SettingsService } from "../../../../../service/admin/SettingsService";
var has = Reflect.has;
import { FollowUpWidgetComponent } from "../../../../shared/followUpWidget.component";
import { FollowUpService } from "../../../../../service/FollowUpService";
import { environment } from '../../../../../environments/environment';
var ReportFormStage2Component = /** @class */ (function (_super) {
    __extends(ReportFormStage2Component, _super);
    function ReportFormStage2Component(session, currentUserService, formRecordService, formService, categoryService, errorHandler, location, settingsService, followUpService) {
        var _this = _super.call(this, location) || this;
        _this.session = session;
        _this.currentUserService = currentUserService;
        _this.formRecordService = formRecordService;
        _this.formService = formService;
        _this.categoryService = categoryService;
        _this.errorHandler = errorHandler;
        _this.settingsService = settingsService;
        _this.followUpService = followUpService;
        _this.emailsEnabled = true;
        _this.readOnly = false;
        _this.hideHeader = false;
        /*
          This should have been done properly using something that implements FormControl but its
          too late now
         */
        _this.form = {
            reassign: new FormField(false, {
                validation: FormField.ValidationMethods.None
            }),
            //Since this will be assigned to a select it must be a string data - Conversion where appropriate
            reassignToUserId: new FormField('', {
                validation: FormField.ValidationMethods.None
            }),
            followUps: new FormField('[]', {
                nullEquivilent: "[]",
                validation: function (value) {
                    return _this.followUpWidgetRef ? _this.followUpWidgetRef.validate() : true;
                }
            }),
            summary: new FormField('', {
                validation: FormField.ValidationMethods.None
            }),
            notes: new FormField('', {
                validation: FormField.ValidationMethods.None
            }),
            emailOther: new FormField(false, {
                validation: FormField.ValidationMethods.None
            }),
            otherEmail: new FormField('', {
                validation: FormField.ValidationMethods.None
            }),
        };
        _this.documents = [];
        return _this;
    }
    ReportFormStage2Component.prototype.registerFormFields = function () {
        var _this = this;
        var _a;
        (_a = this.formFields).push.apply(_a, Object.keys(this.form).map(function (k) { return _this.form[k]; }));
    };
    ReportFormStage2Component.prototype.ngOnInit = function () {
        var _this = this;
        this.dateString = moment().tz(environment.timeZone).format(this.dateFormat);
        this.registerFormFields();
        this.repopulateFormFromData();
        // Load the category name
        this.categoryService.getCategoryIdByName('Follow-Up').subscribe(function (categoryId) {
            return _this.followUpFormCategory = categoryId;
        });
        this.loadSettings();
    };
    ReportFormStage2Component.prototype.loadSettings = function () {
        var _this = this;
        this.settingsService.getSettings().subscribe(function (data) {
            if (has(data, 'isEmailsEnabled')) {
                _this.emailsEnabled = data.isEmailsEnabled;
            }
        });
    };
    ReportFormStage2Component.prototype.onSubmit = function (isDraft) {
        var _this = this;
        this.session.lockInput(function () {
            return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
                var success, fail, stage, assignedUserId, userGroupId, properties, notifyOnComplete;
                var _this = this;
                return __generator(this, function (_a) {
                    success = function () {
                        resolve();
                        _this.goBack();
                    };
                    fail = function (msg, err) {
                        console.error(msg, err);
                        _this.errorHandler.handleHttpError(err);
                        reject();
                    };
                    stage = isDraft ? 2 : 4;
                    assignedUserId = null;
                    userGroupId = this.formData.userGroupId;
                    if (stage === 4) {
                        if (this.form.reassign.value && this.form.reassignToUserId.value) {
                            // We actually want to send it back 1 for more detail
                            stage = 1;
                            assignedUserId = Number(this.form.reassignToUserId.value);
                        }
                    }
                    properties = [];
                    notifyOnComplete = this.formData.notifyOnComplete;
                    if (this.form.otherEmail && this.form.otherEmail.value && this.form.otherEmail.value.length) {
                        if (notifyOnComplete)
                            notifyOnComplete += "," + this.form.otherEmail.value;
                        else
                            notifyOnComplete = this.form.otherEmail.value;
                    }
                    this.formService.updateForm({
                        id: this.formData.id,
                        stage: stage,
                        userGroupId: userGroupId,
                        assignedUserId: assignedUserId,
                        notifyOnComplete: notifyOnComplete
                    })
                        .subscribe(function () {
                        properties.push({
                            name: "reassign",
                            intData: _this.form.reassign.value ? 1 : 0
                        });
                        if (_this.form.reassign.value) {
                            // TODO: This fields validation shoudl change from int > 0 to NONE when reassign is true/false
                            properties.push({
                                name: "reassignToUserId",
                                intData: Number(_this.form.reassignToUserId.value)
                            });
                        }
                        properties.push({
                            name: "followUps",
                            jsonData: _this.form.followUps.value
                        });
                        if (_this.form.summary.value.length > 0) {
                            properties.push({
                                name: "summary",
                                stringData: _this.form.summary.value
                            });
                        }
                        if (_this.form.notes.value.length > 0) {
                            properties.push({
                                name: "notes",
                                stringData: _this.form.notes.value
                            });
                        }
                        properties.push({
                            name: "emailOther",
                            intData: _this.form.emailOther.value ? 1 : 0
                        });
                        if (_this.form.emailOther.value) {
                            properties.push({
                                name: "otherEmail",
                                stringData: _this.form.otherEmail.value
                            });
                        }
                        // Generate the follows
                        var followUps = [];
                        if (_this.form.followUps.value.length) {
                            followUps = JSON.parse(_this.form.followUps.value);
                        }
                        _this.formRecordService.createRecord({
                            formId: _this.formData.id,
                            // Intentionally cast the properties object since we know its correct
                            properties: properties,
                            stage: 2,
                            documents: _this.documents.map(function (doc) { return ({ id: doc.id, isTicked: !!doc.isTicked }); }),
                            isComplete: !isDraft
                        })
                            .subscribe(function (data) {
                            //Done creating the form and appending its properties
                            if (isDraft || followUps.length === 0) {
                                if (stage === 4) {
                                    _this.formService.finalizeForm(_this.formData.id)
                                        .subscribe(function () {
                                        resolve();
                                        _this.goBack();
                                    }, function (err) { return _this.errorHandler.handleHttpError(err); });
                                }
                                else {
                                    success();
                                }
                            }
                            else if (_this.form.reassign.value && _this.form.reassign.value) {
                                success();
                            }
                            else {
                                if (stage === 4) {
                                    _this.formService.finalizeForm(_this.formData.id)
                                        .subscribe(function () {
                                        _this.followUpService.generateFollowUps(_this.formData.id, _this.formData.formLocationId, followUps, _this.getFirstSubmissionType(), success);
                                    }, function (err) { return fail('Error while finalizing a form', err); });
                                }
                                else {
                                    _this.followUpService.generateFollowUps(_this.formData.id, _this.formData.formLocationId, followUps, _this.getFirstSubmissionType(), success);
                                }
                            }
                        }, function (err) { return fail('Error creating a record', err); });
                    }, function (err) { return fail('Error updating a form', err); });
                    return [2 /*return*/];
                });
            }); });
        });
    };
    ReportFormStage2Component.prototype.getFirstSubmissionType = function () {
        var firstSubmission = this.getFirstSubmission();
        if (firstSubmission) {
            var formType = firstSubmission.properties.find(function (recordProperty) { return recordProperty.property.name === "reportFormType"; });
            if (formType) {
                return formType.enumId;
            }
        }
        return null;
    };
    ReportFormStage2Component.prototype.getFirstSubmission = function () {
        if (!this.formData || !this.formData.records || !this.formData.records.length)
            return null;
        return this.formData.records.find(function (record) { return record.stage === 0 && record.isComplete; }) || null;
    };
    ReportFormStage2Component.prototype.repopulateFormFromData = function () {
        var _this = this;
        if (!this.formData || !this.formData.records || !this.formData.records.length)
            return;
        var stageRecords = this.formData.records.filter(function (record) { return record.stage === 2; });
        var previousRecord = this.formData.records.slice().sort(function (a, b) { return a.sequence > b.sequence ? 1 : -1; }).pop();
        /*
          If there are no pre-existing stage records, OR if the previous record is not a stage record,
          AND a sequence has not been specified (IE, not submitted), load followups from that previous record
        */
        if (!this.sequence && (stageRecords.length === 0 || (previousRecord && previousRecord.stage !== 2))) {
            /*
                At this point, we want to acknowledge the possibility that the
                previous stage specified followups that need to be completed
             */
            stageRecords = this.formData.records.filter(function (record) { return record.stage === 1; });
            var mostRecentRecord = stageRecords.sort(function (a, b) { return a.sequence > b.sequence ? 1 : -1; }).pop();
            if (!mostRecentRecord)
                return;
            var followUpRecord = mostRecentRecord.properties.find(function (recordProperty) { return recordProperty.property.name === 'followUps'; });
            if (followUpRecord) {
                this.form.followUps.value = followUpRecord.jsonData;
            }
            /**
             * aggregate documents and ticked documents from all previous complete records
             */
            var completeRecords = this.formData.records.filter(function (record) { return record.isComplete; });
            var allDocuments = [];
            var allTickedDocuments = [];
            for (var _i = 0, completeRecords_1 = completeRecords; _i < completeRecords_1.length; _i++) {
                var record = completeRecords_1[_i];
                if (record.documents && record.documents.length) {
                    allDocuments = allDocuments.concat(record.documents.map(function (doc) { return (__assign({}, doc)); })); // copy all documents object
                    if (record.tickedDocuments && record.tickedDocuments.length) {
                        allTickedDocuments = allTickedDocuments.concat(record.tickedDocuments);
                    }
                }
            }
            this.documents = this.initTickedDocuments(allDocuments, allTickedDocuments);
            return;
        }
        if (!this.sequence) {
            var mostRecentRecord = stageRecords.sort(function (a, b) { return a.sequence > b.sequence ? 1 : -1; }).pop();
            if (!mostRecentRecord)
                throw new Error("internal error");
            // If the most recent record was a submission, we are not going to use it
            if (mostRecentRecord.isComplete)
                return;
            this.formRecord = mostRecentRecord;
        }
        else {
            var targetRecord = stageRecords.find(function (record) { return record.sequence === _this.sequence; });
            if (!targetRecord)
                throw new Error("internal error");
            this.formRecord = targetRecord;
        }
        this.dateString = moment(this.formRecord.createdAt).tz(environment.timeZone).format(this.dateFormat);
        //Convert the properties into easily accessible IFormRecordPropertyParam
        if (!this.formRecord.properties)
            return;
        this.documents = this.initTickedDocuments(this.formRecord.documents, this.formRecord.tickedDocuments);
        var simpleProperties = {};
        this.formRecord.properties.forEach(function (recordProperty) {
            //eject invalid property
            if (!recordProperty.property)
                return;
            var result = {
                name: recordProperty.property.name
            };
            if (recordProperty.stringData)
                result.stringData = recordProperty.stringData;
            if (recordProperty.intData)
                result.intData = recordProperty.intData;
            if (recordProperty.jsonData)
                result.jsonData = recordProperty.jsonData;
            if (recordProperty.enumId)
                result.enumId = recordProperty.enumId;
            simpleProperties[result.name] = result;
        });
        /*
          If the last record for this stage is not the most recent record for this stage
          then it was passed back before coming forward again, and the reassignment
          should ALWAYS be 0
         */
        var allowReassignPrepopulate = true;
        /**
         *  FIX: added .slice() that copies an array to prevent mutation of original records
         **/
        var highestOrderRecord = this.formData.records.slice().sort(function (a, b) { return a.sequence > b.sequence ? 1 : -1; }).pop();
        if (!this.sequence && highestOrderRecord && highestOrderRecord.sequence > this.formRecord.sequence)
            allowReassignPrepopulate = false;
        if (simpleProperties['reassign']
            && simpleProperties['reassign'].intData !== null
            && simpleProperties['reassign'].intData !== undefined
            && allowReassignPrepopulate)
            this.form.reassign.value = (simpleProperties['reassign'].intData > 0);
        if (simpleProperties['reassignToUserId'] && allowReassignPrepopulate)
            this.form.reassignToUserId.value = String(simpleProperties['reassignToUserId'].intData);
        if (simpleProperties['followUps'])
            this.form.followUps.value = simpleProperties['followUps'].jsonData;
        if (simpleProperties['summary'])
            this.form.summary.value = simpleProperties['summary'].stringData;
        if (simpleProperties['notes'])
            this.form.notes.value = simpleProperties['notes'].stringData;
        if (simpleProperties['emailOther']
            && simpleProperties['emailOther'].intData !== null
            && simpleProperties['emailOther'].intData !== undefined)
            this.form.emailOther.value = (simpleProperties['emailOther'].intData > 0);
        if (simpleProperties['otherEmail'])
            this.form.otherEmail.value = simpleProperties['otherEmail'].stringData;
    };
    return ReportFormStage2Component;
}(FormComponent));
export { ReportFormStage2Component };
