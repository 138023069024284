export const environment = {
  production: true,
  apiPrefix: '',
  defaultAuthEmail : '',
  defaultAuthPassword: '',
  environmentName: "production",
  logLevel: "silly",
  timeZone: "Australia/Brisbane",
  adminGroupName: "head office",
  defaultRiskGroupName: "head office"
};
