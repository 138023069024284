var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { Location } from "@angular/common";
import { FormComponent } from "../../../../../model/FormComponent";
import { FormField } from "../../../../../model/Form";
import { Session } from "../../../../../service/util/Session";
import { CurrentUserService } from "../../../../../service/currentUser/CurrentUserService";
import * as moment from 'moment';
import "moment-timezone";
import { FormRecordService } from "../../../../../service/FormRecordService";
import { FormService } from "../../../../../service/FormService";
import { ErrorHandlerService } from "../../../../../service/ErrorHandlerService";
import { FollowUpWidgetComponent } from "../../../../shared/followUpWidget.component";
import { FollowUpService } from "../../../../../service/FollowUpService";
import { environment } from '../../../../../environments/environment';
var FollowUpFormStage2Component = /** @class */ (function (_super) {
    __extends(FollowUpFormStage2Component, _super);
    function FollowUpFormStage2Component(session, currentUserService, formRecordService, formService, errorHandler, followUpService, location) {
        var _this = _super.call(this, location) || this;
        _this.session = session;
        _this.currentUserService = currentUserService;
        _this.formRecordService = formRecordService;
        _this.formService = formService;
        _this.errorHandler = errorHandler;
        _this.followUpService = followUpService;
        // Existing Form Data
        _this.readOnly = false;
        _this.hideHeader = false;
        /*
          This should have been done properly using something that implements FormControl but its
          too late now
         */
        _this.form = {
            summary: new FormField('', {
                validation: FormField.ValidationMethods.IsNotBlank
            }),
            notes: new FormField('', {
                validation: FormField.ValidationMethods.None
            }),
            status: new FormField('inProgress', {
                validation: FormField.ValidationMethods.IsNotBlank,
            }),
            //Since this will be assigned to a select it must be a string data - Conversion where appropriate
            reassignToUserId: new FormField('', {
                validation: FormField.ValidationMethods.None
            }),
            reassign: new FormField(false, {
                validation: FormField.ValidationMethods.None
            }),
            followUps: new FormField('[]', {
                nullEquivilent: "[]",
                validation: function (value) {
                    return _this.followUpWidgetRef ? _this.followUpWidgetRef.validate() : true;
                }
            }),
        };
        _this.documents = [];
        return _this;
    }
    FollowUpFormStage2Component.prototype.registerFormFields = function () {
        var _this = this;
        var _a;
        (_a = this.formFields).push.apply(_a, Object.keys(this.form).map(function (k) { return _this.form[k]; }));
    };
    FollowUpFormStage2Component.prototype.ngOnInit = function () {
        this.dateString = moment().tz(environment.timeZone).format(this.dateFormat);
        this.registerFormFields();
        this.repopulateFormFromData();
    };
    FollowUpFormStage2Component.prototype.onSubmit = function (isDraft) {
        var _this = this;
        this.session.lockInput(function () {
            return new Promise(function (resolve, reject) {
                var success = function () {
                    resolve();
                    _this.goBack();
                };
                var fail = function (msg, err) {
                    console.error(msg, err);
                    _this.errorHandler.handleHttpError(err);
                    reject();
                };
                var stage = isDraft ? 2 : 3;
                var assignedUserId = null;
                if (_this.currentUserService.userData)
                    assignedUserId = _this.currentUserService.userData.id;
                if (!isDraft && _this.form.reassign.value && _this.form.reassignToUserId.value) {
                    stage = 1;
                    assignedUserId = Number(_this.form.reassignToUserId.value);
                }
                var properties = [];
                _this.formService.updateForm({
                    id: _this.formData.id,
                    stage: stage,
                    assignedUserId: assignedUserId
                })
                    .subscribe(function () {
                    properties.push({
                        name: "reassign",
                        intData: _this.form.reassign.value ? 1 : 0
                    });
                    if (_this.form.reassign.value) {
                        properties.push({
                            name: "reassignToUserId",
                            intData: _this.form.reassign.value ? Number(_this.form.reassignToUserId.value) : null,
                        });
                    }
                    properties.push({
                        name: "followUps",
                        jsonData: _this.form.followUps.value
                    });
                    if (_this.form.summary.value.length > 0)
                        properties.push({
                            name: "summary",
                            stringData: _this.form.summary.value
                        });
                    if (_this.form.notes.value.length > 0)
                        properties.push({
                            name: "notes",
                            stringData: _this.form.notes.value
                        });
                    var followUps = [];
                    if (_this.form.followUps.value.length) {
                        followUps = JSON.parse(_this.form.followUps.value);
                    }
                    _this.formRecordService.createRecord({
                        formId: _this.formData.id,
                        // Intentionally cast the properties object since we know its correct
                        properties: properties,
                        stage: 2,
                        documents: _this.documents.map(function (doc) { return ({ id: doc.id, isTicked: !!doc.isTicked }); }),
                        isComplete: !isDraft
                    })
                        .subscribe(function (data) {
                        //Done creating the form and appending its properties
                        if (isDraft || stage !== 3) {
                            success();
                        }
                        else {
                            _this.formService.finalizeForm(_this.formData.id)
                                .subscribe(function () {
                                if (followUps.length === 0)
                                    return success();
                                _this.followUpService.generateFollowUps(_this.formData.id, _this.formData.formLocationId, followUps, _this.getFirstSubmissionType(), success);
                            }, function (err) { return fail('Error while finalizing a form', err); });
                        }
                    }, function (err) { return fail('Error while creating a record', err); });
                }, function (err) { return fail('Error while updating a form', err); });
            });
        });
    };
    FollowUpFormStage2Component.prototype.repopulateFormFromData = function () {
        var _this = this;
        if (!this.formData || !this.formData.records || !this.formData.records.length)
            return;
        var stageRecords = this.formData.records.filter(function (record) { return record.stage === 2; });
        if (stageRecords.length === 0) {
            /*
                At this point, we want to acknowledge the possibility that the
                previous stage specified followups that need to be completed
             */
            stageRecords = this.formData.records.filter(function (record) { return record.stage === 1; });
            var mostRecentRecord = stageRecords.sort(function (a, b) { return a.sequence > b.sequence ? 1 : -1; }).pop();
            if (!mostRecentRecord)
                return;
            var followUpRecord = mostRecentRecord.properties.find(function (recordProperty) { return recordProperty.property.name === 'followUps'; });
            if (followUpRecord) {
                this.form.followUps.value = followUpRecord.jsonData;
            }
            return;
        }
        if (!this.sequence) {
            var mostRecentRecord = stageRecords.sort(function (a, b) { return a.sequence > b.sequence ? 1 : -1; }).pop();
            if (!mostRecentRecord)
                throw new Error("internal error");
            this.formRecord = mostRecentRecord;
        }
        else {
            var targetRecord = stageRecords.find(function (record) { return record.sequence === _this.sequence; });
            if (!targetRecord)
                throw new Error("Missing Record At Sequence " + this.sequence);
            this.formRecord = targetRecord;
        }
        //Convert the properties into easily accessible IFormRecordPropertyParam
        if (!this.formRecord.properties)
            return;
        this.dateString = moment(this.formRecord.createdAt).tz(environment.timeZone).format(this.dateFormat);
        var simpleProperties = {};
        this.formRecord.properties.forEach(function (recordProperty) {
            //eject invalid property
            if (!recordProperty.property)
                return;
            var result = {
                name: recordProperty.property.name
            };
            if (recordProperty.stringData)
                result.stringData = recordProperty.stringData;
            if (recordProperty.intData)
                result.intData = recordProperty.intData;
            if (recordProperty.jsonData)
                result.jsonData = recordProperty.jsonData;
            if (recordProperty.enumId)
                result.enumId = recordProperty.enumId;
            simpleProperties[result.name] = result;
        });
        /*
          If the previous record was not from the current user,
          or if a sequence was specified, we only want the followUps
        */
        if (!this.formRecord.isComplete || this.sequence) {
            if (simpleProperties['reassignToUserId'])
                this.form.reassignToUserId.value = String(simpleProperties['reassignToUserId'].intData);
            if (simpleProperties['reassign']
                && simpleProperties['reassign'].intData !== null
                && simpleProperties['reassign'].intData !== undefined) {
                this.form.reassign.value = (simpleProperties['reassign'].intData > 0);
                this.showReassignOptions(this.form.reassign.value);
            }
            if (simpleProperties['hasContactedOriginator']
                && simpleProperties['hasContactedOriginator'].intData !== null
                && simpleProperties['hasContactedOriginator'].intData !== undefined)
                this.form.hasContactedOriginator.value = (simpleProperties['hasContactedOriginator'].intData > 0);
            if (simpleProperties['summary'])
                this.form.summary.value = simpleProperties['summary'].stringData;
            if (simpleProperties['notes'])
                this.form.notes.value = simpleProperties['notes'].stringData;
            this.documents = this.initTickedDocuments(this.formRecord.documents, this.formRecord.tickedDocuments);
        }
        if (simpleProperties['followUps'])
            this.form.followUps.value = simpleProperties['followUps'].jsonData;
    };
    FollowUpFormStage2Component.prototype.showReassignOptions = function (state) {
        this.form.reassign.value = state;
        if (state) {
            this.setFieldValidation(this.form.reassignToUserId, FormField.ValidationMethods.IsNotBlank);
            this.setFieldValidation(this.form.summary, FormField.ValidationMethods.None);
        }
        else {
            this.setFieldValidation(this.form.reassignToUserId, FormField.ValidationMethods.None);
            this.setFieldValidation(this.form.summary, FormField.ValidationMethods.IsNotBlank);
        }
    };
    FollowUpFormStage2Component.prototype.getFirstSubmissionType = function () {
        var firstSubmission = this.getFirstSubmission();
        if (firstSubmission) {
            var formType = firstSubmission.properties.find(function (recordProperty) { return recordProperty.property.name === "reportFormType"; });
            if (formType) {
                return formType.enumId;
            }
        }
        return null;
    };
    FollowUpFormStage2Component.prototype.getFirstSubmission = function () {
        if (!this.formData || !this.formData.records || !this.formData.records.length) {
            return null;
        }
        return this.formData.records.find(function (record) { return record.stage === 0 && record.isComplete; }) || null;
    };
    return FollowUpFormStage2Component;
}(FormComponent));
export { FollowUpFormStage2Component };
