<div class="row">
    <div class="col-12">
        <div class="row">
            <div class="col-10 pb-2">
                Welcome {{ currentUserService.currentUserFullName | async }}
            </div>
            <div class="col-2 text-right">
                <div ngbDropdown class="dropdown-link-wrapper" placement="bottom-right">
                    <a class="dropdown-link" ngbDropdownToggle>Export to</a>
                    <div ngbDropdownMenu>
                        <button class="dropdown-item" (click)="handlePdfExport()">PDF file</button>
                        <button class="dropdown-item" (click)="handleCsvExport()">CSV file</button>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <h1 class="main-top-header">DASHBOARD</h1>
            </div>
        </div>
    </div>
    <div class="col-12">
        <div class="card-deck">
            <div class="card text-white bg-danger mb-3 top-card-filter"
                 (click)="toggleTopCardFilter('overdue')"
                 [ngClass]="{'active': formsQueryParams.dueFilter === 'overdue'}">
                <div class="card-body">
                    <h1 class="card-title text-center text-xxl">{{alertCount}}</h1>
                </div>
                <div class="card-footer text-center">OVERDUE</div>
            </div>
            <div class="card text-white bg-warning mb-3 top-card-filter"
                 (click)="toggleTopCardFilter('alert')"
                 [ngClass]="{'active': formsQueryParams.dueFilter === 'alert'}">
                <div class="card-body">
                    <h1 class="card-title text-center text-xxl">{{warningCount}}</h1>
                </div>
                <div class="card-footer text-center">Requires Attention</div>
            </div>
            <div class="card text-white bg-success mb-3 top-card-filter"
                 (click)="toggleTopCardFilter('remaining')"
                 [ngClass]="{'active': formsQueryParams.dueFilter === 'remaining'}">
                <div class="card-body">
                    <h1 class="card-title text-center text-xxl">{{taskCount}}</h1>
                </div>
                <div class="card-footer text-center">Remaining Tasks</div>
            </div>
            <div class="card text-white bg-light mb-3 top-card-filter" [routerLink]="['/drm-dashboard']">
                <div class="card-body">
                    <h1 class="card-title text-center text-dark text-xxl">{{notificationCount}}</h1>
                </div>
                <div class="card-footer text-center text-dark">Review Tasks</div>
            </div>
        </div>
    </div>
    <div class="col-12">
        <div class="row">
            <div class="col-8">
                <div class="form-group search-input-group">
                    <input class="form-control search-input-control"
                           type="text"
                           placeholder="Search 🔍"
                           [(ngModel)]="searchFilter"
                           (keyup.enter)="performSearch()"
                           (keyup.escape)="clearSearch()"
                           (blur)="performSearch()"
                    >
                    <span class="search-input-clear"
                          title="Clear"
                          *ngIf="searchFilter.length > 0"
                          (click)="clearSearch()">&times;</span>
                </div>
            </div>
            <div class="col-4">
                <div class="form-group">
                    <div ngbDropdown class="dropdown-link-wrapper" placement="bottom-left">
                        <a class="dropdown-link" ngbDropdownToggle>{{selectedCategoryFilter ? (selectedCategoryFilter.name + 's') : 'All Categories'}}</a>
                        <div ngbDropdownMenu>
                            <button class="dropdown-item"
                                    *ngIf="selectedCategoryFilter"
                                    (click)="handleCategoryFilter(null)">All Categories</button>
                            <ng-container *ngFor="let category of formCategories | async">
                                <button class="dropdown-item"
                                    *ngIf="!selectedCategoryFilter || category.id !== selectedCategoryFilter.id"
                                    (click)="handleCategoryFilter(category)">{{mapCategoryName(category.name)}}s</button>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="isAdminOrManager">
            <div class="col-12 offset-0 col-md-6 col-lg-4 col-sm-6 pb-2">
                <user-select
                    #userSelect
                    (change)="handleUserFilterChange($event)"
                    placeholder="View Queue For User"
                    [defaultValue]="defaultAssignedUserId"
                ></user-select>
            </div>
            <div class="col-12 offset-0 col-md-6 col-lg-4 col-sm-6 pb-2">
                <location-select
                    (change)="handleLocationFilterChange($event)"
                    placeholder="Location"
                    [value]="formsQueryParams.locationId || ''"
                    [restrictLocations]="true"
                    multiple="true"
                ></location-select>
            </div>
            <div class="col-12 offset-0 col-md-6 col-lg-4 col-sm-6 pb-2">
                <user-group-select
                    (change)="handleGroupFilterChange($event)"
                    placeholder="Department"
                    [value]="formsQueryParams.groupId || ''"
                    [restrictGroups]="!isAdmin"
                    multiple="true"
                ></user-group-select>
            </div>
        </div>
        <div class="row">
            <div class="col-12 offset-0 col-md-6 col-lg-4 col-sm-6">
                <div class="form-group search-input-group">
                    <input class="form-control search-input-control"
                       type="text"
                       placeholder="Originator Name 🔍"
                       [(ngModel)]="originatorFilter"
                       (keyup.enter)="performSearch()"
                       (keyup.escape)="clearSearch()"
                       (blur)="performSearch()"
                    >
                    <span class="search-input-clear"
                      title="Clear"
                      *ngIf="searchFilter.length > 0"
                      (click)="clearSearch()">&times;</span>
                </div>
            </div>
            <div class="col-12 offset-0 col-md-6 col-lg-4 col-sm-6">
                <!-- field]="form.type"-->
                <form-category-select
                    (change)="handleCategoryFilterChange($event)"
                    placeholder="Form Type"
                    [value]="formsQueryParams.category ? formsQueryParams.category.toString() : ''"
                ></form-category-select>
            </div>
            <div class="col-12 offset-0 col-md-6 col-lg-4 col-sm-6">
                <!-- field]="form.type"-->
                <enum-select
                    placeholder="Feedback Form Type"
                    enum="ReportFormType"
                    (change)="handleReportFormTypeChange($event)"
                    [value]="formsQueryParams.reportFormType ? formsQueryParams.reportFormType.toString() : ''"
                ></enum-select>
            </div>
        </div>
    </div>
    <div class="col-12">
        <div class="second-line-filter">
            <div class="date-filter-block">
                <div ngbDropdown class="dropdown-link-wrapper" placement="bottom-left">
                    <a class="dropdown-link" ngbDropdownToggle>{{dateFilterMap[selectedDateFilter]}}</a>
                    <div ngbDropdownMenu>
                        <button class="dropdown-item" (click)="handleDateFilterChange('createdAt')" *ngIf="selectedDateFilter !== 'createdAt'">Created At</button>
                        <button class="dropdown-item" (click)="handleDateFilterChange('dueAt')" *ngIf="selectedDateFilter !== 'dueAt'">Due Date</button>
                        <button class="dropdown-item" (click)="handleDateFilterChange('none')" *ngIf="selectedDateFilter !== 'none'">Clear Filter</button>
                    </div>
                </div>
                <div class="date-picker-container" *ngIf="selectedDateFilter !== 'none'">
                    <date-picker placeholder="Start date"
                                 [clearable]="true"
                                 [(value)]="filterStartDate"
                                 (valueChange)="handleStartDateChanged()"
                                 [disableValidationStyles]="true"></date-picker>
                </div>
                <div class="date-picker-container" *ngIf="selectedDateFilter !== 'none'">
                    <date-picker placeholder="End date"
                                 [clearable]="true"
                                 [(value)]="filterEndDate"
                                 (valueChange)="handleEndDateChanged()"
                                 [disableValidationStyles]="true"></date-picker>
                </div>
            </div>
            <div class="show-all-filter-block" *ngIf="isAdminOrManager">
                <button-group-toggle
                        [options]="showAllOptions"
                        [value]="formsQueryParams.showAll"
                        (valueChange)="handleShowAllFilterChange($event)"
                ></button-group-toggle>
            </div>
        </div>
    </div>
    <div class="col-12">
        <table class="table table-hover table-striped">
            <thead>
                <tr>
                    <th scope="col">
                        <span class="sortable-column"
                              (click)="handleSortChange('category')"
                              [ngClass]="{active: formsQueryParams.sortBy === 'category', reverse: formsQueryParams.order !== 'asc'}">
                            Type<i class="fa fa-caret-up"></i>
                        </span>
                    </th>
                    <th scope="col">
                        <span class="sortable-column"
                              (click)="handleSortChange('summary')"
                              [ngClass]="{active: formsQueryParams.sortBy === 'summary', reverse: formsQueryParams.order !== 'asc'}">
                            Form<i class="fa fa-caret-up"></i>
                        </span>
                    </th>
                    <th scope="col">
                        <span class="sortable-column"
                            (click)="handleSortChange('location')"
                            [ngClass]="{active: formsQueryParams.sortBy === 'location', reverse: formsQueryParams.order !== 'asc'}"
                        >
                            Location<i class="fa fa-caret-up"></i>
                        </span>
                    </th>
                    <th scope="col">
                        <span class="sortable-column"
                              (click)="handleSortChange('assignedTo')"
                              [ngClass]="{active: formsQueryParams.sortBy === 'assignedTo', reverse: formsQueryParams.order !== 'asc'}">
                            Assigned To<i class="fa fa-caret-up"></i>
                        </span>
                    </th>
                    <th scope="col" class="text-center">
                        <span class="sortable-column"
                            (click)="handleSortChange('createdAt')"
                            [ngClass]="{active: formsQueryParams.sortBy === 'createdAt', reverse: formsQueryParams.order !== 'asc'}">
                            Created At<i class="fa fa-caret-up"></i>
                        </span>
                    </th>
                    <th scope="col" class="text-center">
                        <span class="sortable-column"
                            (click)="handleSortChange('dueAt')"
                            [ngClass]="{active: formsQueryParams.sortBy === 'dueAt', reverse: formsQueryParams.order !== 'asc'}">
                            Due Date<i class="fa fa-caret-up"></i>
                        </span>
                    </th>
                    <th scope="col" class="text-center" *ngIf="formsQueryParams.showAll !== 'active'">
                        <span class="sortable-column"
                              (click)="handleSortChange('archivedAt')"
                              [ngClass]="{active: formsQueryParams.sortBy === 'archivedAt', reverse: formsQueryParams.order !== 'asc'}">
                            {{formsQueryParams.showAll === 'deleted' ? 'Deleted On' : 'Completed On'}}<i class="fa fa-caret-up"></i>
                        </span>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    class="hover-cursor"
                    [ngClass]="{
                        'table-danger': record.isAlert, 
                        'table-warning': record.isWarning, 
                        'table-success':  !record.isAlert && !record.isWarning, 
                        'table-secondary': record.isFinalized
                    }"
                    *ngFor="let record of dashboardRecords"
                    (click)="progressForm(record)"
                >
                    <td>{{record.type}}</td>
                    <td>{{record.title}}</td>
                    <td>{{record.location}}</td>
                    <td>
                        <span *ngIf="record.assignedUser">
                            {{ record.assignedUser }}
                        </span>
                        <span *ngIf="!record.assignedUser && record.assignedGroup">
                            {{ record.assignedGroup }} Department
                        </span>
                    </td>
                    <td class="text-center">{{record.createdAtDateString}}</td>
                    <td class="text-center">{{record.dueDateString}}</td>
                    <td class="text-center">{{record.archivedAtDateString}}</td>
                </tr>
                <tr *ngIf="dashboardRecords.length === 0">
                    <td [colSpan]="formsQueryParams.showAll === 'active' ? 6 : 7" class="text-center">
                        No Tasks
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="col-12" *ngIf="dashboardRecords.length > 0">
        <pagination
                [collectionSize]="totalForms"
                [(page)]="currentPage"
                (pageChange)="handlePageChange()"
                [pageSize]="pageSize"
                (pageSizeChange)="handlePageSizeChange($event)"
        ></pagination>
    </div>
</div>
