import { Component } from "@angular/core";
import { DateSelectModalOptions, DateSelectModalState } from "../../../service/ModalService";

@Component({
  selector: 'date-select-modal',
  templateUrl: './dateSelectModal.component.html',
  styles: [`
    .flex-grow-2 {
      flex-grow: 2;
    }
  `]
})
export class DateSelectModalComponent {
  options: DateSelectModalOptions;

  public value: string;
  public hours: number = 0;
  public minutes: number = 0;

  constructor(private state: DateSelectModalState) {
    this.options = state.options;
    this.value = state.options.defaultValue;

    if (state.options.defaultHours) {
      this.hours = Number(state.options.defaultHours);
    }

    if (state.options.defaultMinutes) {
      this.minutes = Number(state.options.defaultMinutes);
    }
  }

  submit() {
    let result: { date: string; time?: { hours: number, minutes: number } } = { date: this.value };

    if (this.options.includeTime) {
      result.time = {
        hours: this.hours,
        minutes: this.minutes
      }
    }

    this.state.modal.close(result);
  }

  cancel() {
    this.state.modal.dismiss('canceled');
  }

}
