/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./main.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "../authentication-icon/authentication-icon.component.ngfactory";
import * as i5 from "../authentication-icon/authentication-icon.component";
import * as i6 from "../../service/util/Session";
import * as i7 from "../../service/currentUser/CurrentUserService";
import * as i8 from "./main.component";
var styles_MainComponent = [i0.styles];
var RenderType_MainComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MainComponent, data: {} });
export { RenderType_MainComponent as RenderType_MainComponent };
export function View_MainComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["class", "container-fluid main-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "row header bg-white border-light border-bottom position-relative"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "col-4 text-center d-flex"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "a", [["class", "d-none d-md-block align-self-center logoLink"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(5, 1), (_l()(), i1.ɵeld(6, 0, null, null, 2, "a", [["class", "d-md-none align-self-center logoLink"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(7, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(8, 1), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "col-8 d-flex profileIcon flex-column justify-content-around align-items-end"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "authentication-icon", [], null, null, null, i4.View_AuthenticationIconComponent_0, i4.RenderType_AuthenticationIconComponent)), i1.ɵdid(11, 114688, null, 0, i5.AuthenticationIconComponent, [i6.Session, i7.CurrentUserService, i2.Router], { small: [0, "small"] }, null), (_l()(), i1.ɵeld(12, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(13, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(14, 0, null, null, 1, "div", [["class", "versionIdentifier"]], null, null, null, null, null)), (_l()(), i1.ɵted(15, null, ["V#", ""]))], function (_ck, _v) { var currVal_2 = _ck(_v, 5, 0, "/"); _ck(_v, 4, 0, currVal_2); var currVal_5 = _ck(_v, 8, 0, "/"); _ck(_v, 7, 0, currVal_5); var currVal_6 = true; _ck(_v, 11, 0, currVal_6); _ck(_v, 13, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 4).target; var currVal_1 = i1.ɵnov(_v, 4).href; _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵnov(_v, 7).target; var currVal_4 = i1.ɵnov(_v, 7).href; _ck(_v, 6, 0, currVal_3, currVal_4); var currVal_7 = _co.appVersion; _ck(_v, 15, 0, currVal_7); }); }
export function View_MainComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-main", [], null, null, null, View_MainComponent_0, RenderType_MainComponent)), i1.ɵdid(1, 114688, null, 0, i8.MainComponent, [i2.Router, i6.Session], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MainComponentNgFactory = i1.ɵccf("app-main", i8.MainComponent, View_MainComponent_Host_0, {}, {}, []);
export { MainComponentNgFactory as MainComponentNgFactory };
