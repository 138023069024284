/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./documentCollections.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../shared/pagination.component.ngfactory";
import * as i4 from "../../../../shared/pagination.component";
import * as i5 from "../../../../../service/PersistentStorageService";
import * as i6 from "./documentCollections.component";
import * as i7 from "../../../../../service/admin/DocumentCollectionsService";
import * as i8 from "@angular/router";
import * as i9 from "../../../../../service/ErrorHandlerService";
import * as i10 from "../../../../../service/currentUser/CurrentUserService";
var styles_DocumentCollectionsComponent = [i0.styles];
var RenderType_DocumentCollectionsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DocumentCollectionsComponent, data: {} });
export { RenderType_DocumentCollectionsComponent as RenderType_DocumentCollectionsComponent };
function View_DocumentCollectionsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "tr", [["class", "hover-cursor"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleDocumentCollectionClick(_v.context.$implicit.id) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵppd(5, 2)], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.name; _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i1.ɵnov(_v.parent, 0), _v.context.$implicit.createdAt, "mediumDate")); _ck(_v, 4, 0, currVal_1); }); }
function View_DocumentCollectionsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "td", [["class", "text-center"]], [[8, "colSpan", 0]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" No Collections "]))], null, function (_ck, _v) { var currVal_0 = 7; _ck(_v, 1, 0, currVal_0); }); }
export function View_DocumentCollectionsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "col-9 text-lg-left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h1", [["class", "text-uppercase text-orange"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Document Collections"])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "col-3 text-md-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "button", [["class", "btn btn-md btn-orange"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleDocumentCollectionClick("new") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["New Document Collection"])), (_l()(), i1.ɵeld(8, 0, null, null, 24, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 20, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 19, "table", [["class", "table table-hover table-striped"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 13, "thead", [], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 12, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 5, "th", [["scope", "col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 4, "span", [["class", "sortable-col-name"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleSortChange("name") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(15, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(16, { active: 0, reverse: 1 }), (_l()(), i1.ɵted(-1, null, [" Name"])), (_l()(), i1.ɵeld(18, 0, null, null, 0, "i", [["class", "fa fa-caret-up"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 5, "th", [["scope", "col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 4, "span", [["class", "sortable-col-name"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleSortChange("createdAt") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(21, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(22, { active: 0, reverse: 1 }), (_l()(), i1.ɵted(-1, null, [" Created At"])), (_l()(), i1.ɵeld(24, 0, null, null, 0, "i", [["class", "fa fa-caret-up"]], null, null, null, null, null)), (_l()(), i1.ɵeld(25, 0, null, null, 4, "tbody", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DocumentCollectionsComponent_1)), i1.ɵdid(27, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DocumentCollectionsComponent_2)), i1.ɵdid(29, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(30, 0, null, null, 2, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(31, 0, null, null, 1, "pagination", [], null, [[null, "pageChange"], [null, "pageSizeChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("pageChange" === en)) {
        var pd_0 = ((_co.currentPage = $event) !== false);
        ad = (pd_0 && ad);
    } if (("pageSizeChange" === en)) {
        var pd_1 = ((_co.pageSize = $event) !== false);
        ad = (pd_1 && ad);
    } if (("pageChange" === en)) {
        var pd_2 = (_co.handlePageChange() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i3.View_PaginationComponent_0, i3.RenderType_PaginationComponent)), i1.ɵdid(32, 49152, null, 0, i4.PaginationComponent, [i5.PersistentStorageService], { collectionSize: [0, "collectionSize"], page: [1, "page"], pageSize: [2, "pageSize"] }, { pageChange: "pageChange", pageSizeChange: "pageSizeChange" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = "sortable-col-name"; var currVal_2 = _ck(_v, 16, 0, (_co.sortBy === "name"), (_co.sortOrder !== "asc")); _ck(_v, 15, 0, currVal_1, currVal_2); var currVal_3 = "sortable-col-name"; var currVal_4 = _ck(_v, 22, 0, (_co.sortBy === "createdAt"), (_co.sortOrder !== "asc")); _ck(_v, 21, 0, currVal_3, currVal_4); var currVal_5 = _co.documentCollections; _ck(_v, 27, 0, currVal_5); var currVal_6 = (_co.documentCollections.length === 0); _ck(_v, 29, 0, currVal_6); var currVal_7 = _co.totalItems; var currVal_8 = _co.currentPage; var currVal_9 = _co.pageSize; _ck(_v, 32, 0, currVal_7, currVal_8, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.isAdmin ? null : true); _ck(_v, 6, 0, currVal_0); }); }
export function View_DocumentCollectionsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-groups-component", [], null, null, null, View_DocumentCollectionsComponent_0, RenderType_DocumentCollectionsComponent)), i1.ɵdid(1, 114688, null, 0, i6.DocumentCollectionsComponent, [i7.DocumentCollectionsService, i8.Router, i8.ActivatedRoute, i5.PersistentStorageService, i9.ErrorHandlerService, i10.CurrentUserService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DocumentCollectionsComponentNgFactory = i1.ɵccf("app-groups-component", i6.DocumentCollectionsComponent, View_DocumentCollectionsComponent_Host_0, {}, {}, []);
export { DocumentCollectionsComponentNgFactory as DocumentCollectionsComponentNgFactory };
