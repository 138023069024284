import { Component, OnInit, Input } from '@angular/core';
import { IFormOutputModel, IFormRecordOutputModel } from "../../../../../common/contracts/form";
import * as moment from 'moment';
import 'moment-timezone';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'header',
    template: `
    <div class="row">
        <div class="col-12 col-lg-6">
            <img src="assets/images/logo.svg" class="logoImg">
        </div>
        <div class="col-12 pt-2 pt-lg-0 col-lg-6 text-center">
            <div class="border border-dark rounded border-dashed d-inline-block float-lg-right py-3 px-5">
                <div class="row">
                    <div class="col-12">
                        <strong class="font-weight-heavy">Issue Number:</strong>
                        {{ formData ? formData.issueNumber || "TBA" : "TBA" }}
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-12">
                        <strong class="font-weight-heavy">Date Created:</strong> {{dateString}}
                    </div>
                </div>
            </div>
        </div>
    </div>
    `
})
export class HeaderComponent implements OnInit {

    //TODO: Move this to a config somewhere
    public dateFormat: string = "DD-MM-YYYY";
    public dateString: string;

    private _formRecord:IFormRecordOutputModel|null;
    
    @Input()
    public formData: IFormOutputModel;

    @Input()
    set formRecord(val:IFormRecordOutputModel|null) {
        this._formRecord = val;
    }

    get formRecord():IFormRecordOutputModel|null {
        return this._formRecord;
    }

    constructor() {

    }
    
    ngOnInit() {
        this.updateDateString();
    }

    updateDateString() {
        const theMoment = this.formRecord ? moment(this.formRecord.createdAt) : moment();

        this.dateString = theMoment.tz(environment.timeZone).format(this.dateFormat);
    }
}