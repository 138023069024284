<div class="row">
    <div class="col-9 text-lg-left">
        <h1 class="text-uppercase text-orange">Risks</h1>
    </div>
</div>
<div class="row">
    <div class="col-8">
        <div class="form-group search-input-group">
            <input class="form-control search-input-control" type="text" placeholder="Search 🔍"
                [(ngModel)]="searchFilter" (keyup.enter)="performSearch()" (keyup.escape)="clearSearch()"
                (blur)="performSearch()">
        </div>
    </div>
</div>

<div class="row">
    <div class="col-12">
        <table class="table table-hover table-striped">
            <thead>
                <tr>
                    <th scope="col">
                        <span class="sortable-col-name" (click)="handleSortChange('summary')"
                            [ngClass]="{active: sortBy === 'summary', reverse: sortOrder !== 'asc'}">
                            Name<i class="fa fa-caret-up"></i>
                        </span>
                    </th>
                    <th scope="col">
                        <span class="sortable-col-name" (click)="handleSortChange('clientName')"
                            [ngClass]="{active: sortBy === 'clientName', reverse: sortOrder !== 'asc'}">
                            Client Name<i class="fa fa-caret-up"></i>
                        </span>
                    </th>
                    <th scope="col">
                        <span class="sortable-col-name" (click)="handleSortChange('riskLevel')"
                            [ngClass]="{active: sortBy === 'riskLevel', reverse: sortOrder !== 'asc'}">
                            Risk Level<i class="fa fa-caret-up"></i>
                        </span>
                    </th>
                    <th scope="col">
                        <span class="sortable-col-name" (click)="handleSortChange('riskStatus')"
                            [ngClass]="{active: sortBy === 'riskStatus', reverse: sortOrder !== 'asc'}">
                            Risk Status<i class="fa fa-caret-up"></i>
                        </span>
                    </th>
                    <th scope="col">
                        <span class="sortable-col-name" (click)="handleSortChange('dateOfNextAssessment')"
                            [ngClass]="{active: sortBy === 'dateOfNextAssessment', reverse: sortOrder !== 'asc'}">
                            Date of Next Assessment<i class="fa fa-caret-up"></i>
                        </span>
                    </th>
                    <th scope="col">
                        <span class="sortable-col-name" (click)="handleSortChange('dateOfLastAssessment')"
                            [ngClass]="{active: sortBy === 'dateOfLastAssessment', reverse: sortOrder !== 'asc'}">
                            Date of Last Assessment<i class="fa fa-caret-up"></i>
                        </span>
                    </th>
                    <th scope="col">
                        <span class="sortable-col-name" (click)="handleSortChange('riskStatus')"
                            [ngClass]="{active: sortBy === 'riskStatus', reverse: sortOrder !== 'asc'}">
                            Date of Initial Assessment<i class="fa fa-caret-up"></i>
                        </span>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr class="hover-cursor" *ngFor="let risk of risks" (click)="handleRiskClick(risk.id)">
                    <td>{{risk.summary}}</td>
                    <td>{{risk.clientName || 'N/A'}}</td>
					<td>{{risk.riskLevel}}</td>
					<td>{{risk.riskStatus}}</td>
					<td>{{risk.dateOfNextAssessment ? (risk.dateOfNextAssessment | date: 'mediumDate') : 'N/A'}}</td>
					<td>{{risk.dateOfLastAssessment | date: 'mediumDate'}}</td>
					<td>{{risk.createdAt | date: 'mediumDate'}}</td>
                </tr>
                <tr *ngIf="risks.length === 0">
                    <td [colSpan]="7" class="text-center">
                        No Collections
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="col-12">
        <pagination [collectionSize]="totalItems" [(page)]="currentPage" [(pageSize)]="pageSize"
            (pageChange)="handlePageChange()"></pagination>
    </div>
</div>