var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from "@angular/core";
import { Location } from "@angular/common";
import * as moment from 'moment';
import 'moment-timezone';
import { FormField } from "../../../../../model/Form";
import { FormComponent } from "../../../../../model/FormComponent";
import { Session } from "../../../../../service/util/Session";
import { CurrentUserService } from "../../../../../service/currentUser/CurrentUserService";
import { FormService } from "../../../../../service/FormService";
import { CategoryService } from "../../../../../service/CategoryService";
import { FormRecordService } from "../../../../../service/FormRecordService";
import { Router } from "@angular/router";
import { ErrorHandlerService } from "../../../../../service/ErrorHandlerService";
import { DocumentIntanceService } from "service/admin/DocumentInstanceService";
import { saveAs } from 'file-saver';
import { DocumentService } from "service/DocumentService";
import { ModalService } from "service/ModalService";
import { DocumentsService } from "service/admin/DocumentsService";
import { environment } from "../../../../../environments/environment";
var DocumentReviewFormStage0Component = /** @class */ (function (_super) {
    __extends(DocumentReviewFormStage0Component, _super);
    function DocumentReviewFormStage0Component(session, currentUserService, formService, categoryService, formRecordService, router, errorHandler, documentInstanceService, documentService, documentDRMService, location, modalService) {
        var _this = _super.call(this, location) || this;
        _this.session = session;
        _this.currentUserService = currentUserService;
        _this.formService = formService;
        _this.categoryService = categoryService;
        _this.formRecordService = formRecordService;
        _this.router = router;
        _this.errorHandler = errorHandler;
        _this.documentInstanceService = documentInstanceService;
        _this.documentService = documentService;
        _this.documentDRMService = documentDRMService;
        _this.modalService = modalService;
        // Existing Form Data
        _this.readOnly = false;
        _this.hideHeader = false;
        _this.documents = [];
        /*
          This should have been done properly using something that implements FormControl but its
          too late now
         */
        _this.form = {
            department: new FormField('', {
                validation: FormField.ValidationMethods.IsNotBlank
            }),
            summary: new FormField('', {
                validation: FormField.ValidationMethods.IsNotBlank
            }),
            document: new FormField('', {
                validation: FormField.ValidationMethods.IsNotBlank
            }),
        };
        _this.uploadedDocument = [];
        _this.hasDraftFromCompletedTasks = false;
        _this.isAdminOrManager = _this.currentUserService.isAdministrator.getValue() || _this.currentUserService.userData.role === 'manager';
        return _this;
    }
    DocumentReviewFormStage0Component.prototype.registerFormFields = function () {
        var _this = this;
        var _a;
        (_a = this.formFields).push.apply(_a, Object.keys(this.form).map(function (k) { return _this.form[k]; }));
    };
    DocumentReviewFormStage0Component.prototype.onSubmit = function (isDraft) {
        var _this = this;
        if (isDraft === void 0) { isDraft = false; }
        var isLatestAprovedVersionEmpty = !this.latestAprovedVersion;
        if (isLatestAprovedVersionEmpty && this.uploadedDocument.length === 0) {
            this.modalService.alert({
                title: 'Alert',
                message: 'Document has no aproved versions, please upload a draft to continue.'
            });
            return;
        }
        if (this.uploadedDocument.length === 0) {
            this.modalService.confirmRx({
                title: 'Warning',
                message: 'No files uploaded. Latest draft will be used. Continue?'
            }).subscribe(function (isConfirmed) {
                if (isConfirmed) {
                    /**
                     * Ideally this should be done by copying the latest version and
                     * creating the new draft and form record. In order to make the
                     * code flow simple and clear we are downloading the latest version
                     * and uploading it again as a draft.
                     */
                    _this.documentInstanceService.downloadDraftDocument(_this.latestAprovedVersion.id).subscribe(function (blob) {
                        var fileObject = new File([blob], _this.latestAprovedVersion.originalFileName);
                        _this.documentService.uploadDocument(fileObject).subscribe(function (newDocument) {
                            _this.documents = [];
                            _this.documents.push(newDocument);
                            _this.submitForm(isDraft);
                        });
                    });
                }
            });
        }
        else {
            this.submitForm(isDraft);
        }
    };
    DocumentReviewFormStage0Component.prototype.submitForm = function (isDraft) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var userGroupId = _this.form.department.isValid ? Number(_this.form.department.value) : null;
            var stage = isDraft ? 0 : 1;
            var formFunc;
            // The assigned user should always be reset to null on submission of stage 0
            var assignedUserId = null;
            if (_this.currentUserService.userData && isDraft)
                assignedUserId = _this.currentUserService.userData.id;
            var isNewForm = !(_this.formData && _this.formData.id);
            /* NOTE : The assigned userID should be set to null if this is not a draft */
            if (!isNewForm) {
                //FormFunc = update
                formFunc = _this.formService.updateForm({
                    id: _this.formData.id,
                    userGroupId: userGroupId,
                    stage: stage,
                    assignedUserId: assignedUserId
                });
            }
            else {
                // The actual due date is 21 days however we want this to show up red on the due date
                var dueAt = moment().tz(environment.timeZone).add(5, 'weeks').set({
                    'hour': 0,
                    'minute': 0,
                    'second': 0
                }).toISOString(false);
                // This should be back to the client within 14 days
                var alertAt = moment().tz(environment.timeZone).add(4, 'weeks').set({
                    'hour': 0,
                    'minute': 0,
                    'second': 0
                }).toISOString(false);
                formFunc = _this.formService.createForm({
                    categoryId: _this.formCategoryId,
                    formLocationId: null,
                    userGroupId: userGroupId,
                    dueAt: dueAt,
                    alertAt: alertAt,
                    stage: stage,
                    parentFormId: null,
                    assignedUserId: assignedUserId,
                    notifyOnComplete: null
                });
            }
            formFunc.subscribe(function (data) {
                var properties = [];
                var formId = (_this.formData && _this.formData.id) ? _this.formData.id : data['id'];
                var formCreatedBy = (_this.formData && _this.formData.createdById) ? _this.formData.createdById : data['createdById'];
                if (userGroupId)
                    properties.push({
                        name: "userGroupId",
                        intData: userGroupId
                    });
                // This desperately needs to be refactored but in the interest of brevity...
                properties.push({
                    name: "summary",
                    stringData: _this.form.summary.value
                });
                properties.push({
                    name: "documentId",
                    stringData: _this.form.document.value
                });
                // TODO: Add issue number
                if (_this.uploadedDocument.length === 0) {
                    _this.uploadedDocument.push(_this.documents[_this.documents.length - 1]);
                }
                _this.formRecordService.createRecord({
                    formId: formId,
                    // Intentionally cast the properties object since we know its correct
                    properties: properties,
                    stage: 0,
                    documents: _this.uploadedDocument.map(function (doc) { return ({ id: doc.id, isTicked: !!doc.isTicked }); }),
                    isComplete: !isDraft
                })
                    .subscribe(function (data) {
                    //Done creating the form and appending its properties
                    if (!isDraft) {
                        var params = {
                            userGroupId: userGroupId,
                            stage: 1,
                            createdUserId: formCreatedBy,
                            documentId: _this.documentId,
                            completedBy: _this.currentUserService.userData ? _this.currentUserService.userData.id : null
                        };
                        _this.formService.sendReviewFormNotifications(params).subscribe();
                    }
                    resolve();
                    if (isNewForm) {
                        // go to dashboard
                        _this.router.navigate(['/drm-dashboard']);
                    }
                    else {
                        _this.goBack();
                    }
                }, function (err) {
                    _this.errorHandler.handleHttpError(err);
                    reject("Error creating new record");
                });
            }, function (err) {
                _this.errorHandler.handleHttpError(err);
                reject("Error Creating or updating form");
            });
        });
    };
    DocumentReviewFormStage0Component.prototype.ngOnInit = function () {
        this.dateString = moment().tz(environment.timeZone).format(this.dateFormat);
        this.form.department.value = this.departmentId ? this.departmentId.toString() : "";
        this.registerFormFields();
        this.loadEnums();
        this.repopulateFormFromData();
        this.getLatestAprovedDocument();
        this.getLatestDraft();
        this.populateUploadedDocumentsInCurrentStage(this.readOnly);
        this.form.document.value = this.documentId ? this.documentId.toString() : "";
    };
    DocumentReviewFormStage0Component.prototype.populateUploadedDocumentsInCurrentStage = function (readOnly) {
        if (!this.formData) {
            return;
        }
        if (!readOnly && this.formData) {
            var completeRecords_1 = this.formData.records.filter(function (record) { return (record.stage === 0 && record.documents.length > 0); });
            if (completeRecords_1.length === 0) {
                return;
            }
            var documents_1 = completeRecords_1[completeRecords_1.length - 1].documents;
            var uploadedDocument_1 = documents_1[documents_1.length - 1];
            this.uploadedDocument.push(uploadedDocument_1);
            return;
        }
        var completeRecords = this.formData.records.filter(function (record) { return (record.stage === 0 && record.isComplete && record.documents.length > 0); });
        if (completeRecords.length === 0) {
            return;
        }
        var documents = completeRecords[0].documents;
        var uploadedDocument = documents[documents.length - 1];
        this.uploadedDocument.push(uploadedDocument);
    };
    DocumentReviewFormStage0Component.prototype.repopulateFormFromData = function () {
        var _this = this;
        if (!this.formData || !this.formData.records || !this.formData.records.length)
            return;
        var stageRecords = this.formData.records.filter(function (record) { return record.stage === 0; });
        if (stageRecords.length === 0)
            return;
        if (!this.sequence) {
            var mostRecentRecord = stageRecords.sort(function (a, b) { return a.sequence > b.sequence ? 1 : -1; }).pop();
            if (!mostRecentRecord)
                throw new Error("internal error");
            // If the most recent record was a submission, we are not going to use it
            if (mostRecentRecord.isComplete)
                return;
            this.formRecord = mostRecentRecord;
        }
        else {
            var targetRecord = stageRecords.find(function (record) { return record.sequence === _this.sequence; });
            if (!targetRecord)
                throw new Error("internal error");
            this.formRecord = targetRecord;
        }
        /**
         * get attached documents
         */
        this.documents = this.initTickedDocuments(this.formRecord.documents, this.formRecord.tickedDocuments);
        /**
         * show update date from record's createdAt
         */
        this.dateString = moment(this.formRecord.createdAt).tz(environment.timeZone).format(this.dateFormat);
        //Convert the properties into easily accessible IFormRecordPropertyParam
        if (!this.formRecord.properties) {
            return;
        }
        var simpleProperties = {};
        this.formRecord.properties.forEach(function (recordProperty) {
            //eject invalid property
            if (!recordProperty.property)
                return;
            var result = {
                name: recordProperty.property.name
            };
            if (recordProperty.stringData)
                result.stringData = recordProperty.stringData;
            if (recordProperty.intData)
                result.intData = recordProperty.intData;
            if (recordProperty.jsonData)
                result.jsonData = recordProperty.jsonData;
            if (recordProperty.enumId)
                result.enumId = recordProperty.enumId;
            simpleProperties[result.name] = result;
        });
        if (simpleProperties['userGroupId'])
            this.form.department.value = String(simpleProperties['userGroupId'].intData);
        else if (this.formData['userGroupId'])
            this.form.department.value = String(this.formData['userGroupId']);
        if (this.formData['formLocationId'])
            this.form.location.value = String(this.formData['formLocationId']);
        //Repopulate from the simpleProperties
        if (simpleProperties['summary'])
            this.form.summary.value = simpleProperties['summary'].stringData;
    };
    DocumentReviewFormStage0Component.prototype.loadEnums = function () {
        var _this = this;
        this.session.lockInputRx(this.categoryService.getCategories())
            .subscribe(function (data) {
            var reportCategory = data.find(function (data) { return !!data.name.match(/^DRM-Task/); });
            if (reportCategory)
                _this.formCategoryId = reportCategory.id;
            //else
            //Notify the user an error has occurred, as we can't find the right form category
        }, function (err) {
            console.error('Error getting enums', err);
            _this.errorHandler.handleHttpError(err);
        });
    };
    DocumentReviewFormStage0Component.prototype.getLatestDraft = function () {
        var _this = this;
        this.formService.getFormsFromDocumentId(this.documentId).subscribe(function (data) {
            if (data.length === 0) {
                _this.documentInstanceService.getDraftDocument(_this.documentId).subscribe(function (data) {
                    if (data) {
                        _this.latestDraftRecord = data;
                        _this.hasDraftFromCompletedTasks = true;
                    }
                    else {
                        _this.latestDraftRecord = null;
                    }
                });
            }
            else {
                var formsCreatedAtArray = data.map(function (form) { return new Date(form.createdAt); }), latestFormCreatedAt_1 = new Date(Math.max.apply(null, formsCreatedAtArray)), latestFormRecords_1 = data.find(function (form) { return (new Date(form.createdAt).getTime() === latestFormCreatedAt_1.getTime()); }).records, latestRecord = latestFormRecords_1.find(function (record) { return new Date(record.createdAt).getTime() === new Date(Math.max.apply(null, latestFormRecords_1.filter(function (record) { return !!record.documents.length; }).map(function (record) { return new Date(record.createdAt); }))).getTime(); });
                _this.latestDraftRecord = latestRecord;
            }
        });
    };
    DocumentReviewFormStage0Component.prototype.downloadLatestDraft = function () {
        var _this = this;
        if (this.latestDraftRecord === null) {
            this.downloadLatestAprovedVersion();
        }
        else if (this.hasDraftFromCompletedTasks && this.latestDraftRecord) {
            this.documentInstanceService.downloadDraftDocument(this.latestDraftRecord.id).subscribe(function (data) {
                saveAs(data, _this.latestDraftRecord.originalFileName);
            });
        }
        else {
            this.documentService.downloadDocument(this.latestDraftRecord.documents[0].id).subscribe(function (file) {
                saveAs(file, _this.latestDraftRecord.documents[0].fileName);
            });
        }
    };
    DocumentReviewFormStage0Component.prototype.getLatestAprovedDocument = function () {
        var _this = this;
        if (this.formData) {
            var formPropertiesArray = this.formData.records.map(function (record) { return record.properties; }), flatenedFormPropertiesArray = [].concat.apply([], formPropertiesArray), documentIdProperty = flatenedFormPropertiesArray.find(function (property) { return property.property.name === "documentId"; });
            this.documentId = documentIdProperty.stringData;
        }
        this.documentDRMService.getDocumentInstanceByDocumentId(this.documentId).subscribe(function (documentInstance) {
            _this.latestAprovedVersion = documentInstance;
        });
    };
    DocumentReviewFormStage0Component.prototype.downloadLatestAprovedVersion = function () {
        var _this = this;
        this.documentInstanceService.downloadDocument(this.latestAprovedVersion.id).subscribe(function (data) {
            saveAs(data, _this.latestAprovedVersion.fileName);
        });
    };
    return DocumentReviewFormStage0Component;
}(FormComponent));
export { DocumentReviewFormStage0Component };
