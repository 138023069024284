import { Component, OnInit } from '@angular/core';
// import { IrisksortedField, IDocumentCollectionOutputModel } from "../../../../../../common/contracts/documentCollection";
import { ActivatedRoute, Router } from "@angular/router";
import { ISortOrderValue } from "../../../../../../common/contracts/common";
import { PersistentStorageService } from "../../../../../service/PersistentStorageService";
import { ErrorHandlerService } from "../../../../../service/ErrorHandlerService";
import { CurrentUserService } from '../../../../../service/currentUser/CurrentUserService';
import { RiskService } from 'service/RiskService';

@Component({
  selector: 'app-risks-component',
  templateUrl: './risks.component.html',
  styleUrls: ['./risks.component.scss']
})
export class RiskComponent implements OnInit {

  public risks = [];
  public totalItems = 0;
  public currentPage = 1;
  public pageSize = this.pss.pageSize;
  public sortBy = 'summary';
  public sortOrder: ISortOrderValue = 'asc';
  public isAdmin: boolean = false;
  public searchFilter: string = '';

  constructor(private groupsService: RiskService,
    private router: Router,
    private route: ActivatedRoute,
    private pss: PersistentStorageService,
    private errorHandler: ErrorHandlerService,
    private userService: CurrentUserService,) { }

  ngOnInit() {
    this.isAdmin = this.userService.isAdministrator.getValue();
    this.handlePageChange();
  }

  public handleRiskClick(groupId: number | string) {
    this.router.navigate([`${groupId}`], { relativeTo: this.route });
  }

  public handlePageChange() {
    let search = this.searchFilter.length > 0 ? this.searchFilter : ''
    this.groupsService.getRisks(
      this.pageSize * (this.currentPage - 1),
      this.pageSize,
      this.sortBy,
      this.sortOrder,
      search
    ).subscribe((data) => {
      this.risks = data.items;
      this.totalItems = data.totalCount;
    }, (err) => this.errorHandler.handleHttpError(err));
  }

  public handleSortChange(sortField) {
    if (sortField === this.sortBy) {
      this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortBy = sortField;
      this.sortOrder = 'asc';
    }

    this.handlePageChange();
  }

  public performSearch() {
      this.searchFilter = this.searchFilter.trim();
      this.currentPage = 1;
      this.handlePageChange();
  }
  
  public clearSearch() {
    this.searchFilter = '';
    this.currentPage = 1;
    this.handlePageChange();
  }

}
