<div class="row">
    <div class="col-12 text-lg-left">
        <h1 class="text-uppercase text-orange">{{isNew ? 'New' : 'Edit'}} Document Collection</h1>
    </div>
</div>


<form [formGroup]="documentCollectionForm">
    <div class="row">
        <div class="col-12 offset-0 col-sm-6 col-lg-6 offset-lg-0 col-xl-6 offset-xl-0">
            <div class="form-group">
                <label for="usernameInput">Document Collection Name:</label>
                <input type="text" id="usernameInput" placeholder="Collection name" autofocus
                    class="form-control input-lg" rxControl formControlName="name">
                <small class="form-text text-danger"
                    *ngIf="documentCollectionForm.controls.name.touched && documentCollectionForm.controls.name.hasError('required')">This
                    field is required.</small>
            </div>
        </div>
        <div class="col-12 offset-0 col-sm-6 col-lg-6 offset-lg-0 col-xl-6 offset-xl-0">
            <div class="form-group">
                <label>Review Schedule:</label>
                <select class="form-control" formControlName="reviewSchedule">
                    <option value="" selected disabled>Select review interval</option>
                    <option value="ANNUALLY">Annual</option>
                    <option value="BIANNUALLY">Biannual</option>
                </select>
            </div>
        </div>
    </div>
    <div class="row bg-default mx-1 my-3 py-4">
        <div class="col-12 col-lg-4 p-1 text-center mt-lg-2 mt-xl-0">
            <span *ngIf="!!documentCollection">Date Created: <span
                    class="text-primary">{{ documentCollection.createdAt | date: 'mediumDate' }}</span></span>
        </div>
        <div class="col-12 col-lg-4 p-1 text-center mt-lg-2 mt-xl-0">
            <span *ngIf="!!documentCollection">Date Updated: <span
                    class="text-primary">{{ documentCollection.updatedAt | date: 'mediumDate' }}</span></span>
        </div>
    </div>
    <div class="row" *ngIf="isDocAdmin">
        <div class="col-12 col-md-6 col-lg-4 col-xl-2 col-xxl-2">
            <button class="btn btn-block btn-lg btn-success rounded-0" (click)="submit()"
                [disabled]="documentCollectionForm.pristine">
                <span class="capitalize">save</span>
            </button>
        </div>
        <div class="col-12 pt-2 col-md-6 pt-md-0 col-lg-4 col-xl-2 col-xxl-2">
            <a class="btn btn-block btn-lg btn-secondary rounded-0 text-center"
                [routerLink]="['/document-collections']"><span class="capitalize">cancel</span></a>
        </div>
        <div class="col-12 col-md-6 col-lg-4 col-xl-2 col-xxl-2" *ngIf="!isNew">
            <button class="btn btn-block btn-lg btn-danger rounded-0" (click)="archiveDocumentCollection()"><span
                    class="capitalize">delete</span></button>
        </div>
    </div>
    <div class="row my-1" *ngIf="isDocAdmin && !isNew">
        <div class="col-12 col-md-6 col-lg-4 col-xl-2 col-xxl-2">
            <div class="form-group">
                <label>Show archived:</label>
                <button-toggle [value]="includeArchived" (valueChange)="handleIncludeArchivedToggle()"
                    [disabled]="!isDocAdmin"></button-toggle>
            </div>
        </div>
    </div>
</form>
<div *ngIf="!!documentCollection" class="row mt-4">
    <div class="col-9 text-lg-left">
        <h1 class="text-uppercase text-orange">Documents</h1>
    </div>
    <div class="col-3 text-md-right">
        <button class="btn btn-md btn-orange" (click)="handleDocumentClick('new')"
            [disabled]="isDocAdmin ? null: true">New Document</button>
    </div>
</div>
<div *ngIf="!!documentCollection" class="row">
    <div class="col-12">
        <table class="table table-hover table-striped">
            <thead>
                <tr>
                    <th scope="col">
                        <span class="sortable-col-name" (click)="handleSortChange('documentCode')"
                            [ngClass]="{active: sortBy === 'documentCode', reverse: sortOrder !== 'asc'}">
                            Document ID<i class="fa fa-caret-up"></i>
                        </span>
                    </th>
                    <th scope="col">
                        <span class="sortable-col-name" (click)="handleSortChange('name')"
                            [ngClass]="{active: sortBy === 'name', reverse: sortOrder !== 'asc'}">
                            Name<i class="fa fa-caret-up"></i>
                        </span>
                    </th>
                    <th scope="col">
                        <span class="sortable-col-name" (click)="handleSortChange('createdAt')"
                            [ngClass]="{active: sortBy === 'createdAt', reverse: sortOrder !== 'asc'}">
                            Created At<i class="fa fa-caret-up"></i>
                        </span>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr class="hover-cursor" *ngFor="let document of documents" (click)="handleDocumentClick(document.id)">
                    <td>{{document.documentCode}}</td>
                    <td>{{document.name}}</td>
                    <td>{{document.createdAt | date : 'mediumDate'}}</td>
                </tr>
                <tr *ngIf="documents.length === 0">
                    <td [colSpan]="6" class="text-center">
                        No Documents
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="col-12">
        <pagination [collectionSize]="totalItems" [(page)]="currentPage" [(pageSize)]="pageSize"
            (pageChange)="handlePageChange()"></pagination>
    </div>
</div>