<div *ngIf="!showForm">
    Loading...
</div>
<!-- <form-actions [formData]="formData"></form-actions> -->
<document-review-form-0
    #reportStage0
    [formData]="formData"
    [departmentId]="departmentId"
    [documentId]="documentId"
    *ngIf="showForm && targetStage === 0 && ((!formData || !formData.isDeleted) || true)">
</document-review-form-0>
<document-review-form-1
    #reportStage1
    [formData]="formData"
    *ngIf="showForm && targetStage === 1 && ((!formData || !formData.isDeleted) || true)">
</document-review-form-1>
<document-review-form-2
    #reportStage2
    [formData]="formData"
    *ngIf="showForm && targetStage === 2 && ((!formData || !formData.isDeleted) || true)">
</document-review-form-2>
<document-review-form-3
    #reportStage3
    [formData]="formData"
    *ngIf="showForm && targetStage === 3 && ((!formData || !formData.isDeleted) || true)">
</document-review-form-3>
<document-review-form-5
    #reportStage5
    [formData]="formData"
    *ngIf="showForm && targetStage === 4 && ((!formData || !formData.isDeleted) || true)">    
</document-review-form-5>

<!---
    The read only view of the form should be always visible throughout the process
--->
<div class="{{ targetStage === 4 ? '' : 'pt-2' }}">&nbsp;</div>
<document-review-form-4 [formData]="formData" *ngIf="showForm && targetStage > 0 && !!formData"></document-review-form-4>

<div class="row" *ngIf="childForms">
    <div class="col-12">
        <h1 class="text-uppercase text-white bg-teal p-2 px-3 my-2">Child Forms</h1>
        <div class="pl-2">
            <child-forms-tree [childForms]="childForms"></child-forms-tree>
        </div>
    </div>
</div>

