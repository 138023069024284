import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { CurrentUserService } from "../../../service/currentUser/CurrentUserService";

/**
 * This component should always be authenticated - it's protected by CurrentUserRouterGuard
 */
@Component({
	templateUrl: './authenticated.component.html',
	styles: [`
		.nav {
			background-color: #2a333f!important;
			font-size: 20px;
			min-height: 100vh;
		}

		.nav a {
			border-bottom-color: rgb(142, 166, 201);
			border-bottom-style: solid;
			border-bottom-width: 1px;
			color: #8ea6c9;
			-webkit-font-smoothing: antialiased;
			padding: 1rem 1.2rem;
		}

		.nav a.indent {
			padding-left: 3rem;
		}

		.nav a i {
			padding-right: .5rem;
		}

		.nav a.active, .nav a:hover {
			background-color: #f36b21!important;
			color: #fff;
		}
	`]
})
export class AuthenticatedComponent implements OnInit {

	private activeUrl: string;

	public adminSectionActive: boolean = false;
	public riskSectionActive: boolean = false;

	public navOptions = {
		newRiskAssessment: false
	};

	constructor(
		public currentUserService: CurrentUserService,
		public router: Router
	) { }

	ngOnInit() {
		this.checkNavigationPermissions();

		this.updateNavState();
		this.router.events.subscribe(value => {
			if (value instanceof NavigationEnd) {
				setTimeout(() => {
					this.updateNavState();
				}, 1);
			}
		});
	}

	/**
	 * @description Dirty way of enabling and disabling various parts of the UI
	 */
	private updateNavState() {
		this.activeUrl = this.router.url;
		if( this.isActive('/admin') ) {
			this.adminSectionActive = true;
			this.riskSectionActive = false;
		} else if (
			this.isActive('/ram-dashboard')
			|| this.isActive('/risks')
			|| this.isActive('/risk-assessment-task')
		) {
			this.riskSectionActive = true;
			this.adminSectionActive = false;
		}
	}

	/**
	 * @description Resets and reinitialises the nav options
	 */
	private checkNavigationPermissions(): void {
		this.navOptions = {
			newRiskAssessment: false
		};

		if (this.currentUserService.isAdministrator.getValue()) {
			this.navOptions.newRiskAssessment = true;
		} else {
			this.currentUserService.getCurrentUser().subscribe(userInfo => {
				if (userInfo.groups && userInfo.groups.find(group => group.groupName.toLowerCase() === 'admin')) {
					this.navOptions.newRiskAssessment = true;
				}
			});
		}
	}

	/**
	 * @description Determines if the current url matches the currently active url
	 * @param {string} targetUrl 
	 */
	public isActive(targetUrl): boolean {
		if (this.activeUrl.match(targetUrl)) {
			return true;
		}

		return false;
	}
}
