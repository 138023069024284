import { Component, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { SettingsService } from "../../../../service/admin/SettingsService";
import { ErrorHandlerService } from "../../../../service/ErrorHandlerService";
import { ISettingsOutputModel } from "../../../../../common/contracts/settings";
import { FormControl, FormGroup } from "@angular/forms";

@Component({
  templateUrl: './editOptions.component.html',
})
export class EditOptionsComponent implements OnInit {

  public isLoaded = false;
  public settings: ISettingsOutputModel;
  public settingForm = new FormGroup({
    isEmailsEnabled: new FormControl(false),
    // Set to true to cause the templates to be processed on submission
    processTemplates: new FormControl(false),
  });

  constructor(
    private settingService: SettingsService,
    private errorHandler: ErrorHandlerService,
    private location: Location,
    ) { }

  ngOnInit(): void {
    this.settingService.getSettings().subscribe(data => {
      this.settings = data;
      this.isLoaded = true;
      this.settingForm.setValue({
        isEmailsEnabled: data.isEmailsEnabled,
        processTemplates: false
      });
    }, err => this.errorHandler.handleHttpError(err));
  }

  public goBack() {
    this.location.back();
  }

  public submit() {
    const settings = this.settingForm.getRawValue();
    this.settingService.updateSettings(settings).subscribe(() => {
      this.goBack();
    }, err => this.errorHandler.handleHttpError(err));
  }

}
