var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from '@angular/core';
import { Location } from "@angular/common";
import * as moment from 'moment';
import "moment-timezone";
import { FormField, RecordParamType } from "../../../../../model/Form";
import { FormComponent } from "../../../../../model/FormComponent";
import { Session } from "../../../../../service/util/Session";
import { CurrentUserService } from "../../../../../service/currentUser/CurrentUserService";
import { FormService } from "../../../../../service/FormService";
import { CategoryService } from "../../../../../service/CategoryService";
import { FormRecordService } from "../../../../../service/FormRecordService";
import { GroupsService } from "../../../../../service/admin/GroupsService";
import { ErrorHandlerService } from "../../../../../service/ErrorHandlerService";
import { Router } from "@angular/router";
import { FollowUpWidgetComponent } from "../../../../shared/followUpWidget.component";
import { EnumService } from 'service/EnumService';
import { saveAs } from 'file-saver';
import { DocumentService } from 'service/DocumentService';
import { environment } from '../../../../../environments/environment';
var AuditFormStage0Component = /** @class */ (function (_super) {
    __extends(AuditFormStage0Component, _super);
    function AuditFormStage0Component(session, currentUserService, formService, categoryService, formRecordService, groupsService, errorHandler, router, location, enumService, documentService) {
        var _this = _super.call(this, location) || this;
        _this.session = session;
        _this.currentUserService = currentUserService;
        _this.formService = formService;
        _this.categoryService = categoryService;
        _this.formRecordService = formRecordService;
        _this.groupsService = groupsService;
        _this.errorHandler = errorHandler;
        _this.router = router;
        _this.enumService = enumService;
        _this.documentService = documentService;
        // Existing Form Data
        _this.readOnly = false;
        _this.hideHeader = false;
        _this.isTemplate = false;
        _this.documents = [];
        _this.resourcePacks = [];
        _this.auditFormTypes = [];
        _this.facilityOptions = [
            {
                text: 'Facility Wide',
                value: 'facilitywide',
            },
            {
                text: 'Part of Facility',
                value: 'partoffacility'
            }
        ];
        _this.form = {
            location: new FormField('', {
                validation: FormField.ValidationMethods.None,
                recordParamType: RecordParamType.Number
            }),
            templateLocation: new FormField('', {
                validation: FormField.ValidationMethods.None,
                recordParamType: RecordParamType.String
            }),
            department: new FormField('', {
                validation: FormField.ValidationMethods.IsNotBlank,
                recordParamType: RecordParamType.Number
            }),
            type: new FormField('', {
                validation: FormField.ValidationMethods.IsNotBlank,
                recordParamType: RecordParamType.Enum
            }),
            periodFrom: new FormField('', {
                validation: FormField.ValidationMethods.None,
                recordParamType: RecordParamType.Date
            }),
            periodTo: new FormField('', {
                validation: FormField.ValidationMethods.None,
                recordParamType: RecordParamType.Date
            }),
            dueAt: new FormField('', {
                validation: FormField.ValidationMethods.None,
                recordParamType: RecordParamType.NULL
            }),
            /** Template Only Properties */
            templateDueAtPeriod: new FormField('', {
                validation: FormField.ValidationMethods.None,
                recordParamType: RecordParamType.String
            }),
            templateDueAtOffset: new FormField('', {
                validation: FormField.ValidationMethods.None,
                recordParamType: RecordParamType.Number
            }),
            templatePeriodFromPeriod: new FormField('', {
                validation: FormField.ValidationMethods.None,
                recordParamType: RecordParamType.String
            }),
            templatePeriodFromOffset: new FormField('', {
                validation: FormField.ValidationMethods.None,
                recordParamType: RecordParamType.Number
            }),
            templatePeriodToPeriod: new FormField('', {
                validation: FormField.ValidationMethods.None,
                recordParamType: RecordParamType.String
            }),
            templatePeriodToOffset: new FormField('', {
                validation: FormField.ValidationMethods.None,
                recordParamType: RecordParamType.Number
            }),
        };
        return _this;
    }
    AuditFormStage0Component.prototype.registerFormFields = function () {
        var _this = this;
        var _a;
        (_a = this.formFields).push.apply(_a, Object.keys(this.form).map(function (k) { return _this.form[k]; }));
    };
    AuditFormStage0Component.prototype.getFormLocationId = function () {
        if (!this.isTemplate)
            return this.positiveValidInt(this.form.location);
        return null;
    };
    AuditFormStage0Component.prototype.getUserGroupId = function () {
        return this.positiveValidInt(this.form.department);
    };
    AuditFormStage0Component.prototype.onSubmit = function (isDraft) {
        var _this = this;
        this.session.lockInput(function () {
            return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
                var success, fail, formLocationId, userGroupId, stage, assignedUserId, _a, dueAt, alertAt, formFunc, isNewForm, summary, auditFormTypeEnum;
                var _this = this;
                return __generator(this, function (_b) {
                    success = function () {
                        resolve();
                        _this.router.navigate([_this.isTemplate ? '/admin/templates' : '/dashboard']);
                    };
                    fail = function (msg, err) {
                        console.error(msg, err);
                        _this.errorHandler.handleHttpError(err);
                        reject();
                    };
                    formLocationId = this.getFormLocationId();
                    userGroupId = this.getUserGroupId();
                    stage = 0;
                    assignedUserId = null;
                    // If its a draft assign it to the current user
                    if (!this.isTemplate) {
                        if (isDraft) {
                            assignedUserId = this.currentUserService.getCurrentUserIdOrFail();
                        }
                        else {
                            // The audit form is now going to stage 1 for action
                            stage = 1;
                        }
                    }
                    _a = this.parseDueDate(this.form.dueAt.value, undefined, this.isTemplate), dueAt = _a.dueAt, alertAt = _a.alertAt;
                    isNewForm = this.isNewForm(this.formData);
                    if (this.form.type.value) {
                        auditFormTypeEnum = this.auditFormTypes.find(function (typeEnum) { return typeEnum.id === Number(_this.form.type.value); });
                        if (auditFormTypeEnum)
                            summary = auditFormTypeEnum.value;
                    }
                    if (!isNewForm) {
                        formFunc = this.formService.updateForm({
                            id: this.formData.id,
                            formLocationId: formLocationId,
                            userGroupId: userGroupId,
                            stage: stage,
                            dueAt: dueAt,
                            alertAt: alertAt,
                            assignedUserId: assignedUserId,
                            summary: summary,
                            isTemplate: this.isTemplate
                        });
                    }
                    else {
                        formFunc = this.formService.createForm({
                            categoryId: this.formCategoryId,
                            formLocationId: formLocationId,
                            userGroupId: userGroupId,
                            dueAt: dueAt,
                            alertAt: alertAt,
                            notifyOnComplete: null,
                            stage: stage,
                            parentFormId: null,
                            assignedUserId: assignedUserId,
                            summary: summary,
                            isTemplate: this.isTemplate
                        });
                    }
                    formFunc.subscribe(function (data) {
                        var formId = _this.formData ? _this.formData.id : data['id'];
                        var properties = _this.toRecordParams(_this.form);
                        _this.formRecordService.createRecord({
                            formId: formId,
                            // Intentionally cast the properties object since we know its correct
                            properties: properties,
                            stage: 0,
                            documents: _this.documents.map(function (doc) { return ({ id: doc.id, isTicked: !!doc.isTicked }); }),
                            isComplete: !isDraft
                        })
                            .subscribe(success, function (err) { return fail('Error while creating a form', err); });
                    }, function (err) { return fail('Error while create/update form', err); });
                    return [2 /*return*/];
                });
            }); });
        });
    };
    AuditFormStage0Component.prototype.ngOnInit = function () {
        this.dateString = moment().tz(environment.timeZone).format("DD-MM-YYYY");
        this.registerFormFields();
        this.loadEnums();
        this.repopulateFormFromData();
    };
    AuditFormStage0Component.prototype.repopulateFormFromData = function () {
        var _this = this;
        if (!this.formData || !this.formData.records || !this.formData.records.length)
            return;
        var stageRecords = this.formData.records.filter(function (record) { return record.stage === 0; });
        if (stageRecords.length === 0)
            return;
        if (!this.sequence) {
            var mostRecentRecord = stageRecords.sort(function (a, b) { return a.sequence > b.sequence ? 1 : -1; }).pop();
            if (!mostRecentRecord)
                throw new Error("internal error");
            // If the most recent record was a submission, we are not going to use it
            if (mostRecentRecord.isComplete && !this.isTemplate) {
                // It is likely the client will require that some fields are always loaded
                return;
            }
            this.formRecord = mostRecentRecord;
        }
        else {
            var targetRecord = stageRecords.find(function (record) { return record.sequence === _this.sequence; });
            if (!targetRecord)
                throw new Error("internal error");
            this.formRecord = targetRecord;
        }
        this.dateString = moment(this.formRecord.createdAt).tz(environment.timeZone).format(this.dateFormat);
        this.updateFromRecordParams(this.form, this.formRecord);
        if (this.formData.dueAt) {
            this.form.dueAt.value = moment(this.formData.dueAt).tz(environment.timeZone).format('DD/MM/YYYY');
        }
        // get attached documents
        this.documents = this.initTickedDocuments(this.formRecord.documents, this.formRecord.tickedDocuments);
    };
    // Audit Stage 0 Functions
    AuditFormStage0Component.prototype.loadEnums = function () {
        var _this = this;
        this.session.lockInputRx(this.categoryService.getCategories())
            .subscribe(function (data) {
            var reportCategory = data.find(function (data) { return !!data.name.match(/^Audit/); });
            if (reportCategory)
                _this.formCategoryId = reportCategory.id;
            //else
            //Notify the user an error has occurred
        }, function (err) {
            console.error('Error while getting categories', err);
            _this.errorHandler.handleHttpError(err);
        });
        this.session.lockInputRx(this.enumService.getEnumsByName('auditFormType'))
            .subscribe(function (data) {
            // console.log("Updating Audit form types");
            // console.log(data);
            _this.auditFormTypes = data;
            if (_this.form.type.value) {
                _this.updateAuditType([{
                        id: _this.form.type.value,
                        text: 'default'
                    }]);
            }
            else {
                _this.updateAuditType([]);
            }
        });
    };
    AuditFormStage0Component.prototype.updateAuditType = function (options) {
        // console.log("Updating Audit Type");
        // console.log(options);
        var _this = this;
        var validOptions = options.filter(function (option) { return !!option && option.id; });
        // console.log("ValidOptions");
        // console.log(validOptions);
        // console.log("auditFormTypes");
        // console.log(this.auditFormTypes);
        var validAuditFormTypes = validOptions
            .map(function (option) { return _this.auditFormTypes.find(function (formType) { return formType.id === Number(option.id); }); })
            .filter(function (formType) { return !!formType; });
        // console.log("ValidAuditFormTypes");
        // console.log(validAuditFormTypes);
        this.resourcePacks = validAuditFormTypes
            .map(function (formType) { return formType.resourcePack; })
            .filter(function (formType) { return !!formType; });
        // console.log("ResourcePacks");
        // console.log(this.resourcePacks);
    };
    AuditFormStage0Component.prototype.download = function (doc) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.documentService.downloadDocument(doc.document.id).subscribe(function (data) {
                    saveAs(data, doc.document.fileName);
                }, function (error) { return _this.errorHandler.handleHttpError(error); });
                return [2 /*return*/];
            });
        });
    };
    return AuditFormStage0Component;
}(FormComponent));
export { AuditFormStage0Component };
