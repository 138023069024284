var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from '@angular/core';
import { Location } from "@angular/common";
import { FormComponent } from "../../../../../model/FormComponent";
import { FormField, RecordParamType } from "../../../../../model/Form";
import { Session } from "../../../../../service/util/Session";
import { CurrentUserService } from "../../../../../service/currentUser/CurrentUserService";
import { FormRecordService } from "../../../../../service/FormRecordService";
import { FormService } from "../../../../../service/FormService";
import { CategoryService } from "../../../../../service/CategoryService";
import { GroupsService } from "../../../../../service/admin/GroupsService";
import { ErrorHandlerService } from "../../../../../service/ErrorHandlerService";
import { FollowUpWidgetComponent } from "../../../../shared/followUpWidget.component";
import { Router } from '@angular/router';
import { logger } from 'service/util/Logger';
import { riskTypeMap } from '../../../../../model/RiskType';
import { Risk } from "../../../../../../common/Risk";
import { EnumService } from 'service/EnumService';
import { FollowUpService } from 'service/FollowUpService';
import { environment } from 'environments/environment';
var RiskAssessmentFormStage2Component = /** @class */ (function (_super) {
    __extends(RiskAssessmentFormStage2Component, _super);
    function RiskAssessmentFormStage2Component(session, currentUserService, formRecordService, formService, categoryService, groupsService, errorHandler, location, router, enumService, followUpService) {
        var _this = _super.call(this, location) || this;
        _this.session = session;
        _this.currentUserService = currentUserService;
        _this.formRecordService = formRecordService;
        _this.formService = formService;
        _this.categoryService = categoryService;
        _this.groupsService = groupsService;
        _this.errorHandler = errorHandler;
        _this.router = router;
        _this.enumService = enumService;
        _this.followUpService = followUpService;
        _this.currentFormStage = 2;
        _this.className = "RiskAssessmentFormStage2Component";
        _this.readOnly = false;
        _this.hideHeader = false;
        _this.form = {
            reassign: new FormField(false, {
                validation: FormField.ValidationMethods.None,
                recordParamType: RecordParamType.Boolean
            }),
            //Since this will be assigned to a select it must be a string data - Conversion where appropriate
            reassignToUserId: new FormField('', {
                validation: FormField.ValidationMethods.None
            }),
            followUps: new FormField('[]', {
                nullEquivilent: "[]",
                recordParamType: RecordParamType.JSON,
                validation: function (value) {
                    return _this.followUpWidgetRef ? _this.followUpWidgetRef.validate() : true;
                }
            }),
            isAlarp: new FormField(false, {
                validation: FormField.ValidationMethods.None,
                recordParamType: RecordParamType.Boolean
            }),
            riskRemoved: new FormField(false, {
                validation: FormField.ValidationMethods.None,
                recordParamType: RecordParamType.Boolean
            }),
            summary: new FormField('', {
                validation: FormField.ValidationMethods.None
            }),
        };
        _this.documents = [];
        _this.isExistingRisk = false;
        _this.isRiskRemoved = false;
        _this.isRiskIncreased = false;
        _this.riskType = 'General Risk';
        _this.followUpFormTypeId = null;
        return _this;
    }
    RiskAssessmentFormStage2Component.prototype.registerFormFields = function () {
        var _this = this;
        var _a;
        (_a = this.formFields).push.apply(_a, Object.keys(this.form).map(function (k) { return _this.form[k]; }));
    };
    RiskAssessmentFormStage2Component.prototype.ngOnInit = function () {
        this.loadEnums();
        this.registerFormFields();
        this.repopulateFormFromData();
    };
    RiskAssessmentFormStage2Component.prototype.onSubmit = function (isDraft) {
        var _this = this;
        if (isDraft === void 0) { isDraft = false; }
        var signature = this.className + ".onSubmit:";
        this.session.lockInput(function () {
            return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
                var assignedUserId, stage, userGroupId;
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            assignedUserId = this.currentUserService.userData && isDraft ? this.currentUserService.userData.id : null;
                            stage = this.currentFormStage;
                            userGroupId = null;
                            if (!!isDraft) return [3 /*break*/, 5];
                            if (!(this.form.reassign.value && Number(this.form.reassignToUserId.value))) return [3 /*break*/, 1];
                            // The user is intending to reassign this form to another user
                            stage = 1;
                            userGroupId = this.formData.userGroupId;
                            assignedUserId = Number(this.form.reassignToUserId.value);
                            logger.silly(signature + ("Assigning form to User[" + assignedUserId + "] and Group[" + userGroupId + "]"));
                            return [3 /*break*/, 5];
                        case 1:
                            if (!(!this.isExistingRisk || this.form.riskRemoved.value || this.isRiskIncreased || this.form.isAlarp.value)) return [3 /*break*/, 3];
                            stage = 3;
                            return [4 /*yield*/, this.groupsService.getGroupIdByName(environment.adminGroupName)];
                        case 2:
                            userGroupId = _a.sent();
                            return [3 /*break*/, 5];
                        case 3:
                            stage = 4;
                            return [4 /*yield*/, this.getGroupForRiskType()];
                        case 4:
                            userGroupId = _a.sent();
                            _a.label = 5;
                        case 5:
                            this.formService.updateForm({
                                id: this.formData.id,
                                formLocationId: this.formData.formLocationId,
                                userGroupId: userGroupId,
                                notifyOnComplete: this.formData.notifyOnComplete,
                                stage: stage,
                                assignedUserId: assignedUserId
                            }).subscribe(function (data) {
                                var properties = _this.toRecordParams(_this.form);
                                _this.formRecordService.createRecord({
                                    formId: _this.formData.id,
                                    properties: properties,
                                    stage: _this.currentFormStage,
                                    documents: _this.documents.map(function (doc) { return ({ id: doc.id, isTicked: !!doc.isTicked }); }),
                                    isComplete: !isDraft
                                })
                                    .subscribe(function (data) {
                                    var success = function () {
                                        resolve(data);
                                        _this.session.requestPrevPage.next({ defaultUrl: '/ram-dashboard', filter: 'dashboard' });
                                    };
                                    //Done creating the form and appending its properties
                                    if (stage === 4) {
                                        _this.formService.finalizeForm(_this.formData.id).subscribe(function () {
                                            var followUps = [];
                                            if (_this.form.followUps.value && _this.form.followUps.value.length) {
                                                followUps = JSON.parse(_this.form.followUps.value);
                                            }
                                            if (followUps.length === 0)
                                                return success();
                                            _this.followUpService.generateFollowUps(_this.formData.id, _this.formData.formLocationId, followUps, _this.followUpFormTypeId, success);
                                        });
                                    }
                                    else {
                                        success();
                                    }
                                }, function (err) {
                                    _this.errorHandler.handleHttpError(err);
                                    reject("Error creating new record");
                                });
                            }, function (err) {
                                _this.errorHandler.handleHttpError(err);
                                reject("Error updating form");
                            });
                            return [2 /*return*/];
                    }
                });
            }); });
        });
    };
    /**
     * @description Fetches the groupId of the group that should recieve the ticket based on the riskType
     */
    RiskAssessmentFormStage2Component.prototype.getGroupForRiskType = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.groupsService.getGroupIdByName(environment.defaultRiskGroupName)];
            });
        });
    };
    /**
     * @description Fetches the original data, and historical data that will not change by modification fo this form
     */
    RiskAssessmentFormStage2Component.prototype.handleInitialData = function () {
        var lastStage0Submission = this.getMostRecentSubmission(this.formData, false, 0);
        var firstAssessment = this.getOldestSubmission(this.formData, false, 1, undefined);
        var lastAssessment = this.getMostRecentSubmission(this.formData, false, 1, undefined);
        if (!lastStage0Submission) {
            throw new Error("There should always be a stage 0 submission");
        }
        if (!firstAssessment) {
            throw new Error("There should always be a first 1 submission");
        }
        if (!lastAssessment) {
            throw new Error("There should always be a last 1 submission");
        }
        var propsOfInterest = {
            riskType: new FormField('1', {
                recordParamType: RecordParamType.Number
            }),
            isExistingRisk: new FormField(false, {
                recordParamType: RecordParamType.Boolean
            }),
            existingRisk: new FormField(null, {
                validation: FormField.ValidationMethods.None
            })
        };
        this.updateFromRecordParams(propsOfInterest, lastStage0Submission);
        this.riskType = riskTypeMap[(propsOfInterest.riskType.value || '1').toString()];
        this.isExistingRisk = !!(propsOfInterest.isExistingRisk.value && propsOfInterest.existingRisk.value);
        this.isRiskRemoved = this.getBoolData(lastAssessment, 'riskRemoved') || false;
        if (!this.isRiskRemoved) {
            var initialRisk = this.getStringData(firstAssessment, 'riskLevel');
            var newRisk = this.getStringData(lastAssessment, 'postMitigationRiskLevel');
            if (initialRisk && newRisk) {
                this.isRiskIncreased = Risk.isHigherRisk(initialRisk, newRisk);
            }
        }
        else {
            this.form.riskRemoved.value = true;
            this.form.riskRemoved.disabled = true;
        }
    };
    RiskAssessmentFormStage2Component.prototype.repopulateFormFromData = function () {
        return __awaiter(this, void 0, void 0, function () {
            var lastSubmission;
            return __generator(this, function (_a) {
                this.handleInitialData();
                lastSubmission = this.getMostRecentSubmission(this.formData, true, this.currentFormStage, this.sequence);
                // Do not recall the last submission if it is complete but not specifically requested
                if (!lastSubmission || (!this.sequence && lastSubmission.isComplete)) {
                    return [2 /*return*/, this.loadFollowUpData()];
                }
                this.formRecord = lastSubmission;
                this.updateFromRecordParams(this.form, this.formRecord);
                // get attached documents
                this.documents = this.initTickedDocuments(this.formRecord.documents, this.formRecord.tickedDocuments);
                return [2 /*return*/];
            });
        });
    };
    /**
     * @description Loads follow up data from the most recent submission of any type
     */
    RiskAssessmentFormStage2Component.prototype.loadFollowUpData = function () {
        if (!this.form.followUps.valueIsNull)
            return;
        var mostRecentSubmission = this.getMostRecentSubmission(this.formData, true);
        if (mostRecentSubmission) {
            var followUpData = this.getJsonData(mostRecentSubmission, 'followUps');
            this.form.followUps.value = followUpData;
        }
    };
    RiskAssessmentFormStage2Component.prototype.loadEnums = function () {
        var _this = this;
        this.session.lockInputRx(this.enumService.getEnumsByName('reportFormType'))
            .subscribe(function (data) {
            var followUpFormType = data.find(function (typeEnum) { return typeEnum.value.toLowerCase() === 'other'; });
            if (followUpFormType) {
                _this.followUpFormTypeId = followUpFormType.id;
            }
        });
    };
    return RiskAssessmentFormStage2Component;
}(FormComponent));
export { RiskAssessmentFormStage2Component };
