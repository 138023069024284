/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./uploads.component";
import * as i3 from "../../service/DocumentService";
import * as i4 from "../../service/ModalService";
import * as i5 from "../../service/ErrorHandlerService";
var styles_UploadsComponent = [".hidden-input[_ngcontent-%COMP%] {\n          display: none;\n      }\n\n      .download-document-reference[_ngcontent-%COMP%] {\n          text-decoration: underline;\n          cursor: pointer;\n          font-size: 0.8em;\n          line-height: 1.2;\n          padding-bottom: 4px;\n      }\n\n      .remove-document-reference[_ngcontent-%COMP%] {\n          cursor: pointer;\n          font-size: 1em;\n          margin-left: 8px;\n      }\n\n      .upload-title[_ngcontent-%COMP%] {\n          margin-left: 10px;\n      }\n\n      button[disabled][_ngcontent-%COMP%] {\n          background-color: #ff9b3b;\n          color: white;\n      }\n    \n    .attachment-icon[_ngcontent-%COMP%] {\n        font-size: 20px;\n        cursor: pointer;\n    }\n    \n    .light-grey-text[_ngcontent-%COMP%] {\n        color: #AAAAAA;\n    }"];
var RenderType_UploadsComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_UploadsComponent, data: {} });
export { RenderType_UploadsComponent as RenderType_UploadsComponent };
function View_UploadsComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "i", [["class", "fa fa-upload"]], null, null, null, null, null))], null, null); }
function View_UploadsComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "i", [["class", "fa fa-circle-o-notch fa-spin"]], null, null, null, null, null))], null, null); }
function View_UploadsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 8, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 6, "button", [["class", "btn btn-orange rounded-0 btn-block"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 9).click() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_UploadsComponent_2)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_UploadsComponent_3)), i0.ɵdid(6, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(7, 0, null, null, 1, "strong", [["class", "upload-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(8, null, ["", ""])), (_l()(), i0.ɵeld(9, 0, [["fileInput", 1]], null, 0, "input", [["class", "hidden-input"], ["type", "file"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.handleFileSelected($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_1 = !_co.isUploading; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.isUploading; _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isUploading; _ck(_v, 2, 0, currVal_0); var currVal_3 = (_co.isUploading ? "UPLOADING" : "UPLOAD FILE"); _ck(_v, 8, 0, currVal_3); }); }
function View_UploadsComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 8, "div", [["class", "col-12 pt-2"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" How to configure attachments"])), (_l()(), i0.ɵeld(3, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 0, "i", [["class", "fa fa-paperclip attachment-icon text-primary"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Send File to Originator"])), (_l()(), i0.ɵeld(6, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 0, "i", [["class", "fa fa-paperclip attachment-icon light-grey-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Not For Originator"])), (_l()(), i0.ɵeld(9, 0, null, null, 0, "br", [], null, null, null, null, null))], null, null); }
function View_UploadsComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "mt-1 pt-1 pl-3 border-top"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["No Files Uploaded"]))], null, null); }
function View_UploadsComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "i", [["class", "fa fa-paperclip attachment-icon"]], [[8, "title", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleDocumentTick(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(2, { "text-primary": 0, "light-grey-text": 1 })], function (_ck, _v) { var currVal_1 = "fa fa-paperclip attachment-icon"; var currVal_2 = _ck(_v, 2, 0, _v.parent.context.$implicit.isTicked, !_v.parent.context.$implicit.isTicked); _ck(_v, 1, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = (_v.parent.context.$implicit.isTicked ? "Detach document from the final report" : "Attach document to the final report"); _ck(_v, 0, 0, currVal_0); }); }
function View_UploadsComponent_9(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["class", "remove-document-reference"], ["title", "Remove document"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleRemoveDocument(_v.parent.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00D7"]))], null, null); }
function View_UploadsComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["class", "mt-1 pt-1 border-top"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_UploadsComponent_8)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\u00A0 "])), (_l()(), i0.ɵeld(4, 0, null, null, 1, "span", [["class", "download-document-reference"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleDocumentDownload(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(5, null, ["", ""])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_UploadsComponent_9)), i0.ɵdid(7, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.disableTicking; _ck(_v, 2, 0, currVal_0); var currVal_2 = !_co.readOnly; _ck(_v, 7, 0, currVal_2); }, function (_ck, _v) { var currVal_1 = _v.context.$implicit.fileName; _ck(_v, 5, 0, currVal_1); }); }
function View_UploadsComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "div", [["class", "row mt-2 mt-lg-3"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Files Uploaded:"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_UploadsComponent_6)), i0.ɵdid(6, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_UploadsComponent_7)), i0.ɵdid(8, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.documents.length === 0); _ck(_v, 6, 0, currVal_0); var currVal_1 = _co.documents; _ck(_v, 8, 0, currVal_1); }, null); }
export function View_UploadsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_UploadsComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_UploadsComponent_4)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_UploadsComponent_5)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.readOnly; _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.disableTicking; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.displayUploads; _ck(_v, 5, 0, currVal_2); }, null); }
export function View_UploadsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "document-uploads", [], null, null, null, View_UploadsComponent_0, RenderType_UploadsComponent)), i0.ɵdid(1, 49152, null, 0, i2.UploadsComponent, [i3.DocumentService, i4.ModalService, i5.ErrorHandlerService], null, null)], null, null); }
var UploadsComponentNgFactory = i0.ɵccf("document-uploads", i2.UploadsComponent, View_UploadsComponent_Host_0, { displayUploads: "displayUploads", documents: "documents", readOnly: "readOnly", disableTicking: "disableTicking", disableSend: "disableSend", defaultTickedState: "defaultTickedState" }, { documentsChange: "documentsChange", documentUploaded: "documentUploaded" }, []);
export { UploadsComponentNgFactory as UploadsComponentNgFactory };
