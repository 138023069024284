import { OnInit, EventEmitter } from '@angular/core';
import { Session } from "../../service/util/Session";
import { FormField } from "../../model/Form";
import { Select2Component } from 'ng2-select2';
import { TagsService } from "../../service/admin/TagsService";
import { CurrentUserService } from '../../service/currentUser/CurrentUserService';
import { logger } from "../../service/util/Logger";
import { FormService } from "../../service/FormService";
import { RiskService } from 'service/RiskService';
var RiskSelectComponent = /** @class */ (function () {
    function RiskSelectComponent(TagsService, session, currentUserService, formService, riskService) {
        this.TagsService = TagsService;
        this.session = session;
        this.currentUserService = currentUserService;
        this.formService = formService;
        this.riskService = riskService;
        this._value = '';
        // Implies a read only state
        this.disabled = false;
        // /**
        //  * Restrict available tags to the tags available to the current user
        //  */
        // @Input()
        // restrictTags: boolean = false;
        this.isMeta = true;
        this.multiple = false;
        this.defaultValue = '';
        this.currentRiskId = null;
        this.selectData = [];
        this.change = new EventEmitter();
    }
    Object.defineProperty(RiskSelectComponent.prototype, "value", {
        get: function () {
            return this._value;
        },
        /**
         * By preventing assigning non-unique values during the set
         * we can prevent a loop of observable subscribers
         */
        set: function (newValue) {
            this._value = newValue;
            if (!this.field && !this.multiple) {
                this.defaultValue = this._value;
            }
        },
        enumerable: true,
        configurable: true
    });
    RiskSelectComponent.prototype.ngOnInit = function () {
        this.init();
    };
    RiskSelectComponent.prototype.init = function () {
        var _this = this;
        this.selectOptions = {
            allowClear: !this.multiple,
            placeholder: this.placeholder || "Select a risk",
            multiple: this.multiple
        };
        this.session.lockInputRx(this.riskService.getRisks(0, 2000, undefined, undefined, '')).subscribe(function (riskData) {
            _this.selectData = riskData.items.map(function (item) { return ({
                id: item.id.toString(),
                text: item.summary,
                selected: false
            }); });
        });
    };
    RiskSelectComponent.prototype.valueChanged = function (selectedOpts) {
        if (selectedOpts.length > 1 && !this.multiple)
            throw ("Selected options unexpectedly contained multiple results");
        if (selectedOpts.length === 1 && !this.multiple && !this.disabled) {
            if (this.field) {
                this.field.value = selectedOpts[0].id;
            }
            else {
                if (this._value !== selectedOpts[0].id) { // if the value has been changed - emit event
                    this._value = selectedOpts[0].id;
                    this.change.emit(selectedOpts);
                    return;
                }
            }
        }
        if (this.multiple) {
            var actualSelected = selectedOpts.filter(function (opt) { return opt.id && opt.id.length; });
            var newValue = actualSelected.map(function (opt) { return opt.id; }).join(",");
            if (this.field) {
                this.field.value = newValue;
            }
            else {
                logger.silly("Checking internalValue change Value[(" + typeof newValue + ") " + newValue + "] !== _value[(" + typeof this._value + ") " + this._value + "]");
                if (this._value !== newValue) { // if the value has been changed - emit event
                    logger.silly("RiskSelectComponent: Updating Internal Value");
                    this._value = newValue;
                    this.change.emit(actualSelected);
                }
            }
        }
    };
    return RiskSelectComponent;
}());
export { RiskSelectComponent };
